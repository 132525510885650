<template>
<div id="pageIndex" style="padding-bottom:50px;" :style="{'background': backColor && curClassify === -1 ? backColor : '#F0F1F5'}">
    <div class="defaultBg" style="padding-top: 92px;">
        <div style="position: fixed;top: 0; width: 100%; z-index: 3" :style="headStyle">
            <div class="flex-container paddingh20"
                 style="height: 44px;">
                <div class="flex-container">
                    <img v-if="(themeD === 3 && themeBg) || backColor || headBgImg || newerList[0]" class="logo" alt=""
                         src="//image1.51tiangou.com/tgou2/img2/index/title-tgou-logo_4.png"
                         height="20">
                    <img v-else
                         src="//image1.51tiangou.com/tgou2/img2/index/title-tgou-logo_3.png"
                         class="logo"
                         alt="" height="20">
                    <div class="lh-20 fs-12 slogan"
                         style="margin-left: 8px;"
                         :class="(themeD === 3 && themeBg) || backColor || headBgImg || newerList[0] ? 'text-white' : 'text-dark'">
                        大商集团电商平台
                    </div>
                    <div @click="$router.push({path:'search',query:{type:'index',placeholder:'放心购物上天狗'}})"
                         class="flex-container logoBarSearch"
                         style="border-radius: 200px;padding: 4px;width: 260px;background: #F8F8F8;"
                         data-scp="search.0">
                        <div class="flex-container">
                            <img src="//image1.51tiangou.com/tgou2/img2/index/icon_search.png"
                                 style="margin-left: 4px;"
                                 width="24"
                                 height="24"
                                 alt="">
                            <div v-text="searchBar.placeholder || searchBar.defaultText"
                                 class="text-limit1 text-left text-light fs-12"
                                 style="margin-left: 8px;"></div>
                        </div>
                        <div class="bg-red fs-12 lh-24 text-white" style="border-radius: 200px;padding: 0 12px;">搜索</div>
                    </div>
                </div>
                <a href="/mine/message/index.html" data-scp="noti.00">
                    <div class="pos-r overflow-h" style="width: 24px;height: 24px;">
                        <img :src="((themeD === 3 && themeBg) || backColor || headBgImg || newerList[0]) ?
                                   '//image1.51tiangou.com/tgou2/img2/index/icon-message.png' : '//image1.51tiangou.com/tgou2/img2/index/icon-message-2.png'"
                             width="24"
                             height="24"
                             class="pos-a"
                             alt="">
                    </div>
                </a>
            </div>
            <div style="padding: 8px 48px 8px 12px;" class="pos-r">
                    <div @click="$router.push({path:'search',query:{type:'index',placeholder:'放心购物上天狗'}})"
                         class="flex-container"
                         style="border-radius: 200px;padding: 4px;background: #f8f8f8"
                         data-scp="search.0">
                        <div class="flex-container">
                            <img src="//image1.51tiangou.com/tgou2/img2/index/icon_search.png"
                                 style="margin-left: 4px;"
                                 width="24"
                                 height="24"
                                 alt="">
                            <div v-text="searchBar.placeholder || searchBar.defaultText"
                                 class="text-limit1 text-left text-light fs-12"
                                 style="margin-left: 8px;"></div>
                        </div>
                        <div class="bg-red fs-12 lh-24 text-white" style="border-radius: 200px;padding: 0 12px;">搜索</div>
                    </div>
					<!--结算码-->
					<a href="/mine/idCode.html" data-scp="code.0" class="pos-a" style="width: 30px; height: 36px; right: 9px; top: 5px" v-if="!isWeixin">
						<img :src="(themeD === 3 && themeBg) || backColor || headBgImg || newerList[0] ?
							 	'//image1.51tiangou.com/tgou2/img2/index/icon-idcode-2.png' : '//image1.51tiangou.com/tgou2/img2/index/icon-idcode.png'"
							 width="30" height="36" alt="">
					</a>
                    <div data-scp="code.0" class="pos-a" v-if="isWeixin"
                         style="width: 30px; height: 36px; right: 9px; top: 1px"
                         v-html="barCodeEntry"></div>
					<div v-if="idCodeGuideInfoIndex"
						 data-scp="wzdl.0"
						 @click="idCodeGuideInfoIndex = false"
						 class="pos-a tip-idcode" style="right: 0; top: 48px;">
						<img src="//image1.51tiangou.com/tgou2/img2/index/tip-idcode.png" alt="" width="163" height="81">
					</div>
                </div>
        </div>
    </div>

    <!--小标签-->
    <div v-if="twelveTag.items.length"
         id="twelveTagScroll"
         class="scroll-container"
         ref="twelveTagScroll"
         style="margin: 8px 12px 0;"
         :style="themeD === 3 && 'margin-bottom: 8px'">
        <div v-for="item in twelveTag.items" style="margin-right: 8px;">
            <a :href="item.clickUrl"
               :data-scp="item.scpCD"
               :data-bk="item.bk"
               data-trace-id="">
                <img :src="item.imageUrl" width="64" height="24">
            </a>
        </div>
    </div>

    <!-- 运营分类 -->
    <div v-if="classifyTabs && classifyTabs.items.length" style="padding-top: 40px" :style="{'margin-bottom': curClassify === -1 && newerList[0] ? '0' : '8px'}">
        <div class="pos-f paddingl10" ref="refTab" :style="classifyStyle">
            <div class="scroll-container float-left fs-14" style="width: calc(100% - 40px)">
                <div class="dis-i paddingh20 js-fltab" :class="curClassify === -1 ? 'text-bolder fs-16' : 'fs-14'"
                     :style="newerList[0] ? 'color: #fff' : classifyTabs.indexColor ? `color: ${classifyTabs.indexColor}` : backColor || classifyTabs.imageUrl ? 'color: #fff' : 'color: #000'"
                     data-scp="fltab.0" data-trace-id=""
                     @click="changeClassify(-1)">首页</div>
                <a v-if="classifyAdv" :href="classifyAdv.clickUrl"
                   :data-scp="classifyAdv.scpCD" :data-bk="classifyAdv.bk" data-trace-id=""
                   style="vertical-align: text-top" class="js-fltab">
                    <img :src="classifyAdv.imageUrl" alt="" height="18px" :style="newerList[0] ? 'filter: brightness(100);' : ''">
                </a>
                <div v-for="(item, index) in classifyTabs.items" :key="item.id"
                     class="dis-i paddingh20 js-fltab fs-14" :style="{color: newerList[0] ? '#fff' : item.color, 'vertical-align': curClassify === index ? '' : 'top'}"
                     :class="{'text-bolder fs-16': curClassify === index, 'text-white': backColor || newerList[0] || classifyTabs.imageUrl, 'text-dark': curClassify !== index && !(backColor || newerList[0] || classifyTabs.imageUrl), 'text-black': curClassify === index && !item.color && !(backColor || newerList[0] || classifyTabs.imageUrl)}"
                     :data-scp="`fltab.${classifyAdv ? index + 2 : index + 1}`"
                     :data-bk="item.bk" :data-trace-id="''"
                     @click="changeClassify(index, item)">
                    {{item.title}}
                </div>
            </div>
            <a href="/#/classify" data-scp="flrk.0" data-trace-id="">
                <img v-if="backColor || newerList[0] || classifyTabs.imageUrl" src="//image1.51tiangou.com/tgou2/img2/index/icon-fenlei.png" alt="" width="40px">
                <img v-else src="//image1.51tiangou.com/tgou2/img2/index/icon-fenlei-dark.png" alt="" width="40px">
            </a>
        </div>
    </div>

    <!-- 首页展示的广告位 -->
    <div v-show="curClassify === -1">
        <big-event-adv ref="refBigEventAdv" :obj="bigEventEntryH || bigEventEntryW" :page="'index'"></big-event-adv>
        <station-mail ref="refStationMail" :page="'index'" :code="'14.sp'" style="margin: 0 12px"></station-mail>
        <!--新人礼-->
        <div v-if="newerList[0] && newerList[0].items && newerList[0].items.length" class="flex-container newer-contain"
             style="margin-bottom: 8px" :style="newerStyle"
             :data-scp="newerList[0].scpCD" :data-bk="newerList[0].bk" data-trace-id=""
             @click="changePage(newerList[0].clickUrl, newerList[0].scpCD)">
            <div v-if="newerList[0].couponPrice"
                 class="text-center" style="width: calc(25% - 4px)">
                <div class="img-container borderR8 pos-r">
                    <span class="pos-a fs-12" style="color: #feffe1;top: 55%">￥<span class="fs-20">{{newerList[0].couponPrice}}</span></span>
                    <img src="//image1.51tiangou.com/tgou2/img2/index/icon-newer-coupon.png" alt="">
                </div>
                <p class="fs-12" style="margin: 8px 0 4px">新人专享</p>
                <p class="fs-12 text-center text-bolder text-white bg-red"
                   style="line-height: 20px;padding: 0 12px;border-radius: 20px;width: fit-content;margin: 0 auto">立即领取</p>
            </div>
            <div v-for="(item, index) in newerList[0].items.slice(0, newerList[0].couponPrice ? 3 : 4)"
                 :data-scp="item.scpCD" :data-bk="item.bk" data-trace-id=""
                 @click.stop="changePageTgs(newerList[0].clickUrl, newerList[0].scpCD, newerList[0].bk)"
                 class="text-center" style="width: calc(25% - 4px)">
                <div class="img-container borderR8 pos-r">
                    <img :src="item.imageUrl" alt="">
<!--                    <img src="//image1.51tiangou.com/tgou2/img2/index/icon-pro-newer.png" alt="" class="pos-a left0 top0" width="56">-->
                </div>
                <p class="fs-12" style="margin: 8px 0 4px">{{item.title.slice(0, 6)}}</p>
                <div class="fs-12 text-center text-bolder text-red"
                     style="font-family: DINAlternate-Bold;">
                    ￥<span class="frs-30">{{item.price}}</span>
                </div>
            </div>
        </div>
        <div class="defaultBg">
            <!-- <cmui-affix class="pos-r">
                <div class="flex-container padding20">
                    <div class="flex-container left logoTxt">
                        <img src="//image1.51tiangou.com/tgou2/img2/index/logo-tgw.png" v-if="themeD>1" alt="" width="75">
                        <img src="//image1.51tiangou.com/tgou2/img2/index/logo-tgb.png" v-else alt="" width="75">
                        <span class="marginl10 margint10 fs-11" :class="{'text-white':themeD>1}">逛商场更便利、买好货更省钱</span>
                    </div>
                    <div class="flex-container" style="font-size: 22px">
                        <a href="/purchase/index.html" data-scp="mr.tscar">
                            <span class="marginr40 icon-cart dis-b icons pos-r" :class="{'text-white':themeD>1}"></span>
                        </a>
                        <a href="/mine/message/index.html" data-scp="noti.00">
                            <span class="icon-advice dis-b icons pos-r" :class="{'text-white':themeD>1}"></span>
                        </a>
                    </div>
                </div>
                <div class="marginh20 inputB" style="border-radius: 2px;">
                    <div class="fs-13 text-light paddingh20 flex-container" @click="$router.push({path:'search',query:{type:'index',placeholder:'放心购物上天狗'}})" data-scp="search.0">
                        <div v-text="searchBar.placeholder || searchBar.defaultText"></div>
                        <i class="icon-scan" style="font-size: 22px;"></i>
                    </div>
                </div>
            </cmui-affix> -->
            <div class="deHeight"></div>
            <!-- 超级大促 -->
            <div v-if="(slider_cu && themeD === 2) || themeD === 3" class="pos-r" style="margin-bottom: 12px;">
                <!-- 大促轮播 -->
                <div v-if="slider_cu && themeD === 2" class="borderR8 marginh20" style="overflow: hidden;">
                    <tg-slider :data="slider_cu.items.slice(0, 5)"
                               @slider-change="changeBg"></tg-slider>
                </div>

                <!-- 超级大促图片 -->
                <div class="pos-r" v-if="themeD === 3">
                    <a class="dis-b" data-scp="ad.0"
                       :data-bk="`adv-${superBlock.items[0].data.items[0].id}`"
                       :href="isWeixin && (isList(superBlock.items[0].data.items[0]) || isRoom(superBlock.items[0].data.items[0])) ? '': superBlock.items[0].data.items[0].clickUrl">
                        <div>
                            <wx-open-launch-weapp v-if="isWeixin && isList(superBlock.items[0].data.items[0])"
                                                  :username="webcast.userName"
                                                  :path="webcast.list">
                                <script type="text/wxtag-template">
                                    <img style="max-width: 100%;display:block;"
                                         src="{{protocol}}{{superBlock.items[0].data.items[0].imageUrl}}"
                                         alt="">
                                </script>
                            </wx-open-launch-weapp>
                            <wx-open-launch-weapp v-else-if="isWeixin && isRoom(superBlock.items[0].data.items[0])"
                                                  :username="webcast.userName"
                                                  :path="roomPath(superBlock.items[0].data.items[0])">
                                <script type="text/wxtag-template">
                                    <img style="max-width: 100%;display:block;"
                                         src="{{protocol}}{{superBlock.items[0].data.items[0].imageUrl}}"
                                         alt="">
                                </script>
                            </wx-open-launch-weapp>
                            <img v-else width="100%" alt=""
                                 :src="superBlock.items[0].data.items[0].imageUrl">
                        </div>
                    </a>
                </div>
                <!-- 三宫格广告位 -->
                <cmui-list :col="3" v-if="themeD === 3">
                    <cmui-list-item class="list-item" v-for="(item, index) in superBlock.items[1].data.items">
                        <a style="display: block;"
                           :href="isWeixin && (isList(item) || isRoom(item)) ? '':item.clickUrl"
                           :data-scp="`ads.${index}`"
                           :data-bk="`adv-${item.id}`">
                            <div style="">
                                <wx-open-launch-weapp v-if="isWeixin && isList(item)"
                                                      :username="webcast.userName"
                                                      :path="webcast.list">
                                    <script type="text/wxtag-template">
                                        <img style="max-width:50%;"
                                             src="{{protocol}}{{item.imageUrl}}"
                                             alt="">
                                    </script>
                                </wx-open-launch-weapp>
                                <wx-open-launch-weapp v-else-if="isWeixin && isRoom(item)"
                                                      :username="webcast.userName"
                                                      :path="roomPath(item)">
                                    <script type="text/wxtag-template">
                                        <img style="max-width:50%;"
                                             src="{{protocol}}{{item.imageUrl}}"
                                             alt="">
                                    </script>
                                </wx-open-launch-weapp>
                                <img v-else alt="" width="100%"
                                     :src="item.imageUrl">
                            </div>
                        </a>
                    </cmui-list-item>
                </cmui-list>
                <div class="pos-a bottom0 left0 right0"
                     style=";height:14px;overflow: hidden;pointer-events:none;z-index: 1;" v-if="themeD === 3">
                    <div id="midShadow"
                         :style="{'box-shadow':(backColor?`${backColor} 0 0 0 300px`:'rgb(248, 248, 248) 0 0 0 300px')}"
                         style="background: transparent;height: 34px;width: 110%;border-radius: 50%;bottom: 0px;position: absolute;box-shadow: rgb(248, 248, 248) 0 0 0 300px;margin-left: -5%;"></div>
                </div>
                <!--
                <div class="defaultBg" :style="(themeD === 2 || themeD===3) && 'background:'+themeBg2">
                    <div class="paddingh20 pos-r" style="height:30px">
                        <div class="bg-white flex-container text-center pos-a left20 right20 paddingh20" style="border-radius:50px;height:60px;    box-shadow: 0px 2px 4px #EBEBEB">
                            <a class="flex1"
                               v-for="(item, index) in nav2.items"
                               :href="item.clickUrl"
                               :data-scp="`nav2.${index}`">
                                <div>
                                    <img alt="" width="30"
                                         :src="item.imageUrl">
                                    <div class="fs-10 text-darker lh-20" v-text="item.title"></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                -->
            </div>
        </div>

        <div v-if="bigEvent"
             class="pos-r" style="margin: 12px 12px 0;">
            <a :href="bigEvent.clickUrl"
               :data-scp="bigEvent.scpCD"
               :data-bk="bigEvent.bk"
               data-trace-id="">
                <div class="img-container">
                    <img :src="bigEvent.imageUrl"
                         style="width: 100%;"
                         alt="">
                </div>
            </a>
            <div class="pos-a bottom0 left0 right0"
                 style="padding: 12px;">
                <div class="flex-container">
                    <div v-for="item in bigEvent.items"
                         class="img-container">
                        <a :href="item.clickUrl"
                           :data-scp="item.scpCD"
                           :data-bk="item.bk"
                           data-trace-id="">
                            <img :src="item.imageUrl"
                                 width="104"
                                 height="104"
                                 alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="bigEventMore"
             class="overflow-h"
             style="margin: 12px 12px 0;">
            <div class="row">
                <div v-for="(item, index) in bigEventMore.items"
                     class="span6 img-container">
                    <a :href="item.clickUrl"
                       :data-scp="`bigtwo.${index}`"
                       :data-bk="item.bk"
                       data-trace-id="">
                        <img :src="item.imageUrl"
                             style="width: 100%;"
                             alt="">
                    </a>
                </div>
            </div>
        </div>

        <!--横版轮播-->
        <tg-slider v-if="slider" class="borderR8 overflow-h"
                   :data="slider.items"
                   scp-c="lunbo"
                   style="margin: 0 8px 8px;"></tg-slider>
        <!--功能豆-->
        <div v-if="funIcon" class="flex-container bg-white borderR8 text-center"
             :class="funIcon.items.length < 4 || funIcon.items.length === 6 || funIcon.items.length === 7 || funIcon.items.length === 9 ? 'left' : 'round'"
             style="margin: 0 8px 8px;padding: 12px 8px 8px;flex-wrap: wrap">
            <div v-for="(item, index) in funIcon.items" class="paddingb10"
                 :style="funIcon.items.length === 8 ? 'width: 25%' : 'width: 20%'">
                <a :href="item.clickUrl" class="borderR8 overflow-h"
                   :data-scp="item.scpCD"
                   :data-bk="item.bk"
                   data-trace-id="">
                    <img :src="item.imageUrl" alt="" width="48" style="margin: auto">
                    <p v-text="item.title" class="fs-12 text-black"></p>
                </a>
            </div>
        </div>
        <!--二宫格-->
        <div v-if="advLowPoster"
             class="overflow-h"
             style="margin: 0 8px 8px;">
            <div class="row">
                <div v-for="(item, index) in advLowPoster.items"
                     class="span6 img-container">
                    <a :href="item.clickUrl" class="borderR8 overflow-h" style="width: 100%"
                       :data-scp="`ntwo.${index}`"
                       :data-bk="item.bk"
                       data-trace-id="">
                        <img :src="item.imageUrl"
                             style="width: 100%;"
                             alt="">
                    </a>
                </div>
            </div>
        </div>

        <!--
            <div class="flex-container text-center margint20 bg-white">
                <a class="flex1"
                   v-for="(item, index) in nav2.items"
                   :href="item.clickUrl"
                   :data-scp="`nav2.${index}`">
                    <div>
                        <img alt="" width="50"
                             :src="item.imageUrl">
                        <div class="fs-12 text-darker lh-20" v-text="item.title"></div>
                    </div>
                </a>
            </div>
        -->

        <!--    <entrance-icon class="bg-white marginh20 paddingv20 borderR8" v-if="navigation.items.length" :data="navigation.items"></entrance-icon>-->

        <!--会员card--start-->
        <!--    <a href="/payCard.html" :data-scp="openScp" v-show="isShowCard">
                &lt;!&ndash;未登录 或 非会员&ndash;&gt;
                <div class="open_vip_card margint20 marginh20 paddingl20" v-if="!isLogin || isVip == 0" style="background-color: #171A24;">
                    <div class="flex-container paddingt30 paddingb20 text-shadow paddingr20" style="color: #E9B983">
                        <div class="fs-16 flex-container center">
                            <img src="//image1.51tiangou.com/tgou2/img2/giftCard/up-logo.png" style="height: 16px;" />
                            <span>会员限时开卡礼</span>
                        </div>
                        <div v-if="isVip == 0" class="fs-12">尽享全年折上95折起等特权</div>
                    </div>
                    <div class="flex-container paddingb20" style="flex-direction: column;">
                        <div class="coupon-list flex-container paddingt10 paddingb10">
                            <div class="coupon-description fs-12">
                                会员专享<img src="//image1.51tiangou.com/tgou2/img2/giftCard/tag.png" width="8" style="margin-right: 4px;">优惠券
                            </div>
                            <div class="scroll-container marginl10">
                                <div v-for="(coupon, $index) in couponList" class="pos-r coupon-item" :class="{'marginr20': $index === couponList.length - 1}">
                                    <div class="coupon-detail text-darker fs-12 lh-1 flex-container" style="align-items: baseline;">
                                        ¥<span style="font-size: 28px;">{{coupon.value}}</span>
                                        <span class="text-darker text-limit1 marginl10 dis-b" style="white-space: normal;height: 11px;font-weight: bold;">{{coupon.name}}</span>
                                    </div>
                                    <div class="useInfo pos-a top0 right0 text-center">
                                        <span style="line-height: 16px;">全额抵用</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isVip == 0 || !isVip" class="scroll-container paddingb20" style="width: 100%;">
                        <div class="marginr20" v-for="item in vipCouponProductList">
                            <div class="coupon img-container">
                                <img :src="item.imageUrl">
                            </div>
                            <div class="coupon-desc margint10 text-white fs-10">
                                <p class="text-center coupon-desc-title overflow-h">{{item.shortName}}</p>
                                <p class="text-center coupon-desc-rule">券后<span style="color: #E9B983;font-weight: bold;">¥{{item.vipPrice}}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                &lt;!&ndash;会员非会员日&ndash;&gt;
                <div class="open_vip_card margint20 marginh20" v-if="isLogin && isVip == 1 && !isMemberDay">
                    <div class="title padding30">
                        <div class="left">
                            <img src="//image1.51tiangou.com/tgou2/img2/giftCard/up-logo.png">
                            <span>会员中心</span>
                        </div>
                        <div class="right">
                            <span>尽享全年折上95折起等特权</span>
                        </div>
                    </div>
                    <div class="content">
                        <div class="scroll-container">
                            <div class="item" v-for="item in vipProductList">
                                <img :src="item.imageUrl">
                                <div class="foot paddingb20">
                                    <div class="fs-12 ehn" v-text="item.shortName"></div>
                                    <div class="foot_btm fs-11">
                                        <span v-text="'￥' + item.vipPrice"></span>
                                        <div class="em"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                &lt;!&ndash;会员且会员日&ndash;&gt;
                <div class="margint20 paddingh20" v-if="isLogin && isVip == 1 && isMemberDay"
                     :class="memberAdvImage ? 'vip_card' : 'open_vip_card'">
                    <img :src="memberAdvImage" v-if="memberAdvImage">
                    <div class="title padding30" v-else>
                        <div class="left">
                            <img src="//image1.51tiangou.com/tgou2/img2/giftCard/up-logo.png">
                            <span>会员中心</span>
                        </div>
                        <div class="right">
                            <span>尽享全年折上95折起等特权</span>
                        </div>
                    </div>
                    <div class="content">
                        <div class="scroll-container">
                            <div class="item" v-for="item in vipProductList">
                                <img :src="item.imageUrl">
                                <div class="foot paddingb20">
                                    <div class="fs-12 ehn" v-text="item.shortName"></div>
                                    <div class="foot_btm fs-11">
                                        <span v-text="'￥' + item.vipPrice"></span>
                                        <div class="em"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>-->
        <!--会员card--end-->

        <!-- 直播逛店 -->
        <!--
            <div class="margint20 marginh20 bg-white padding20 borderR8">
              <cmui-list :col="2" :space="20">
                <cmui-list-item>
                  <div class="fs-16 text-black flex-container text-bolder left">
                    直播逛店
                    <img src="//image1.51tiangou.com/tgou2/img2/index/index_live_1.png" class="marginl10" height="16" alt="">
                  </div>
                    <div class="margint20 pos-r"
                         data-scp="three.0"
                         style="width: 162px;height: 212px;overflow:hidden;border-radius: 4px;">
                        <div class="pos-r"
                             style="width: 162px;height: 306px;top: 50%;transform: translateY(-50%)"
                             v-if="liveList.length > 1">
                            <tg-slider :data="liveList"></tg-slider>
                        </div>
                        <img v-else-if="liveList.length === 1"
                             :src="liveList[0].imageUrl" width="100%" alt="">
                        <div style="position: absolute;top: 0; bottom: 0;left: 0; right: 0;overflow: hidden;"
                             v-if="isWeixin">
                            <wx-open-launch-weapp
                                :username="webcast.userName"
                                :path="webcast.list">
                                <script type="text/wxtag-template">
                                  <div style="width: 162px;height: 212px;"></div>
                              </script>
                            </wx-open-launch-weapp>
                      </div>
                  </div>
                </cmui-list-item>
                <cmui-list-item>
                  <div class="clearfix">
                      <a :href="itemHot.clickUrl" data-scp="three.1">
                          <div class="fs-16 text-black flex-container text-bolder left">
                              全球好货
                              <img src="//image1.51tiangou.com/tgou2/img2/index/index_live_2.png" class="marginl10" height="16" alt="">
                          </div>
                          <cmui-list :col="2" :space="10" class="margint20">
                              <cmui-list-item v-for="item in itemHot.list">
                                  <img :src="item.imageUrl" style="border-radius: 4px;" alt="">
                              </cmui-list-item>
                          </cmui-list>
                      </a>
                  </div>
                  <div class="clearfix margint40">
                    <div class="fs-16 text-black flex-container text-bolder left">
                      大商优选
                      <img src="//image1.51tiangou.com/tgou2/img2/index/index_live_3.png" class="marginl10" height="16" alt="">
                    </div>
                      <div class="margint20">
                          <a :href="dsTopOut.clickUrl" data-scp="three.2">
                              <img :src="dsTopOut.imageUrl" style="border-radius: 4px;" alt="">
                          </a>
                      </div>
        &lt;!&ndash;            <cmui-list :col="2" :space="10" class="margint20">&ndash;&gt;
        &lt;!&ndash;              <cmui-list-item v-for="item in dsTopOut">&ndash;&gt;
        &lt;!&ndash;	              &ndash;&gt;
        &lt;!&ndash;              </cmui-list-item>&ndash;&gt;
        &lt;!&ndash;            </cmui-list>&ndash;&gt;
                  </div>
                </cmui-list-item>
              </cmui-list>
            </div>
        -->

        <!--    <tg-poster :data="adPoster" class="margint20 marginh20"></tg-poster>-->

        <!-- 惠品牌 / 大商自有 -->
        <div v-if="brandBar" style="margin: 0 8px 8px;">
            <div class="flex-container" style="height: 48px;margin-bottom: 8px;margin-left: -12px;">
                <a :href="brandBar.clickUrl"
                   data-scp="dszy.0"
                   :data-bk="brandBar.imgBk">
                    <img :src="brandBar.imageUrl"
                         width="104"
                         height="48"
                         alt="">
                </a>
                <a v-if="brandBar.subTitle" :href="brandBar.subClickUrl"
                   data-scp="dszy.1"
                   :data-bk="brandBar.subBk">
                    <img :src="brandBar.subTitle"
                         width="174"
                         height="48"
                         alt="">
                </a>
            </div>

            <div class="bg-white flex-container brandBar-container">
                <div v-for="item in brandBar.items"
                     class="brandBar-item"
                     :style="(brandBar.items.length === 4 || brandBar.items.length === 8) && 'width: 25%;'">
                    <a :href="item.clickUrl"
                       :data-scp="item.scpCD"
                       :data-bk="item.bk"
                       data-trace-id="">
                        <div class="img-container" style="margin-bottom: 4px;">
                            <img :src="item.imageUrl"
                                 width="56"
                                 height="56"
                                 alt="">
                        </div>
                        <div v-if="item.subTitle"
                             v-text="item.subTitle"
                             class="text-center text-bolder text-black"
                             style="font-size: 10px;line-height: 10px;"></div>
                        <div v-else
                             class="text-center text-bolder"
                             style="font-size: 10px;line-height: 10px;">&emsp;</div>
                    </a>
                </div>
            </div>
        </div>

        <a v-if="licenseBar"
           :href="licenseBar.clickUrl"
           :data-scp="licenseBar.scpCD"
           data-trace-id="">
            <div class="flex-container bg-white"
                 style="margin: 0 8px 8px;padding: 7px 12px 7px 8px;border-radius: 8px;">
                <div class="img-container">
                    <img :src="licenseBar.imageUrl"
                         width="48"
                         height="28"
                         alt="">
                </div>
                <div v-text="licenseBar.title"
                     class="fs-12 text-dark"
                     style="line-height: 34px;"></div>
                <div class="img-container">
                    <img src="//image1.51tiangou.com/tgou2/img2/index/icon_arrow_right.png"
                         width="24"
                         height="24"
                         alt="">
                </div>
            </div>
        </a>

        <!-- 业务条 -->
        <div v-if="bizBar" style="margin: 0 8px 8px;">
            <div class="row">
                <div class="span4 img-container poa-r">
                    <a :href="bizBar.items[0].clickUrl"
                       :data-scp="bizBar.items[0].scpCD"
                       :data-bk="bizBar.items[0].bk"
                       data-trace-id="">
                        <img :src="bizBar.items[0].imageUrl"
                             style="width: 100%;"
                             alt="">
                        <div class="pos-a text-dark fs-10 paddingl10"
                             style="bottom: 6px;left: 12px;right: 0;width: auto;height: 10px;line-height: 10px;">
						<span v-if="bizBar.items[0].subTitleList.length === 1"
                              v-text="bizBar.items[0].subTitleList[0]"></span>
                            <cmui-slider v-else-if="bizBar.items[0].subTitleList.length > 1"
                                         :options="sliderOptions"
                                         :loop="true"
                                         :page="false">
                                <cmui-slider-item v-for="item in bizBar.items[0].subTitleList">
                                    <div v-text="item"></div>
                                </cmui-slider-item>
                            </cmui-slider>
                        </div>
                    </a>
                </div>
                <div class="span4 img-container pos-r">
                    <a :href="bizBar.items[1].clickUrl"
                       :data-scp="bizBar.items[1].scpCD"
                       :data-bk="bizBar.items[1].bk"
                       data-trace-id="">
                        <img :src="bizBar.items[1].imageUrl"
                             style="width: 100%;"
                             alt="">
                        <div class="pos-a text-dark fs-10 paddingl10"
                             style="bottom: 6px;left: 12px;right: 0;width: auto;height: 10px;line-height: 10px;">
						<span v-if="bizBar.items[1].subTitleList.length === 1"
                              v-text="bizBar.items[1].subTitleList[0]"></span>
                            <cmui-slider v-else-if="bizBar.items[1].subTitleList.length > 1"
                                         :options="sliderOptions"
                                         :loop="true"
                                         :page="false">
                                <cmui-slider-item v-for="item in bizBar.items[1].subTitleList">
                                    <div v-text="item"></div>
                                </cmui-slider-item>
                            </cmui-slider>
                        </div>
                    </a>
                </div>
                <div class="span4 img-container pos-r">
                    <a :href="bizBar.items[2].clickUrl"
                       :data-scp="bizBar.items[2].scpCD"
                       :data-bk="bizBar.items[2].bk"
                       data-trace-id="">
                        <img :src="bizBar.items[2].imageUrl"
                             style="width: 100%;"
                             alt="">
                        <div class="pos-a text-dark fs-10 paddingl10"
                             style="bottom: 6px;left: 12px;right: 0;width: auto;height: 10px;line-height: 10px;">
						<span v-if="bizBar.items[2].subTitleList.length === 1"
                              v-text="bizBar.items[2].subTitleList[0]"></span>
                            <cmui-slider v-else-if="bizBar.items[2].subTitleList.length > 1"
                                         :options="sliderOptions"
                                         :loop="true"
                                         :page="false">
                                <cmui-slider-item v-for="item in bizBar.items[2].subTitleList">
                                    <div v-text="item"></div>
                                </cmui-slider-item>
                            </cmui-slider>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <!--新三宫格不限高-->
        <div v-if="advLimPoster" style="margin: 0 8px 8px;">
            <div class="row">
                <div class="span4 img-container pos-r">
                    <a :href="advLimPoster.items[0].clickUrl"
                       class="borderR8 overflow-h"
                       :data-scp="advLimPoster.items[0].scpCD"
                       :data-bk="advLimPoster.items[0].bk"
                       data-trace-id="">
                        <img :src="advLimPoster.items[0].imageUrl"
                             style="width: 100%;"
                             alt="">
                        <div class="pos-a text-white fs-10 text-center"
                             style="bottom: 10px;left: 0;right: 0;width: auto;height: 10px;line-height: 10px;">
						<span v-if="advLimPoster.items[0].subTitleList.length === 1"
                              v-text="advLimPoster.items[0].subTitleList[0]"></span>
                            <cmui-slider v-else-if="advLimPoster.items[0].subTitleList.length > 1"
                                         :options="sliderOptions"
                                         :loop="true"
                                         :page="false">
                                <cmui-slider-item v-for="item in advLimPoster.items[0].subTitleList">
                                    <div v-text="item"></div>
                                </cmui-slider-item>
                            </cmui-slider>
                        </div>
                    </a>
                </div>
                <div class="span4 img-container pos-r">
                    <a :href="advLimPoster.items[1].clickUrl"
                       class="borderR8 overflow-h"
                       :data-scp="advLimPoster.items[1].scpCD"
                       :data-bk="advLimPoster.items[1].bk"
                       data-trace-id="">
                        <img :src="advLimPoster.items[1].imageUrl"
                             style="width: 100%;"
                             alt="">
                        <div class="pos-a text-white fs-10 text-center"
                             style="bottom: 10px;left: 0;right: 0;width: auto;height: 10px;line-height: 10px;">
						<span v-if="advLimPoster.items[1].subTitleList.length === 1"
                              v-text="advLimPoster.items[1].subTitleList[0]"></span>
                            <cmui-slider v-else-if="advLimPoster.items[1].subTitleList.length > 1"
                                         :options="sliderOptions"
                                         :loop="true"
                                         :page="false">
                                <cmui-slider-item v-for="item in advLimPoster.items[1].subTitleList">
                                    <div v-text="item"></div>
                                </cmui-slider-item>
                            </cmui-slider>
                        </div>
                    </a>
                </div>
                <div class="span4 img-container pos-r">
                    <a :href="advLimPoster.items[2].clickUrl"
                       class="borderR8 overflow-h"
                       :data-scp="advLimPoster.items[2].scpCD"
                       :data-bk="advLimPoster.items[2].bk"
                       data-trace-id="">
                        <img :src="advLimPoster.items[2].imageUrl"
                             style="width: 100%;"
                             alt="">
                        <div class="pos-a text-white fs-10 text-center"
                             style="bottom: 10px;left: 0;right: 0;width: auto;height: 10px;line-height: 10px;">
						<span v-if="advLimPoster.items[2].subTitleList.length === 1"
                              v-text="advLimPoster.items[2].subTitleList[0]"></span>
                            <cmui-slider v-else-if="advLimPoster.items[2].subTitleList.length > 1"
                                         :options="sliderOptions"
                                         :loop="true"
                                         :page="false">
                                <cmui-slider-item v-for="item in advLimPoster.items[2].subTitleList">
                                    <div v-text="item"></div>
                                </cmui-slider-item>
                            </cmui-slider>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <!-- 每日秒杀 / 限时买必省 55623 每日秒杀改版 删除了一些旧代码。。。 -->
        <!--	<maibisheng :data="saleProduct" theme="3" v-if="saleProduct.length > 0"></maibisheng>-->
        <div v-if="maibisheng" style="margin: 0 8px 8px;" class="bg-miaosha borderR8" :data-scp="maibisheng.scpCD"
             @click="changePage(maibisheng.clickUrl, maibisheng.scpCD)" :style="maibisheng.imageUrl ? `background-image: url('${maibisheng.imageUrl}');` : ''">
            <div class="bg-count-down flex-container right">
                <div class="fs-14 text-white" style="margin-right: 8px">本场结束剩余</div>
                <cmui-countdown :now-time="new Date().getTime()" :end-time="msEndTime" class="fs-12 text-black"></cmui-countdown>
            </div>
            <div class="flex-container top" style="margin-top: 14px">
                <div v-for="(item, index) in maibisheng.items.slice(0, 4)"
                     class="text-center"
                     style="width: calc(25% - 5px)">
                    <a :href="item.clickUrl"
                       :data-scp="item.scpCD"
                       :data-bk="item.bk"
                       :data-trace-id="item.traceId">
                        <div class="img-container pos-r" style="overflow: visible">
                            <img :src="item.imageUrl" alt="" style="border-radius: 8px;">
                            <div v-if="item.price !== item.originalPrice" style="line-height: 15px;padding: 0 6px;white-space: nowrap"
                                 class="btn-red pos-a bg-red text-white fs-10 bottom0 overflow-h borderR8">
                                补贴￥<span v-text="parseFloat((item.originalPrice - item.price).toFixed(2))"></span>
                            </div>
                            <div v-else style="line-height: 15px;padding: 0 6px;white-space: nowrap"
                                 class="btn-red pos-a bg-red text-white fs-10 bottom0 overflow-h borderR8">秒杀</div>
                        </div>
                        <div v-if="item.title" v-text="item.title.slice(0, 7)"
                             class="fs-12 text-black"
                             style="margin-top: 8px;overflow: hidden;white-space: nowrap;text-overflow: clip;scale: 0.92;"></div>
                        <p class="fs-12 text-black" style="line-height: 1;font-family: DINAlternate-Bold;">¥
                            <span v-text="item.price" class="fs-16"></span>
                        </p>
                    </a>
                </div>
            </div>
        </div>

        <!-- 品牌旗舰店 -->
        <!--
            <div class="margint20 marginh20 bg-white borderR8 padding20"
                 v-if="showBrandList.length>2">
                <div class="flex-container">
                    <div class="flex-container left lh-1">
                        <div class="fs-16 text-black text-bolder">品牌旗舰店</div>
                        <div class="fs-12 text-light marginl10">周周特惠价</div>
                    </div>
                    <div class="fs-12 lh-1">
                        <a href="/counter/directSupplyOfBrand.html"
                           data-scp="pmore.0"
                           class="text-light flex-container right">
                            <span>更多</span>
                            <img src="//image1.51tiangou.com/tgou2/img2/index/ico-right.png" class="marginl10" height="16"
                                 alt="">
                        </a>
                    </div>
                </div>
                <div class="margint20">
                    <cmui-list :col="3" :space="20">
                        <cmui-list-item v-for="(item, index) in showBrandList.slice(0, 3)">
                            <a :href="'/counter/flagshipStore.html?brandId='+item.fkBrandId"
                               :data-scp="`brand.${index}`"
                               class="dis-b" style="border-radius:4px;overflow: hidden;">
                                <img :src="item.imageUrl" alt="">
                            </a>
                        </cmui-list-item>
                    </cmui-list>
                </div>
            </div>
        -->

        <!-- 限时秒杀/品牌特卖	-->
        <!--
            <div class="flex-container left center lh-1 paddingh20 paddingv30" v-if="secKillLineTitle.title">
                <img src="//image1.51tiangou.com/tgou2/img2/index/ico-index-xianshiqiang.png" width="20" alt="">
                <span class="marginl10">限时抢</span>
                <span class="fs-12 text-light marginl10">低价秒杀</span>
            </div>
            &lt;!&ndash; <div class="padding20 margint20 fs-17 text-darker text-bolder lh-1" v-text="secKillLineTitle.title"></div> &ndash;&gt;
            <div class="clearfix">
                <time-limit :index="index" v-for="(item,index) in secKillEntry" :data="item" :type="item.location-2||1"></time-limit>
            </div>
        -->

        <!-- 人气排行 -->
        <!--
             <div class="paddingh20 margint10" v-if="hotItems.items.length">
                <div @click="changePage(hotItemsLineTitle.clickUrl,'hotItems.0')" >
                    <div class="flex-container paddingv20 bottom">
                        <div class="fs-17 text-darker text-bolder" v-text="hotItemsLineTitle.title"></div>
                        <a class="text-dark fs-12" :href="hotItemsLineTitle.clickUrl" data-scp="hotItems.0">
                            <span v-text="hotItemsLineTitle.subTitle"></span>
                            <i class="icon-right"></i>
                        </a>
                    </div>
                    <div style="background: #F2EDED">
                        <popularity :data="hotItems.items"></popularity>
                    </div>
                </div>
            </div>
        -->

        <!--发现视频-->
        <!--
            <div v-if="discoveryList.items.length" class="paddingh20 paddingt20">
                <div class="fs-17 text-darker text-bolder marginv20">好物圈视频</div>
                <cmui-list :col="2" :space="10">
                    <cmui-list-item v-for="(item,index) in discoveryList.items">
                        <a :href="item.clickUrl" :data-scp="`find.${index}`">
                            <div class="ratio-container-2x3 border-radius overflow-h">
                                <div class="img-container center bg-black">
                                    <img :src="item.imageUrls[0].imageUrl" alt="" style="width:100%">
                                </div>
                                <div style="pointer-events: none;background: url('//image1.51tiangou.com/tgou2/img2/index/bg-discoveryList.png') no-repeat center bottom;background-size:100% auto" class="flex-container-col">
                                    <div style="width:100%">
                                    <span class="badge pill marginl10 margint10 flex-container left" style="background: rgba(0,0,0,0.4);">
                                        <div class="flex-container left">
                                            <i class="icon icon-card_invisibleShow" style="margin-top: 0px;"></i>
                                            <span class="marginl10">{{item.browseNum}}</span>
                                        </div>
                                    </span>
                                    </div>
                                    <div style="width:100%" class="padding10">
                                        <p class="text-limit1 text-white fs-15">{{item.content}}</p>
                                        <div class="flex-container left">
                                            <div class="img-container round marginr10" style="height:24px;width:24px;">
                                                <img :src="item.headImageUrl" alt="">
                                            </div>
                                            <span class="text-white fs-12">{{item.nickName}}</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </a>
                    </cmui-list-item>
                </cmui-list>
            </div>
        -->

        <!-- 瀑布流 -->
        <div v-if="product && product.length" style="margin: 0 8px">
            <div id="waterfall"
                 class="waterfall row">
                <!--竖版轮播-->
                <div class="item item-slider span12" v-if="waterfallSlider.items.length"
                     :class="waterfallSlider.span && `item-span-${waterfallSlider.span}`" style="border-radius: 8px;overflow:hidden;">
                    <tg-slider :data="waterfallSlider.items" style="border-radius: 8px;overflow:hidden;"
                               scp-c="slunbo"></tg-slider>
                </div>
                <!--排行榜 与 品牌惠购 广告位-->
                <div v-for="item in halfOneGrid" class="item span12" :class="item.span && `item-span-${item.span}`">
                    <div class="img-container box" style="background: transparent">
                        <a :href="item.clickUrl"
                           :data-scp="item.scpCD"
                           :data-bk="item.bk"
                           data-trace-id="" style="width: 100%">
                            <img :src="item.imageUrl" width="100%" alt="">
                        </a>
                    </div>
                </div>
                <template v-for="(item, index) in productList">
                    <!--第一个广告位-->
                    <div v-if="index === 3 && waterfallAd && waterfallAd.items.length > 0"
                         :class="waterfallAd.items[0].span && `item-span-${waterfallAd.items[0].span}`"
                         class="item item-ad span12">
                        <div class="img-container box">
                            <a :href="waterfallAd.items[0].clickUrl"
                               :data-scp="waterfallAd.items[0].scpCD"
                               :data-bk="waterfallAd.items[0].bk"
                               data-trace-id="" style="width: 100%">
                                <img :src="waterfallAd.items[0].imageUrl" width="100%"
                                     alt="">
                            </a>
                        </div>
                    </div>
                    <!--直播入口-->
                    <div v-if="index === 3 && liveList && liveList.items.length"
                         class="item item-live span12">
                        <div class="img-container box">
                            <a :href="liveList.items[0].clickUrl"
                               :data-scp="liveList.items[0].scpCD"
                               :data-bk="liveList.items[0].bk"
                               data-trace-id="" style="width: 100%">
                                <img :src="liveList.items[0].imageUrl" width="100%"
                                     alt="">
                            </a>
                        </div>
                    </div>
                    <!--广告位-->
                    <div v-if="index > 4 + liveList.items.length && computeIndex(index) %5 === 0 && waterfallAd && waterfallAd.items.length > Math.floor(computeIndex(index) /5)"
                         :class="waterfallAd.items[Math.floor(computeIndex(index) /5)].span && `item-span-${waterfallAd.items[Math.floor(computeIndex(index) /5)].span}`"
                         class="item item-ad span12">
                        <div class="img-container box">
                            <a :href="waterfallAd.items[Math.floor(computeIndex(index) /5)].clickUrl"
                               :data-scp="waterfallAd.items[Math.floor(computeIndex(index) /5)].scpCD"
                               :data-bk="waterfallAd.items[Math.floor(computeIndex(index) /5)].bk"
                               data-trace-id="" style="width: 100%">
                                <img :src="waterfallAd.items[Math.floor(computeIndex(index) /5)].imageUrl"
                                     alt="" width="100%">
                            </a>
                        </div>
                    </div>
                    <!--商品-->
                    <div v-if="item"
                         :class="{'item-coupon': item.couponList && item.couponList.length, 'item-long': item.longTitle, 'item-latest' : item.newProduct, 'item-sortRank' : item.itemSortRank}"
                         class="item span12">
                        <div class="box" style="padding-bottom: 8px">
                            <a :href="item.clickUrl"
                               :data-store-id="item.storeId"
                               :data-trace-id="item.traceId ? item.traceId : ''"
                               :data-scp="`pproduct.${index}`"
                               :data-bk="`item-${item.id}`">
                                <!--商品图-->
                                <div class="img-container">
                                    <img :src="item.imageUrl" alt="">
                                </div>
                                <!--标题文字-->
                                <div class="fs-14 lh-20" style="padding: 5px 8px;">
                                    <div style="overflow: hidden;max-height: 20px;">
                                        <img :src="getTagByItem(item).imgUrl"
                                             style="vertical-align: text-top;"
                                             v-if="item.source === 4 && item.storeType === 3"
                                             alt="" height="16px">
                                        {{item.title}}
                                    </div>
                                </div>
                                <!--促销优惠标签-->
                                <div v-if="item.couponList && item.couponList.length"
                                     class="overflow-h"
                                     style="height: 20px; padding-left: 8px;">
                                    <coupon :data="item.couponList.slice(0, 2)"></coupon>
                                </div>
                                <!--价格-->
                                <div class="text-red" style="height: 27px;padding: 0 8px;line-height: 27px;">
                                    <span>
                                        <span class="fs-10 text-bolder">¥</span>
                                        <span v-if="item.probablyPrice" class="fs-20"
                                              style="margin-left: -4px;">
                                            <span class="fs-20 text-bolder"
                                                  v-text="splitPriceInt(item.probablyPrice)"></span><span class="fs-14 text-bolder"
                                                                                                          v-text="splitPrice(item.probablyPrice)"></span>
                                        </span>
                                        <span v-else-if="item.isPin"
                                              style="margin-left: -4px;">
                                            <span class="fs-20 text-bolder"
                                                  v-text="splitPriceInt(item.groupPrice)"></span><span class="fs-14 text-bolder"
                                                                                                       v-text="splitPrice(item.groupPrice)"></span>
                                        </span>
                                        <span v-else-if="item.isSecKill"
                                              style="margin-left: -4px;">
                                            <span class="fs-20 text-bolder"
                                                  v-text="splitPriceInt(item.buyoutPrice)"></span><span class="fs-14 text-bolder"
                                                                                                        v-text="splitPrice(item.buyoutPrice)"></span>
                                        </span>
                                        <span v-else-if="item.newPrice"
                                              style="margin-left: -4px;">
                                            <span class="fs-20 text-bolder"
                                                  v-text="splitPriceInt(item.newPrice)"></span><span class="fs-14 text-bolder"
                                                                                                     v-text="splitPrice(item.newPrice)"></span>
                                        </span>
                                        <span v-else
                                              style="margin-left: -4px;">
                                            <span class="fs-20 text-bolder"
                                                  v-text="splitPriceInt(item.price)"></span><span class="fs-14 text-bolder"
                                                                                                  v-text="splitPrice(item.price)"></span>
                                        </span>
                                    </span>
                                    <!-- <span v-if="item.vipPrice"
                                          v-text="`¥${item.vipPrice}`"
                                          style="background: #000 url('//image1.51tiangou.com/tgou2/img2/giftCard/up-logo.png') no-repeat 5px center/ 15px;border-radius: 9px;padding: 0 8px 0 27px;"
                                          class="dis-i text-white fs-12 lh-16"></span> -->
                                    <del v-if="item.originalPrice && item.originalPrice !== item.price && !item.isPin && !item.isSecKill && !item.newPrice && !item.vipPrice && !item.probablyPrice"
                                         v-text="`¥${item.originalPrice}`"
                                         class="fs-10 text-light" style="vertical-align: top;"></del>
                                    <span class="fs-10 text-bolder" style="vertical-align: top" v-if="item.probablyPrice">预估到手</span>
                                    <span class="fs-10 text-bolder" style="vertical-align: top" v-else-if="item.isPin">拼团价</span>
                                    <span class="fs-10 text-bolder" style="vertical-align: top" v-else-if="item.isSecKill">秒杀价</span>
                                    <span class="fs-10 text-bolder" style="vertical-align: top" v-else-if="item.newPrice">新人价</span>
                                    <span v-if="item.soldQty" class="float-right fs-10 text-light">{{ item.soldQty }}人购买</span>
                                </div>
                                <p v-if="item.newProduct" class="fs-10" style="color: #F7B487; padding-left: 8px;">“近期上新单品”</p>
                            </a>
                            <!-- <a :href="item.storeClickUrl"
                               :data-trace-id="item.traceId"
                               :data-scp="`pproduct.${index}`"
                               :data-bk="`item-${item.id}`">
                                <div class="flex-container"
                                     style="height: 28px;padding: 0 8px;line-height: 28px;">
                                    <div v-text="getTagByItem(item).text"
                                         :class="getTagByItem(item).className"
                                         class="fs-10 text-white lh-1"
                                         style="border-radius:4px; padding:2px 4px;"></div>
                                    <img :src="getTagByItem(item).imgUrl" height="16px">
                                    <div class="text-light flex-container">
                                        <div v-text="item.storeName"
                                             class="fs-10 lh-10 text-limit1 text-right"
                                             style="width: 8em;margin-right: -4px;"></div>
                                        <div class="img-container pos-r" style="width: 16px;height: 16px;">
                                            <img src="//image1.51tiangou.com/tgou2/img2/index/icon_arrow_right.svg"
                                                 class="pos-a"
                                                 style="left: -12px;top: 0;width: 16px;height: 16px;filter: drop-shadow(16px 0px #B3B3B3)"
                                                 width="16"
                                                 height="16"
                                                 alt="">
                                        </div>
                                    </div>
                                </div>
                            </a> -->
                        </div>
                        <!--排行榜-->
                        <a v-if="item.itemSortRank" class="fs-10 dis-i" :href="item.itemSortRank.clickUrl"
                           :data-scp="`spphb.${index}`"
                           :data-bk="`id-${item.itemSortRank.rankId}`"
                           style="width: 100%;background: #FFF2D1;color: #664E10;line-height: 22px;padding: 8px 8px 0;border-radius: 0 0 8px 8px;margin-top: -8px">
                            <span v-if="item.itemSortRank.sort">{{item.itemSortRank.rankName }} · 第{{item.itemSortRank.sort}}名</span>
                            <span v-else>入选{{item.itemSortRank.rankName }}</span>
                            <img src="//image1.51tiangou.com/tgou2/img2/index/icon-rank-arrow.png"
                                 class="float-right dis-i" alt="" width="8" style="margin-top: 5px">
                        </a>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <!-- 分类tab展示的广告位 -->
    <div v-show="curClassify !== -1" style="padding: 0 8px;">
        <!-- 横屏轮播 -->
        <tg-slider v-if="classifySlider" class="borderR8 overflow-h"
                   :data="classifySlider.items"
                   scp-c="lunbo"
                   style="margin-bottom: 8px;"></tg-slider>
        <!-- 热门分类 -->
        <div class="bg-white hot-classify" v-if="hotCategories && hotCategories[0] && hotCategories[0].items && hotCategories[0].items.length">
            <p class="fs-16 text-black text-bolder" style="margin-bottom: 8px;padding-left: 8px">热门分类</p>
            <div v-for="item in hotCategories[0].items" class="dis-i text-center fs-12 text-dark"
                 style="width: 20%;margin-bottom: 8px;padding: 0 3px;overflow: hidden;">
                <a :href="item.clickUrl" :data-scp="item.scpCD" :data-bk="item.bk" data-trace-id="">
                    <div class="img-container" style="margin: 0 auto 8px;width: 50px; height: 50px;">
                        <img :src="item.imageUrl" alt="">
                    </div>
                    <span>{{item.title.slice(0, 5)}}</span>
                </a>
            </div>
        </div>
        <!-- 一宫格 -->
        <div v-if="classifyOneGrid" class="overflow-h borderR8" style="margin-bottom: 8px;">
            <div class="row">
                <div class="img-container">
                    <a :href="classifyOneGrid.items[0].clickUrl"
                       :data-scp="classifyOneGrid.items[0].scpCD"
                       :data-bk="classifyOneGrid.items[0].bk"
                       data-trace-id="">
                        <img :src="classifyOneGrid.items[0].imageUrl" style="width: 100%;" alt="">
                    </a>
                </div>
            </div>
        </div>
        <!-- 二宫格 -->
        <div v-if="classifyTwoGrid" class="overflow-h" style="margin-bottom: 8px;">
            <div class="row">
                <div v-for="(item, index) in classifyTwoGrid.items" class="span6 img-container">
                    <a :href="item.clickUrl" class="borderR8 overflow-h"
                       :data-scp="item.scpCD"
                       :data-bk="item.bk"
                       data-trace-id="">
                        <img :src="item.imageUrl" style="width: 100%;" alt="">
                    </a>
                </div>
            </div>
        </div>
        <!-- 热门品牌 -->
        <div class="bg-white borderR8" v-if="hotBrands && hotBrands.items" style="margin-bottom: 8px;padding-top: 8px;">
            <p class="fs-16 text-black text-bolder" style="margin-bottom: 8px;padding-left: 8px">热门品牌</p>
            <div style="padding: 0 8px">
                <a :href="item.clickUrl" v-for="item in hotBrands.items.slice(0, 8)"
                     :data-scp="item.scpCD" :data-bk="item.bk" data-trace-id=""
                     class="dis-i hot-brands borderR8 overflow-h" style="border: 1px solid #f3f3f3;">
                    <img :src="item.imageUrl" alt="">
                </a>
            </div>
        </div>
        <!-- 瀑布流 -->
        <div v-if="classifyProduct && classifyProduct.length" style="padding-top: 8px" class="pos-r">
            <div class="bg-change-color pos-a top0 borderR8"></div>
            <ul class="sort-list flex-container fs-12 text-dark pos-r paddingh10" style="margin-bottom: 8px">
                <li @click="tabSort(1)" :class="curSort === 1 ? 'active text-red' : ''" data-scp="tabBar.0">月销</li>
                <li @click="tabSort(2)" class="pos-r price-sort" style="padding-left: 23px;padding-right: 29px;"
                    :class="priceSort" data-scp="tabBar.1">价格</li>
                <li @click="tabSort(3)" :class="curSort === 3 ? 'active text-red' : ''" data-scp="tabBar.2">综合</li>
                <li @click="openBrandPanel = !openBrandPanel" :class="{'active text-red': chooseFilterBrand.length > 0 || openBrandPanel, 'open': openBrandPanel}"
                    class="brand-screen" style="padding-right: 8px;" v-text="chooseBrandName" data-scp="filter.show"></li>
            </ul>
            <!--筛选面板-->
            <div v-show="openBrandPanel" class="bg-white fs-12 text-black pos-a borderR8"
                 style="width: 100%;padding-left: 8px;padding-bottom: 44px;box-shadow: 0 4px 8px 0 rgba(0,0,0,.1);z-index: 2">
                <ul class="scroll-container-y" style="max-height: 178px;">
                    <li v-for="(item, index) in filterBrand" class="dis-i"
                        :data-scp="`filterBrand.${index}`"
                        :class="item.choose ? 'text-red' : ''" style="width: 50%;line-height: 30px"
                        @click="clickFilterBrand(item)">{{item.name}}</li>
                </ul>
                <div class="flex-container pos-a text-center fs-12 left0 right0" style="bottom: 8px">
                    <div class="flex1 text-bslack" data-scp="filter.reset"
                         style="background: #e6e6e6;line-height: 32px; border-radius: 32px;margin: 0 8px"
                         @click="resetFilter()">重置</div>
                    <div class="flex1 text-white bg-red" data-scp="filter.set"
                         style="line-height: 32px; border-radius: 32px;margin: 0 8px"
                         @click="setFilter()">确定</div>
                </div>
            </div>
            <div id="waterfall"
                 class="waterfall row jsProductPanel">
                <template v-for="(item, index) in classifyProductList">
                    <!--商品-->
                    <div v-if="item"
                         :class="{'item-coupon': item.couponList && item.couponList.length, 'item-long': item.longTitle, 'item-latest' : item.newProduct}"
                         class="item span12">
                        <div class="box" style="padding-bottom: 8px">
                            <a :href="item.clickUrl"
                               :data-store-id="item.storeId"
                               :data-trace-id="item.traceId ? item.traceId : ''"
                               :data-scp="`pproduct.${index}`"
                               :data-bk="`item-${item.id}`">
                                <!--商品图-->
                                <div class="img-container">
                                    <img :src="item.imageUrl" alt="">
                                </div>
                                <!--标题文字-->
                                <div class="fs-14 lh-20"
                                     style="padding: 5px 8px;">
                                    <div style="overflow: hidden;max-height: 40px;">
                                        <img :src="getTagByItem(item).imgUrl"
                                             style="vertical-align: text-top;"
                                             v-if="item.source === 4 && item.storeType === 3"
                                             alt="" height="16px">
                                        {{item.title}}
                                    </div>
                                </div>
                                <!--促销优惠标签-->
                                <div v-if="item.couponList && item.couponList.length"
                                     class="overflow-h"
                                     style="height: 20px; padding-left: 8px;">
                                    <coupon :data="item.couponList.slice(0, 2)"></coupon>
                                </div>
                                <!--价格-->
                                <div style="height: 27px;padding: 0 8px;line-height: 27px;">
                                    <span>
                                        <span class="fs-10 text-bolder">¥</span>
                                        <span v-if="item.isPin"
                                              v-text="item.groupPrice"
                                              class="fs-20"
                                              style="margin-left: -4px;"></span>
                                        <span v-else-if="item.isSecKill"
                                              v-text="item.buyoutPrice"
                                              class="fs-20"
                                              style="margin-left: -4px;"></span>
                                        <span v-else-if="item.newPrice"
                                              v-text="item.newPrice"
                                              class="fs-20"
                                              style="margin-left: -4px;"></span>
                                        <span v-else
                                              v-text="item.price"
                                              class="fs-20"
                                              style="margin-left: -4px;"></span>
                                    </span>
                                    <!-- <span v-if="item.vipPrice"
                                          v-text="`¥${item.vipPrice}`"
                                          style="background: #000 url('//image1.51tiangou.com/tgou2/img2/giftCard/up-logo.png') no-repeat 5px center/ 15px;border-radius: 9px;padding: 0 8px 0 27px;"
                                          class="dis-i text-white fs-12 lh-16"></span> -->
                                    <del v-if="item.originalPrice && item.originalPrice !== item.price && !item.isPin && !item.isSecKill && !item.newPrice && !item.vipPrice"
                                         v-text="`¥${item.originalPrice}`"
                                         class="fs-10 text-light" style="vertical-align: top;"></del>
                                    <span class="fs-10 text-bolder" style="vertical-align: top" v-if="item.isPin">拼团价</span>
                                    <span class="fs-10 text-bolder" style="vertical-align: top" v-else-if="item.isSecKill">秒杀价</span>
                                    <span class="fs-10 text-bolder text-red" style="vertical-align: top" v-else-if="item.newPrice">新人价</span>
                                    <span v-if="item.soldQty" class="float-right fs-10 text-light">{{ item.soldQty }}人购买</span>
                                </div>
                                <p v-if="item.newProduct" class="fs-10" style="color: #F7B487; padding-left: 8px;">“近期上新单品”</p>
                            </a>
                            <!-- <a :href="item.storeClickUrl"
                               :data-trace-id="item.traceId"
                               :data-scp="`pproduct.${index}`"
                               :data-bk="`item-${item.id}`">
                                <div class="flex-container"
                                     style="height: 28px;padding: 0 8px;line-height: 28px;">
                                    <div v-text="getTagByItem(item).text"
                                         :class="getTagByItem(item).className"
                                         class="fs-10 text-white lh-1"
                                         style="border-radius:4px; padding:2px 4px;"></div>
                                    <img :src="getTagByItem(item).imgUrl" height="16px">
                                    <div class="text-light flex-container">
                                        <div v-text="item.storeName"
                                             class="fs-10 lh-10 text-limit1 text-right"
                                             style="width: 8em;margin-right: -4px;"></div>
                                        <div class="img-container pos-r" style="width: 16px;height: 16px;">
                                            <img src="//image1.51tiangou.com/tgou2/img2/index/icon_arrow_right.svg"
                                                 class="pos-a"
                                                 style="left: -12px;top: 0;width: 16px;height: 16px;filter: drop-shadow(16px 0px #B3B3B3)"
                                                 width="16"
                                                 height="16"
                                                 alt="">
                                        </div>
                                    </div>
                                </div>
                            </a> -->
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

<!--
    <div v-if="product.length">
        &lt;!&ndash; <div class="flex-container padding20 margint20 bottom">
            <div class="fs-17 text-darker text-bolder" v-text="recommendProductTitle.title"></div>
        </div> &ndash;&gt;
      <div class="flex-container left center lh-1 paddingh20 paddingv30" v-if="secKillLineTitle.title">
        <img src="//image1.51tiangou.com/tgou2/img2/index/ico-index-xiangmai.png" width="20" alt="">
        <span class="marginl10">想买榜</span>
        <span class="fs-12 text-light marginl10">猜你喜欢</span>
      </div>
      <cmui-list :col="2" :space="20" class="marginh20 margint20 clearfix">
        <cmui-list-item v-for="(item,index) in product">
          <recommended :data="item" :index="index"></recommended>
        </cmui-list-item>
      </cmui-list>
      &lt;!&ndash; <recommended :data="item" :index="index" v-for="(item,index) in product" class="marginb20 paddingb20 paddingh20 borderb"></recommended> &ndash;&gt;
      &lt;!&ndash; <recommended :data="product" class="marginb20 paddingb20 paddingh20 borderb"></recommended> &ndash;&gt;
    </div>
-->
    <modal-poster :data="modalPoster.item[0]"></modal-poster>
    <!-- 新人弹窗 -->
    <newer-pop ref="newerPop"></newer-pop>
    <!-- 会员续费优先级最高，提示续费不再有其他弹框 -->
    <popup-poster v-if="showPopupPoster && !showRenewNotice" :data="popupPoster" @close-popup-poster="closePopupPoster"></popup-poster>
    <bottom-notice v-if="showNotice && !showRenewNotice" :data="noticeList.items"></bottom-notice>
    <renew-notice v-if="showRenewNotice" :data="renewNoticeData" @close-popup-poster="closePopupPoster"></renew-notice>
    <tg-loading></tg-loading>

    <!-- 吸粉计划 -->
<!--
    <follow-fans :data="subscribe"></follow-fans>
     <div v-if="subscribe.clickUrl" v-show="powderShow" class="powderShow pos-f left20 right20 bottom50 bg-white paddingh30 paddingv20">
      <a :href="subscribe.clickUrl">
        <div class="flex-container">
          <div class="flex-container left">
            <img src="//image1.51tiangou.com/tgou2/img2/index/img-powder_01.png" width="38" alt="">
            <div class="fs-14 marginl20">
              <div>成为大商天狗粉丝</div>
              <div class="fs-11">
                马上领取新人<span class="text-red">专享礼包</span>
              </div>
            </div>
          </div>
          <div class="badge big red pill pos-r" style="padding: 6.5px 16px;font-size: 11px; margin-right:40px;margin-bottom:10px;">
            关注
            <img src="//image1.51tiangou.com/tgou2/img2/index/img-powder_02.png" style="right:-20px;" class="pos-a" width="32" alt="">
          </div>
        </div>
      </a>
      <div class="pos-a top0 right0 padding10" @click="powderShow = false">
        <i class="icon-roundclosefill text-light fs-20"></i>
      </div>
    </div>
-->

  <!-- </cmui-popup> -->
    <!--超级大促底部导航-->
	<div v-if="footer" class="fixed-bottom foot-nav-cu" style="z-index: 2;background-size: 621px 55px;background-position: center" :style="{backgroundImage:'url('+footer.imageUrl+')'}">
		<div class="flex-container">
			<div class="flex1 text-center"
				 v-for="(item,$index) in footer.items"
				 :style="{background:$index===0?'url('+item.activeImageUrl+') center no-repeat':'',backgroundSize:'contain'}">
				<a :href="$index===0?'###':item.clickUrl"
                   :data-scp="'nav'+footscp.substr($index,1)+'.00'">
					<img :src="item.imageUrl" alt="" style="height:35px;margin:0 auto">
					<p class="fs-10 text-white">{{item.title}}</p>
				</a>
			</div>
		</div>
	</div>
	<tg-foot v-else></tg-foot>
	<!--红包雨-->
	<rain-red-packet v-if="isRain" page="index" :rulesMes="rulesMes" :redPacketId="redPacketId" :showIcon="showIcon"></rain-red-packet>
</div>
</template>

<script>
import recommended  from 'spa/component/product/recommended.vue';
import popularity   from 'spa/component/product/popularity.vue';
import timeLimit    from 'spa/component/product/timeLimit.vue';
import entranceIcon from 'spa/component/common/entranceIcon.vue';
import popupPoster  from './com/popupPoster.vue';
import modalPoster  from './com/modalPoster.vue';
import bottomNotice from './com/bottomNotice.vue';
import renewNotice  from './com/renewNotice.vue';
import followFans   from 'spa/component/common/followFans.vue';
import request      from 'spa/lib/http.js';
import baseService  from 'spa/lib/api/base.js';
import couponService  from 'spa/lib/api/coupon.js';
import searchwebService from 'spa/lib/api/searchweb.js';

import maibisheng from 'spa/component/product/maibisheng.vue';
import coupon     from 'spa/component/common/coupon.vue';
import stationMail     from 'spa/component/common/stationMail.vue';
import rainRedPacket     from 'spa/component/common/rainRedPacket.vue';
import bigEventAdv     from 'spa/component/common/bigEventAdv.vue';
import newerPop     from 'spa/component/common/newerPop.vue';

import domain from 'javascript/libs/tg/domain.js';
import mixin  from 'spa/component/product/mixin.js';
import tgs from "javascript/libs/tg/analyse";
import timeUtil from "../../../javascript/libs/tg/util/time";

let shakeHandle
	, isWeixin = maple.device.weixin
	, protocol = location.protocol
	;

// 加载数据
//  |
// 新手引导
//  |
// 新人礼 || 弹窗广告
//  |
// 底部用户购买提示

export default {
    name: 'index'
	, config: {
		page: '14.sp'
		, needWxautoLogin: false
	}
	, components:{
	    recommended
	    , popularity
	    , entranceIcon
	    , timeLimit
	    , bottomNotice
	    , popupPoster
	    , modalPoster
	    , maibisheng
	    , followFans
        , renewNotice
		, coupon
        , stationMail
        , rainRedPacket
		, bigEventAdv
		, newerPop
    }
	, mixins: [mixin]
	, data(){
        return {
            searchBar: {
            }
            , hasStep: false    // 是否有新手引导
            , stepExec: null    // 新手引导是否执行完成
            , fetchData: null     // 获取数据
            , showNotice: false
            , showRenewNotice: false
            , renewNoticeData: null
            , showPopupPoster: false
            , popupPoster: null
            , hasPopupHttpPoster: maple.cookie('popupHttpPoster') === '1'   // 是否显示过新人礼
            , popupHttpPoster: null // 新人礼
            , indexPop: maple.cookie('indexPop')
            , hasNewerPop: maple.cookie('hasNewerPop')
            // , powderShow: true  //吸粉计划
            , noticeList: {
                items: []
            }
            , modalPoster: {
                item: []
            }
            , adPoster:[]
            , adPoster_cu: {
                items: []
            }
            , superBlock: {
                items: []
            }
            , nav2: {
                items: []
            }
            , navigation: {
                items: []
            }
            , secKillLineTitle: {
                title: ''
            }
            , secKillEntry: []
            , hotItemsLineTitle: {
                title: ''
                , subTitle: ''
            }
            , hotItems:{
                items:[]
            }
            , recommendProductTitle: {
                title: ''
            }
            , product:[]
            , classifyProduct:[]
            , subscribe: []
            , slider_cu: {
                items: [
                    // {
                    //     imageUrl: "//img1.tg-img.com/seller/201902/25/534ED264-1269-4EBF-8D45-D6B8AB7F926F.jpg!y"
                    // },
                    // {
                    //     imageUrl: "//img1.tg-img.com/seller/201903/08/A1E72921-CD21-4E4D-9611-0E5AF4447F09.jpg!y"
                    // },
                    // {
                    //     imageUrl: "//img1.tg-img.com/seller/201902/25/534ED264-1269-4EBF-8D45-D6B8AB7F926F.jpg!y"
                    // },
                    // {
                    //     imageUrl: "//img1.tg-img.com/seller/201903/08/A1E72921-CD21-4E4D-9611-0E5AF4447F09.jpg!y"
                    // }
                ]
            }
            // themeD:2,     // 1:默认   2:大促   3:超级大促
            , themeBg: ''   // 大促或超级大促头部背景色
            , themeBg2: '#fff000'   // 大促底部背景色

			, footer: null
            , footscp:'HCSEM'
            , discoveryList: {
                items: []
            }

	        , isWeixin
	        , webcast: {
		        userName: domain.webcast.userName
		        , list: `${domain.webcast.path}.html`
		        , room: `${domain.webcast.room}.html`
	        }
            , barCode: {
                userName: domain.webcast.userName
                , path: domain.webcast.barCode +'.html'
            }
	        , protocol

	        , saleProduct: []   // 每日秒杀 买必省

	        , brandList: [] // 品牌旗舰店
	        , showBrandList: [] // 显示品牌旗舰店
	        , currentBrandIndex: 0
	        , dsTopOut: {}  // 大商优选
	        // , liveList: []  // 直播逛店
	        , itemHot: {
            	clickUrl: '/overseas/index.html'
            	, list: []
	        }   // 全球好货
	        , itemHotList: []
	        , currentItemHotIndex: 0
	        , isVip: null // 用户vip标识
	        , isLogin: null // 用户登录标识
	        , couponList: null // 优惠券集合
	        , vipCouponProductList: null // 优惠商品集合
	        , vipProductList: null // 会员展示商品集合
	        , memberAdvImage: null // 会员广告位设置
	        , openScp: "" // 用户开卡埋点
	        , isMemberDay: false // 是否会员日
	        , isShowCard: true // 增加显示标识  防止开卡接口都挂掉  隐藏开卡显示

	        , bigEvent: null
	        , bigEventMore: null
	        , slider: null
	        , sliderOptions: {
		        autoplay: {
					delay: 2000
		        }
		        , direction: 'vertical'
	        }
	        , brandBar: null
	        , licenseBar: null
	        , maibisheng: null
	        , bizBar: null
	        , waterfallSlider: {
				items: []
	        },
            halfOneGrid: []
	        , waterfallSizi: {
				items: []
	        }
	        , waterfallAd: {
				items: []
	        }
			, liveList: {
				items: []
	        }
			, waterfallSpanList: {}
	        , waterfallSpanWidth: false
	        , waterfallClassifySpanWidth: false

	        , endList: ''
            // 页面背景色
            , backColor: ''
            , headBgImg: ''
            , advLimPoster: null
            , advLowPoster: null
            // 横滑小标签
            , twelveTag: {
                items: []
            },
			// 是否展示结算码引导
			idCodeGuideInfoIndex: false,
			// 是否展示红包雨
			isRain: false,
			// 红包雨活动规则
			rulesMes: '',
			// 红包雨id
			redPacketId: '',
			showIcon: '',
			// 大型活动入口广告位
			bigEvenEntryImg: '',
			bigEvenEntryType: null,
            // 运营分类
            curClassify: -1,
            oldScrollY: 0,
            goDown: false,
            hotClasifyOne: [],
            hotClasifyTwo: [],
            scrollEndBaoguang: _.debounce(()=>{
                tg.updateTraceProductList();
            }, 100, {
                leading: true
            }),
            tabTop: 92,
            sliderCopy: null,
            waterfallSliderCopy: [],
            productPanelHeight: undefined,
            // 当前频道页商品排序方式
            curSort: 1,
            filterBrand: [],
            openBrandPanel: false,
            chooseFilterBrand: [],
            // 记录筛选选中的顺序
            chooseFilterBrandName: [],
            currentDataUrl: '',
            newerList: [],
            // 每日秒杀倒计时
            msEndTime: 0
        };
    }
	, computed: {
        themeD(){
            if (this.superBlock.items) {
                if( this.superBlock.items.length ){
                    return 3;
                }
                else if( this.adPoster_cu.items.length || this.slider_cu.items.length ){
                    return 2;
                }
            }

            return 1;
        },
		productList(){
			let l = this.product.length;
			if (l < 76 && this.endList) {
				let temp = new Array(76 - l);
				temp.fill(null);
				return this.product.concat(temp);
			}

			return this.product;
		},
        classifyProductList(){
            let l = this.classifyProduct.length;
            if (l < 76 && this.endList) {
                let temp = new Array(76 - l);
                temp.fill(null);
                return this.classifyProduct.concat(temp);
            }
            return this.classifyProduct;
        },
        // 头部背景
        headStyle() {
            // 新人
            if (this.newerList && this.newerList[0] && this.newerList[0].items && this.newerList[0].items.length) {
                if (this.newerList[0].imageUrl && this.newerList[0].imageUrl.length) {
                    return `background: url(${this.newerList[0].imageUrl[0]});background-size: 100% 100%;`;
                } else {
                    return `background: #FF2238`;
                }
            }
            // 头部背景图
            if (this.headBgImg) {
                return `background: url(${this.headBgImg.imageUrl}) no-repeat;background-size: 100% 100%;`;
            }
            // 超级大促背景色
            if ((this.themeD === 2 || this.themeD === 3 ) && this.themeBg) {
                return `background:${this.themeBg}`;
            }
            // 页面底色
            if (this.backColor) {
                return `background:${this.backColor}`;
            }
            return 'background:#fff';
        },
        chooseBrandName() {
            if (this.chooseFilterBrandName.length) {
                return this.chooseFilterBrandName[0].slice(0, 4);
            } else {
                return '筛选';
            }
        },
        priceSort() {
            let value = '';
            if (this.curSort === 2) {
                value += 'active text-red';
            }
            if (this.currentDataUrl) {
                let params = maple.url(this.currentDataUrl).params;
                if (params.orderColumn === 'price' && params.orderType === 'desc') {
                    value += ' down';
                } else if (params.orderColumn === 'price' && params.orderType === 'asc') {
                    value += ' up';
                }
            }
            return value;
        },
        barCodeEntry(){
            if ( (this.themeD === 3 && this.themeBg) || this.backColor || this.headBgImg || this.newerList[0] ) {
                return `
<wx-open-launch-weapp username="${this.barCode.userName}"
                      path="${this.barCode.path}">
    <script type="text/wxtag-template">
        <img style="margin-top: 10px" src="${this.protocol}//image1.51tiangou.com/tgou2/img2/index/icon-idcode-2.png" width="30" height="36" alt="">
    <\/script>
</wx-open-launch-weapp>`;
            } else {
                return `
<wx-open-launch-weapp username="${this.barCode.userName}"
                      path="${this.barCode.path}">
    <script type="text/wxtag-template">
        <img style="margin-top: 10px" src="${this.protocol}//image1.51tiangou.com/tgou2/img2/index/icon-idcode.png" width="30" height="36" alt="">
    <\/script>
</wx-open-launch-weapp>`;
            }
        },
        // 分类频道栏样式
        classifyStyle() {
            let sty = `line-height: 40px;width: 100%;top:${this.tabTop}px;`;
            // 新人
            if (this.newerList && this.newerList[0] && this.newerList[0].items && this.newerList[0].items.length) {
                if (this.newerList[0].imageUrl && this.newerList[0].imageUrl.length) {
                    sty += `background: url(${this.newerList[0].imageUrl[1]});background-size: 100% 100%;`;
                } else {
                    sty += `background: #FF2238`;
                }
            } else if (this.classifyTabs && this.classifyTabs.imageUrl) {
                sty += `background: url(${this.classifyTabs.imageUrl});background-size: 100% 100%;`;
            } else if (this.backColor) {
                sty += ` background:${this.backColor}`;
            } else {
                sty += ` background: #fff`;
            }
            return sty;
        },
        // 新人模块背景
        newerStyle() {
            if (this.newerList && this.newerList[0].imageUrl && this.newerList[0].imageUrl.length) {
                return `background-image: url(${this.newerList[0].imageUrl[2]});`;
            } else {
                return `background-image: url("//image1.51tiangou.com/tgou2/img2/index/bg-newer-module-2.png");`;
            }
        }
    }
    , created(){
        let { urlParams: { storeId } } = tg.getParams()
            ;

        storeId && baseService.queryStoreInfoById({
	        data: {
	        	storeId
	        }
        }).then((data)=>{
            if( data.store ){
                tg.setCookie('storeId', storeId);
            }
        });

        this.fetchData = tg.autoMidway({
            // url: '/shopping/home/init.node'
            url: '/index/index.node'
	        , data: {
                storeId: maple.cookie('storeId')
		        , cityId: maple.cookie('cityId')
		        , lat: maple.cookie('lat')
		        , lon: maple.cookie('lon')
            }
	        , listCom: ['product', 'secKillEntry', 'adPoster', 'newerList', 'halfOneGrid']
	        , format: (data)=>{
				if( 'bizBar' in data ){
					let arr = data.bizBar.items.map((item)=>{
							let a = item.subTitleList
								;

							item.subTitleList = a.slice(0, 1);

							return a;
						})
						;

					setTimeout(()=>{
						this.bizBar.items.forEach((item, index)=>{
							item.subTitleList = arr[index];
						});
					}, 4000);
				}

                // 配置底色+反白
                if ('pageBgColor' in data) {
                    this.backColor = data.pageBgColor.color;
                }

                // 去掉底部导航的海外购，同时兼容超级大促
                // if ('footer' in data && data.footer.items.length > 4) {
                //     data.footer.items.splice(1, 1);
                // }

                if ('maibisheng' in data) {
                    if (data.maibisheng.items) {
                        // 拼出一个要在每日秒杀落地页展示的数据，因为这个接口使用了个推数据，每次调用时候可能返回的数据不同，需要保持首页外露和落地页前四个品一样
                        let dataList = [];
                        data.maibisheng.items.forEach((item) => {
                            dataList.push({
                                clickUrl: `${domain.baseService}/product/listing.html?id=${item.itemId}`,
                                couponList: item.couponList,
                                imageUrl: item.imageUrl,
                                itemId: item.itemId,
                                newProduct: item.newProduct,
                                originalPrice: item.originalPrice,
                                price: item.price,
                                promotion: item.promotion,
                                soldQty: item.soldQty,
                                stock: item.stock,
                                title: item.totalName,
                                vipPrice: item.vipPrice
                            })
                        })
                        localStorage.setItem('maibishengItems', JSON.stringify(dataList));
                    }
                }

				if ('product' in data) {
					data.product.forEach((item) => {
                        if (item.couponList) {
                            // 商品瀑布流不展示秒杀、拼团、新人价优惠标签
                            if (item.couponList.indexOf('拼团') !== -1) {
                                item.couponList.splice(item.couponList.indexOf('拼团'), 1)
                                item.isPin = true;
                            }
                            if (item.couponList.indexOf('秒杀') !== -1) {
                                item.couponList.splice(item.couponList.indexOf('秒杀'), 1)
                                item.isSecKill = true;
                            }
                            for(let i = 0; i < item.couponList.length; i++) {
                                if (item.couponList[i].indexOf('新人价') !== -1) {
                                    item.couponList.splice(item.couponList[i].indexOf('新人价'), 1);
                                    break;
                                }
                            }
                        }
						// 购买数展示
						if (item.soldQty) {
							if (item.soldQty < 30) {
								item.soldQty = 0;
							} else if (item.soldQty > 10000) {
								item.soldQty = Math.floor(item.soldQty/10000);
								item.soldQty = String(item.soldQty) + '万+';
							}
						}
					})
				}

				// 不展示开屏弹窗时
				if (!this.showPopupPoster) {
					if (this.bigEventEntryH) {
						// 异形
						this.bigEvenEntryImg = this.bigEventEntryH.imageUrl;
						this.bigEvenEntryType = 1;
					} else if (this.bigEventEntryW) {
						// 矩形
						this.bigEvenEntryImg = this.bigEventEntryW.imageUrl;
						this.bigEvenEntryType = 2;
					}
				}
				if (data.emptyList || data.product) {
					this.$nextTick(() => {
						if (!this.bigEvenEntryType && !this.showPopupPoster) {
							this.$refs.refStationMail.requestMail();
						}
					})
				}

				return data;
	        }
        }, this).then(()=>{
            this.navigation.items.forEach((item)=>{
                if( /storeNormal/.test(item.clickUrl) ){
                    item.clickUrl += '?entryType=2';
                }
            });
            // 弹窗广告
            this.showStep();
        });

	    // request({
		//     url: domain.baseMidService +'/shopping/page/sale/list.node'
		//     , method: 'get'
	    // }).then((data)=>{
		//     if( data ){
		// 	    let tmpArr = []
		// 	        ;
		//
		// 	    data.forEach((item)=>{
		// 		    (item.name === 'product') && tmpArr.push( item.data );
		// 	    });
		// 	    this.saleProduct = tmpArr;
		//     }
	    // });

	    // coupon.autotrophy().then((data)=>{
	    // 	data.forEach((d)=>{
	    // 		d.imageUrl = domain.getImageUrl( d.brandAdvertImgUrl );
		//     })
	    //
	    // 	this.brandList = data;
	    // 	this.showBrandList = this.brandList.slice(0, 3);
	    // 	this.currentBrandIndex = 3;
	    // 	this.changeBrand();
	    // });
	    // coupon.advModule({
		//     data: {
		// 	    pageType: 82
		// 	    , advModuleId: 435
		//     }
	    // }).then((data)=>{
	    // 	try{
		// 	    data[0].positionList[0].imageUrl = domain.getImageUrl( data[0].positionList[0].imageUrl )
	    //
		// 	    this.dsTopOut = data[0].positionList[0];
		//     }
		//     catch(e){}
	    // });
	    // coupon.itemHot({
		//     data: {
		// 	    advertiseId: 5465
		// 	    , state: 3
		//     }
	    // }).then((data)=>{
	    // 	if( !data || !data.processingItemList || data.processingItemList.length < 2 ){
	    // 		return ;
		//     }
	    //
	    // 	this.itemHot.list = data.processingItemList.map((item)=>{
		// 	    item.imageUrl = domain.getImageUrl( item.imgUrl );
	    //
		// 	    return item;
		//     }).slice(0, 2);
	    // 	this.itemHotList = data.processingItemList.slice(0, 10);
	    // 	this.currentItemHotIndex = 2;
	    // 	this.changeItemHot();
	    //
	    // 	// coupon.advModule({
		// 	//     data: {
		// 	//     	pageType: 92
		// 	// 	    , advModuleId: 424
		// 	//     }
		//     // }).then((data)=>{
		//     // 	try{
		// 	// 	    this.itemHot.clickUrl = data[0].positionList[1].clickUrl;
		// 	//     }
		// 	//     catch(e){}
		//     // });
	    // });
	    // live.list({
		//     data: {
		//     	page: 1
		// 	    , rows: 20
		//     }
	    // }).then((data)=>{
	    // 	data.forEach((d)=>{
		// 	    d.imageUrl = domain.getImageUrl( d.bgImg );
	    // 		d.clickUrl = '/webcast.html#/publicity';
		//     });
	    //
	    // 	this.liveList = data;
	    // });

		/**
		 * 2021-11-12 web端引掉摇一摇结算码功能
		 * #51146
		 * */
        // // 摇一摇显示结算码
        // shakeHandle = maple.shake(()=>{
        //     _tgs.trackSCP(tg.page + '.uscode.01');
        //     tg.changePage("./mine/idCode.html",{scp:tg.page+'.uscode.0'});
        // });

        // // 获取用户登录及vip信息
        // this.isLogin = !!(tg.getCookie("isLogin") && tg.getCookie("isLogin") === "true");
        // this.isVip = tg.getCookie("vip");
		//
		// if( !this.isLogin || this.isVip == 0 ){  // 取非会员优惠券及商品
		// 	couponService.vipCouponProduct().then((response)=>{
		// 		if( response ){
		// 			this.couponList = response.couponList;
		// 			this.vipCouponProductList = response.vipProductList;
		// 			this.vipCouponProductList.forEach((value)=>{
		// 				value.imageUrl = domain.getImageUrl( value.imageUrl );
		// 			});
		//
		// 			// 防止接口挂掉无数据返回  隐藏区域
		// 			if( (!this.couponList || this.couponList.length === 0)
		// 				&& (!this.vipCouponProductList || this.vipCouponProductList.length === 0) ){
		// 				this.isShowCard = false;
		// 			}
		// 		}
		// 	});
		// 	// 首页埋点无会员或未登录
		// 	this.openScp = 'open.2';
		// }
		// else{ // 取会员商品
		// 	Promise.all([
		// 		couponService.vipDayProduct({   // 取每日会员商品
		// 			data: {
		// 				startNum: 0
		// 				, pageCount: 10
		// 			}
		// 		})
		// 		, couponService.dailySpeciallySeckill({ // 取秒杀商品
		// 			data: {
		// 				startNum: 0
		// 				, pageCount: 10
		// 				, advertiseId: this.getAdvertiseId()
		// 			}
		// 		})
		// 		, couponService.advModule({ // 取会员广告位
		// 			data: {
		// 				pageType: 82
		// 				, advModuleId: 459
		// 			}
		// 		})
		// 	]).then(([contentVipDayProduct, activityVipSecKill, memberAdv])=>{
		// 		if( contentVipDayProduct && contentVipDayProduct.length > 0 ){  // 会员日
		// 			// 商品列表
		// 			this.vipProductList = contentVipDayProduct;
		// 			// 埋点
		// 			this.openScp = 'open.0';
		//
		// 			// 背景底图
		// 			try{
		// 				this.memberAdvImage = domain.getImageUrl( memberAdv[0].positionList[0].imageUrl );
		// 			}
		// 			catch(e){}
		//
		// 			this.isMemberDay = true;
		// 		}
		// 		else{   // 非会员日
		// 			// 商品列表
		// 			this.vipProductList = activityVipSecKill;
		// 			// 埋点
		// 			this.openScp = 'open.1';
		// 			// 背景底图
		// 			this.memberAdvImage = '';
		// 			this.isMemberDay = false;
		// 		}
		//
		// 		this.vipProductList.forEach((value)=>{
		// 			value.imageUrl = domain.getImageUrl( value.imageUrl );
		//
		// 			// 会员特卖接口 没有提供 7 言商品名称 统一数据格式
		// 			if( !value.shortName ){
		// 				value.shortName = value.productName;
		// 			}
		// 		});
		//
		// 		// 防止接口挂掉无数据返回  隐藏区域
		// 		if( !this.vipProductList || this.vipProductList.length == 0 ){
		// 			this.isShowCard = false;
		// 		}
		// 	});
		// }
		//
		// couponService.getRenewInfo().then((data)=>{
		// 	if( !!data ){
		// 		this.renewNoticeData = data;
		// 	}
		// 	else{
		// 		return ;
		// 	}
		//
		// 	// 前三周一周弹一次续费提醒，根据倒数判断当前时间所在是第几周，并设置标记记录已提示
		// 	let expiredDayNum = this.expiredDays( this.renewNoticeData.endTime )
		// 		, expiredAlertFlag = localStorage.getItem('expiredAlertFlag')
		// 		;
		//
		// 	if( this.isRangeIn(expiredDayNum, 30, 23) ){
		// 		this.showRenewNotice = expiredAlertFlag !== '4w';
		// 		localStorage.setItem('expiredAlertFlag', '4w');
		// 	}
		// 	else if( this.isRangeIn(expiredDayNum, 23, 16) ){
		// 		this.showRenewNotice = expiredAlertFlag !== '3w';
		// 		localStorage.setItem('expiredAlertFlag', '3w');
		// 	}
		// 	else if( this.isRangeIn(expiredDayNum, 16, 9) ){
		// 		this.showRenewNotice = expiredAlertFlag !== '2w';
		// 		localStorage.setItem('expiredAlertFlag', '2w');
		// 	}
		// 	else if( expiredDayNum === 3 ){
		// 		this.showRenewNotice = expiredAlertFlag !== '3d';
		// 		localStorage.setItem('expiredAlertFlag', '3d');
		// 	}
		// 	else if( expiredDayNum === 2 ){
		// 		this.showRenewNotice = expiredAlertFlag !== '2d';
		// 		localStorage.setItem('expiredAlertFlag', '2d');
		// 	}
		// 	else if( expiredDayNum === 1 ){
		// 		this.showRenewNotice = expiredAlertFlag !== '1d';
		// 		localStorage.setItem('expiredAlertFlag', '1d');
		// 	}
		// 	else{
		// 		// 有不再提醒标识
		// 		let expiredAlertAnyMore = localStorage.getItem('TG-Renew-Alert')
		// 			;
		//
		// 		if( expiredAlertAnyMore !== 'true' ){
		// 			let lastAlertDate = new Date( parseInt(expiredAlertFlag) ).getTime()
		// 				;
		//
		// 			if( !!lastAlertDate ){
		// 				if( this.expiredDays(lastAlertDate) <= -7 ){
		// 					this.showRenewNotice = true;
		// 					localStorage.setItem('expiredAlertFlag', new Date().getTime());
		// 				}
		// 			}
		// 			else{
		// 				this.showRenewNotice = true;
		// 				localStorage.setItem('expiredAlertFlag', new Date().getTime());
		// 			}
		// 		}
		// 	}
		// });

		// 结算码引导文案
		let times = localStorage.getItem('idCodeGuideInfoIndex') || 0;
		if (times < 3) {
			this.idCodeGuideInfoIndex = true;
			localStorage.setItem('idCodeGuideInfoIndex', ++times);
			// 文案8秒后消失
			setTimeout(() => {
				this.idCodeGuideInfoIndex = false;
			}, 8000)
		}

		// 根据日期、页面、门店id（逛店页）判断红包雨是否展示
		let redArr = JSON.parse(localStorage.getItem('redPacketA')) || [];
		const today = timeUtil.formatDate(new Date(), 'YYYY-MM-DD');
		let redObj = redArr.filter((item) => {
			return item.page === 'index' && item.date === today;
		})[0];
		if (!redObj) {
			// 查询红包雨
			couponService.getRedPacketActivity({
				data: {
					homeType: 1
				}
			}).then((data)=>{
				if (data) {
					this.isRain = true;
					this.rulesMes = data.description
					this.redPacketId = data.id;
					this.showIcon = data.showIcon;
					$('body').css("overflow","hidden");
				}
			});
		}

        // 监听页面滚动，展示运营分类广告位
        window.addEventListener("scroll", this.scrollFun)
        // 每日秒杀倒计时
        this.msEndTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1).getTime();
    }
    , methods: {
		// 计算会员的过期天数
		expiredDays(endTime){
			return Math.ceil((new Date(endTime) - new Date()) / (24 * 60 * 60 * 1000));
		}
        // 判断参数区间 m 最小值 n 最大值
		, isRangeIn(num, max, min){
			return num <= max && num > min;
		}
		, changePage(url, scp){
            tg.changePage(url,{
	            scp: `${tg.page}.${scp}.${Math.random().toString(36).slice(2, 7)}`
            });
        },
        // 需要单独埋点的点击位
        changePageTgs(url, scp, bk) {
            tgs.trackSCP(`${tg.page}.${scp}.${Math.random().toString(36).slice(2, 7)}`, bk);
            tg.changePage(url,{
                scp: `${tg.page}.${scp}.${Math.random().toString(36).slice(2, 7)}`
            });
        },
        closePopupPoster(){
            this.showPopupPoster = false;
            this.showRenewNotice = false;
            this.showBottomNotice();    // 显示新手引导
			// 展示  大型活动入口吸底广告位/站内信
			if (this.bigEventEntryH) {
				this.bigEvenEntryImg = this.bigEventEntryH.imageUrl;
				this.bigEvenEntryType = 1;
			} else if (this.bigEventEntryW) {
				this.bigEvenEntryImg = this.bigEventEntryW.imageUrl;
				this.bigEvenEntryType = 2;
			}
			if (!this.bigEvenEntryType) {
				this.$refs.refStationMail.requestMail();
			}
        }
        , showStep(){
            // 没有新手引导
            this.stepExec = Promise.resolve();
        }
        , showBottomNotice(){
            // this.showNotice = true;
        }
        , changeBg(current){
            this.themeBg = this.slider_cu.items[(current-1)%this.slider_cu.items.length].upperBackGroundColor;
            this.themeBg2 = this.slider_cu.items[(current-1)%this.slider_cu.items.length].lowerBackGroundColor;
        }

        , isList(item){
		    return /webcast\.html.*?#\/publicity/.test( item.clickUrl );
	    }
        , isRoom(item){
		    return /webcast\.html.*?#\/room/.test( item.clickUrl );
	    }
	    , roomPath(item){
		    let rs = /webcast\.html.*?#\/room\?roomId=([^&]*)/.exec( item.clickUrl )
			    , roomId = ''
		        ;

		    if( rs ){
			    roomId = rs[1];
		    }
		    
		    return `${this.webcast.room}?roomId=${roomId}`;
	    }

	    , changeBrand(){
        	setTimeout(()=>{
        		let d = this.brandList.slice(this.currentBrandIndex, this.currentBrandIndex +3)
		            ;

        		if( d.length < 3 ){
        			let i = d.length
			            ;

        			d.push.apply(d, this.brandList.slice(0, 3- i));
        			this.currentBrandIndex = 3- i;
		        }
        		else{
        			this.currentBrandIndex = this.currentBrandIndex + 3;
		        }

        		this.showBrandList = d;

		        this.changeBrand();
	        }, 3000);
	    }
	    , changeItemHot(){
        	setTimeout(()=>{
        		let d = this.itemHotList.slice(this.currentItemHotIndex, this.currentItemHotIndex +2)
		            ;

        		if( d.length < 2 ){
			        let i = d.length
			            ;

			        d.push.apply(d, this.itemHotList.slice(0, 2- i));
			        this.currentBrandIndex = 2- i;
		        }
		        else{
			        this.currentItemHotIndex = this.currentItemHotIndex + 2;
		        }

        		this.itemHot.list = d;

        		this.changeItemHot();
	        }, 3000);
	    }
		, getAdvertiseId(){
            let advertiseId
                ;

			if( Object.is(domain.env, 'dev') || Object.is(domain.env, 'test') ){
				advertiseId = 5519;
			}
			else{
				advertiseId = 5483;
			}

            return advertiseId;
        }

		, computeIndex(index){
			return index -3 - this.liveList.items.length;
		},
        // 切换运营分类
        changeClassify(index, item) {
            this.openBrandPanel = false;
            if (this.curClassify === index) return;
            this.curClassify = index;
            this.classifyProduct = [];
            this.productPanelHeight = undefined;
            this.curSort = 1;
            // 滚动到可视区域
            document.getElementsByClassName('js-fltab')[index + 1].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start'
            })
            if (index !== -1) {
                // 首页轮播数据需要重新赋值，否则轮播会不动
                if (this.slider) {
                    this.sliderCopy = JSON.stringify(this.slider)
                    this.slider = null;
                }
                if (this.waterfallSlider.items && this.waterfallSlider.items.length) {
                    this.waterfallSliderCopy = this.waterfallSlider.items.concat();
                    this.waterfallSlider.items = [];
                }
            } else {
                this.slider = JSON.parse(this.sliderCopy)
                this.waterfallSlider.items = this.waterfallSliderCopy.concat();
                return;
            }
            // 清空原数据
            this.classifySlider = null;
            this.hotCategories = null;
            this.classifyOneGrid = null;
            this.classifyTwoGrid = null;
            this.hotBrands = null;
            // 清空曝光数据，除了上面的分类tab
            tg.clearObserve(['fltab', 'flrk']);
            this.currentDataUrl = item.httpUrl;
            this.filterBrand = [];
            this.chooseFilterBrandName = [];
            this.chooseFilterBrand = [];
            this.fetchData = tg.autoMidway({
                listCom:['hotCategories', 'classifyProduct'],
                url: item.httpUrl,
                format: (data)=>{
                    // 处理热门分类
                    if ('hotCategories' in data && data.hotCategories[0].items && data.hotCategories[0].items.length && data.hotCategories[0].items.length < 10
                        && data.hotCategories[0].items[data.hotCategories[0].items.length - 1].title !== '全部') {
                        data.hotCategories[0].items.push({
                            bk: '',
                            clickUrl: `/#/classify?id=${this.classifyTabs.items[index].id}`,
                            imageUrl: '//image1.51tiangou.com/tgou2/img2/index/icon-all-fenlei.png',
                            scpCD: `fenlei.${data.hotCategories[0].items.length}`,
                            title: '全部'
                        })
                    }
                    if ('classifyProduct' in data) {
                        data.classifyProduct.forEach((item) => {
                            // 商品瀑布流不展示秒杀拼团优惠标签
                            if (item.couponList) {
                                if (item.couponList.indexOf('拼团') !== -1) {
                                    item.couponList.splice(item.couponList.indexOf('拼团'), 1)
                                    item.isPin = true;
                                }
                                if (item.couponList.indexOf('秒杀') !== -1) {
                                    item.couponList.splice(item.couponList.indexOf('秒杀'), 1)
                                    item.isSecKill = true;
                                }
                                for(let i = 0; i < item.couponList.length; i++) {
                                    if (item.couponList[i].indexOf('新人价') !== -1) {
                                        item.couponList.splice(item.couponList[i].indexOf('新人价'), 1);
                                        break;
                                    }
                                }
                            }
                            // 购买数展示
                            if (item.soldQty) {
                                if (item.soldQty < 30) {
                                    item.soldQty = 0;
                                } else if (item.soldQty > 10000) {
                                    item.soldQty = Math.floor(item.soldQty/10000);
                                    item.soldQty = String(item.soldQty) + '万+';
                                }
                            }
                        })
                        this.$nextTick(() =>{
                            let dom = document.querySelector('.jsProductPanel');
                            if (dom) {
                                this.productPanelHeight = dom.clientHeight;
                            } else if (!data.classifyProduct.length) {
                                this.productPanelHeight = 0;
                            }
                        })
                    }
                    return data;
                }
            },this);
            // 同步调筛选品牌的接口
            this.requestBrandFliter();
        },
        // 监听全屏滚动，向下滑展示运营分类
        scrollFun() {
            if (!this.classifyTabs || !this.classifyTabs.items || !this.classifyTabs.items.length) return;
            let scrollY = document.documentElement.scrollTop || document.body.scrollTop;
            // 页面向下滚，不超过40
            if (scrollY < 40 && scrollY > this.oldScrollY) {
                this.tabTop = 92 - scrollY;
            }
            // 页面向下滚，消失
            else if (scrollY >= 40 && scrollY > this.oldScrollY) {
                if (this.$refs.refTab.classList.contains('tabShow')) {
                    this.$refs.refTab.classList.remove('tabShow');
                    this.$refs.refTab.classList.value += ' tabHide';
                }
                this.tabTop = 51;
            }
            // 页面向上滚，展示
            else if (scrollY < this.oldScrollY) {
                if (this.$refs.refTab.classList.contains('tabHide') || (!this.$refs.refTab.classList.contains('tabHide') && !this.$refs.refTab.classList.contains('tabShow'))) {
                    this.$refs.refTab.classList.remove('tabHide');
                    this.$refs.refTab.classList.value += ' tabShow';
                    this.tabTop = 92;
                }
            }
            // 滚动到最顶部的时候清空样式
            if (scrollY === 0) {
                this.$refs.refTab.classList.remove('tabShow');
                this.$refs.refTab.classList.remove('tabHide');
            }
            this.oldScrollY = scrollY;
        },
        tabSort(type) {
            if (this.curSort === type && type !== 2) {
                return;
            }
            this.curSort = type;
            let temp = maple.url(this.currentDataUrl || this.classifyTabs.items[this.curClassify].httpUrl);
            let params = temp.params;
            switch (type) {
                case 1:
                    // 销量
                    params.orderColumn = 'monthlySoldQty';
                    params.orderType = 'desc';
                    break;
                case 2:
                    // 价格
                    if (params.orderColumn === 'price') {
                        params.orderType = params.orderType === 'desc' ? 'asc' : 'desc';
                    } else {
                        params.orderColumn = 'price';
                        params.orderType = 'asc';
                    }
                    break;
                case 3:
                    // 综合
                    params.orderColumn = undefined;
                    params.orderType = undefined;
                    break;
                default:
                    break;
            }
            this.requestSortList(temp, params);
        },
        // 请求筛选面板里的品牌
        requestBrandFliter() {
            searchwebService.aggregationPlatformFilter({
                data: {
                    platCategoryCodes: this.classifyTabs.items[this.curClassify].code,
                    pageCount: 80
                }
            }).then((data)=>{
                if ( data.brand ) {
                    data.brand.forEach(item => {
                        item.choose = false;
                        this.filterBrand.push(item)
                    })
                }
            })
        },
        requestSortList(temp, params) {
            // 清空原数据
            this.classifyProduct = [];
            params.currentView = 2;
            temp.query = '?' + Object.keys(params).reduce((all, k) => {
                if (params[k]) {all.push(k + '=' + params[k]);}
                return all;
            }, []).join('&');
            this.currentDataUrl = temp.pack();
            tg.autoMidway({
                listCom:['classifyProduct'],
                url: temp.pack(),
                format: (data)=>{
                    if ('classifyProduct' in data) {
                        data.classifyProduct.forEach((item) => {
                            // 商品瀑布流不展示秒杀拼团优惠标签
                            if (item.couponList) {
                                if (item.couponList.indexOf('拼团') !== -1) {
                                    item.couponList.splice(item.couponList.indexOf('拼团'), 1)
                                    item.isPin = true;
                                }
                                if (item.couponList.indexOf('秒杀') !== -1) {
                                    item.couponList.splice(item.couponList.indexOf('秒杀'), 1)
                                    item.isSecKill = true;
                                }
                                for(let i = 0; i < item.couponList.length; i++) {
                                    if (item.couponList[i].indexOf('新人价') !== -1) {
                                        item.couponList.splice(item.couponList[i].indexOf('新人价'), 1);
                                        break;
                                    }
                                }
                            }
                            // 购买数展示
                            if (item.soldQty) {
                                if (item.soldQty < 30) {
                                    item.soldQty = 0;
                                } else if (item.soldQty > 10000) {
                                    item.soldQty = Math.floor(item.soldQty/10000);
                                    item.soldQty = String(item.soldQty) + '万+';
                                }
                            }
                        })
                        this.$nextTick(() =>{
                            let dom = document.querySelector('.jsProductPanel');
                            if (dom) {
                                this.productPanelHeight = dom.clientHeight;
                            } else if (!data.classifyProduct.length) {
                                this.productPanelHeight = 0;
                            }
                        })
                    }
                    return data;
                }
            },this);
        },
        // 过滤面板点击重置
        resetFilter() {
            this.openBrandPanel = false;
            this.filterBrand.forEach(item => {
                item.choose = false;
            });
            this.chooseFilterBrand = [];
            this.chooseFilterBrandName = [];
            this.curSort = 1;
            let httpUrl = this.classifyTabs.items[this.curClassify].httpUrl;
            let temp = maple.url(httpUrl);
            let params = temp.params;
            params.brandIds = undefined;
            params.orderColumn = 'monthlySoldQty';
            params.orderType = 'desc';
            this.requestSortList(temp, params);
        },
        setFilter() {
            this.openBrandPanel = false;
            let temp = maple.url(this.currentDataUrl);
            let params = temp.params;
            this.chooseFilterBrand = this.filterBrand && this.filterBrand.reduce((all, d) => {
                if (d.choose) {
                    all.push(d.id);
                }
                return all;
            }, [])
            params.brandIds = this.chooseFilterBrand.join();
            let index = this.filterBrand.findIndex(item => {
                return item.choose;
            })
            this.requestSortList(temp, params);
        },
        // 点击筛选里的品牌名
        clickFilterBrand(item) {
            item.choose = !item.choose;
            if (item.choose) {
                this.chooseFilterBrandName.push(item.name)
            } else {
                let index = this.chooseFilterBrandName.indexOf(item.name);
                if (index !== -1) {
                    this.chooseFilterBrandName.splice(index, 1);
                }
            }
        },
        splitPriceInt(discount) {
            let rs = '';
            if( discount ){
                rs = (''+ discount).split('.')[0];
            }
            return rs;
        },
        splitPrice(discount) {
            let rs = '';
            if( discount ){
                rs = (''+ discount).split('.');
                if( rs.length > 1 ){
                    rs = '.'+ rs[1];
                } else{
                    rs = '';
                }
            }
            return rs;
        }
    }
    , watch: {
        superBlock(newValue){
            if( newValue ){
                this.themeBg = newValue.bgColor;
                this.themeBg2 = newValue.bgColor;
            }
        }
        , slider_cu(newValue){
            if( newValue && newValue.items.length ){
                this.themeBg = newValue.items[0].upperBackGroundColor;
                this.themeBg2 = newValue.items[0].lowerBackGroundColor;
            }
        }
        // 弹窗合集
        , stepExec(newValue){
            if( newValue ){
                newValue.then(()=>{
                    if( !this.hasPopupHttpPoster && this.popupHttpPoster ){
                        // 有新人礼并今天没有显示过新人礼则显示新人礼
                        maple.confirm({
                            content: '<div><img src="'+ this.popupHttpPoster +'" alt=""></div>'
                            , theme: 'newSkin'
                            , okText: '立即领取'
                            , okFn: ()=>{
                                request( this.popupHttpPoster.httpUrl ).then(()=>{
                                    tg.changePage( this.popupHttpPoster.clickUrl );
                                });
                            }
                            , cancelFn: ()=>{
                                this.showBottomNotice();    // 显示用户购买提示
                            }
                        });
                        maple.cookie('popupHttpPoster', 1, 1);
                        this.hasPopupHttpPoster = true;
                    }
                    // else if( !this.popupPoster ){   // 没有弹窗广告
                    //     this.showBottomNotice();    // 显示用户购买提示
                    // }
                    else if( this.popupPoster && this.popupPoster.clickUrl !== this.indexPop && (!this.newerList[0] || this.popupPoster.heat) ){
                        // 有弹窗广告且与之前的广告不同且（非新人 或者 弹窗广告优先级>0）
                        this.showPopupPoster = true;
                        tg.setCookie('indexPop', this.popupPoster.clickUrl, '1d');
                    }
                    else if(this.newerList[0] && this.newerList[0].items && this.newerList[0].items.length
                        && !this.indexPop && (!this.popupPoster || !this.popupPoster.heat) && !this.hasNewerPop) {
                        // 有新人品数据且 当天没弹过弹窗广告位 且（弹窗广告位优先级为0 或者 没有弹窗广告位）且一天弹一次
                        this.$refs.newerPop && this.$refs.newerPop.setNewData(this.newerList[0].items);
                        tg.setCookie('hasNewerPop', true, '1d');
                    }
                    else{
                        this.showBottomNotice();
                    }
                });
            }
        }
        // 瀑布流广告位高度
		, waterfallAd(newVal){
			if( newVal ){
				let dom = document.createElement('style');

				dom.innerHTML = newVal.items.reduce((rs, item)=>{
					let { height
						, width } = item
						, w = document.documentElement.clientWidth
						, colWidth = (w - 24 - 0.266666666* (w /10)) /2
						, span = Math.ceil( height / (width / colWidth) )
						;

					if( !(span in this.waterfallSpanList) ){
						this.waterfallSpanList[span] = true;
						rs = `${rs}.waterfall .item.item-span-${span}{grid-row: span ${span};}`
					}
					item.span = span;
					return rs;
				}, '');
				document.head.appendChild( dom );
			}
		}
        // 瀑布流竖版轮播高度
        , waterfallSlider(newVal){
            if( newVal ){
                let dom = document.createElement('style');
                let { height, width } = newVal.items[0],
                    w = document.documentElement.clientWidth,
                    colWidth = (w - 24 - 0.266666666* (w /10)) /2,
                    span = Math.ceil( height / (width / colWidth) );
                if( !(span in this.waterfallSpanList) ){
                    this.waterfallSpanList[span] = true;
                    dom.innerHTML = `.waterfall .item.item-span-${span}{grid-row: span ${span};}`;
                }
                newVal.span = span;
                document.head.appendChild( dom );
            }
        }
        // 瀑布流品牌惠购高度
        , halfOneGrid(newVal){
            if( newVal && newVal.length ){
                newVal.forEach(item => {
                    let dom = document.createElement('style');
                    let { height, width } = item,
                        w = document.documentElement.clientWidth,
                        colWidth = (w - 24 - 0.266666666* (w /10)) /2,
                        span = Math.ceil( height / (width / colWidth) );
                    if( !(span in this.waterfallSpanList) ){
                        this.waterfallSpanList[span] = true;
                        dom.innerHTML = `.waterfall .item.item-span-${span}{grid-row: span ${span};}`;
                    }
                    item.span = span;
                    document.head.appendChild( dom );
                })
            }
        }
		// 商品瀑布流样式
		, product(newVal){
			let width = document.documentElement.clientWidth
				, dom = document.createElement('style')
				, colWidth = (width - 24 - 0.266666666* (width /10)) /2
				, span = Math.ceil( colWidth ) + 65
				, live = Math.ceil( 200 / (170/ colWidth) )
				;

			// if( newVal.length ){
			// 	newVal.forEach((item)=>{
			// 		let title = item.title;
			// 		if( title.length > colWidth / 14 && !/^[a-zA-Z0-9]*$/.test(title) ){
			// 			item.longTitle = true;
			// 		}
			// 	});
			// }

			if( this.waterfallSpanWidth ){
				return ;
			}

			dom.innerHTML = `.waterfall .item{grid-row: span ${span};}
.waterfall .item-long, .waterfall .item-coupon, .waterfall .item-latest {grid-row: span ${span +20};}
.waterfall .item-sortRank {grid-row: span ${span + 30};}
.waterfall .item-long.item-coupon, .waterfall .item-long.item-latest, .waterfall .item-latest.item-coupon {grid-row: span ${span +40};}
.waterfall .item-latest.item-sortRank, .waterfall .item-sortRank.item-coupon, .waterfall .item-sortRank.item-long {grid-row: span ${span + 50};}

.waterfall .item-long.item-coupon.item-latest {grid-row: span ${span + 60};}
.waterfall .item-long.item-coupon.item-sortRank, .waterfall .item-long.item-sortRank.item-latest, .waterfall .item-latest.item-coupon.item-sortRank {grid-row: span ${span + 70};}
.waterfall .item-long.item-coupon.item-latest.item-sortRank {grid-row: span ${span + 90};}
.waterfall .item-live{grid-row: span ${live}`;
			document.head.appendChild( dom );

			this.waterfallSpanWidth = true;
		},
        classifyProduct(newVal){
            let width = document.documentElement.clientWidth
                , dom = document.createElement('style')
                , colWidth = (width - 24 - 0.266666666* (width /10)) /2
                , span = Math.ceil( colWidth ) + 65;

            if( newVal.length ){
                newVal.forEach((item)=>{
                    let title = item.title;

                    if( title.length > colWidth / 14 && !/^[a-zA-Z0-9]*$/.test(title) ){
                        item.longTitle = true;
                    }
                });
            }

            if( this.waterfallClassifySpanWidth ){
                return ;
            }

            dom.innerHTML = `.waterfall .item{grid-row: span ${span};}
    .waterfall .item-long,.waterfall .item-coupon, .waterfall .item-latest{grid-row: span ${span +20};}
    .waterfall .item-long.item-coupon, .waterfall .item-long.item-latest, .waterfall .item-latest.item-coupon{grid-row: span ${span +40};}
    .waterfall .item-long.item-coupon.item-latest{grid-row: span ${span +60};}`
            document.head.appendChild( dom );

            this.waterfallClassifySpanWidth = true;
        },
		bigEvenEntryType(newVal) {
			if (newVal) {
				this.$nextTick(() => {
					this.$refs.refBigEventAdv.setData(this.bigEvenEntryImg, newVal);
				})
			}
		}
    }
    , destroyed(){
        // shakeHandle && shakeHandle.cancel();
        window.removeEventListener("scroll", this.scrollFun)
    }
};
</script>

<style lang="scss" type="text/scss">
//body{
//	filter: grayscale(100%);
//}
.inputTop{
  border-radius: 20px;
  border:1px solid #ff2238;
  height:30px;
  line-height:30px;
  &.searchD{
    //background:rgba(0,0,0,.2);
    //border:1px solid rgba(0,0,0,0);
	  color:#fff;
    //div{
    //  color:#fff;
    //}
  }
}
.borderR8{
  border-radius: 8px;
}
.defaultBg{
    /*background-image: linear-gradient(270deg, #FF4C48 22%, #FF1515 100%);*/
}
.cmui-affix-warp.warpActive .affixActive{
  z-index: 1;
}
/*会员卡开卡样式--start*/
div.open_vip_card {
    border-radius: 8px;
    color: #E9B983;
    background-color: #000;
    // background: url('//image1.51tiangou.com/tgou2/img2/giftCard/open-card-bg.png') no-repeat top center;
    // background-size: contain;
    // height: 194px;

    .title {
        display: flex;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            font-size: 16px;

            img {
                margin-right: 3px;
                width: 30px;
                height: 18px;
            }
        }

        .right {
            span {
                font-size: 12px;
            }
        }
    }

    .content {
        display: flex;
        padding: 0 .4rem .4rem;

        .scroll-container {
            display: flex;
        }

        .item:not(:last-child){
            margin-right: .2666666667rem;
        }

        .item {
            position: relative;
            background-color: #fff;
            border-radius: 10px;

            .img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 90px;
                background: url('//image1.51tiangou.com/tgou2/img2/giftCard/red-package.png') no-repeat top center;
                background-size: 100% 100%;

                span{
                    color: rgb(204, 147, 85);
                    font-size: 28px;

                    span{
                        font-size: 12px;
                    }
                }
            }

            img{
                width: 90px;
                height: 90px;
                border-radius: 10px;
            }

            .foot{
                display: flex;
                flex-direction: column;
                padding-top: .1333333333rem;
                width: 90px;
                text-align: center;
                white-space: pre-wrap;
                word-break: break-word;
                color: #000000;
            }
        }
    }
}
div.vip_card{
    position: relative;
    border-radius: 8px;

    .content {
        display: flex;
        position: absolute;
        padding-left: .4rem;
        bottom: .4rem;
        width: 355px;
        overflow: hidden;

        .item {
            position: relative;
            margin-right: 8px;
            width: 90px;
            border-radius: 10px;
            background-color: #fff;

            img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 90px;
                border-radius: 10px;

                span{
                    font-size: 30px;

                    span{
                        font-size: 14px;
                    }
                }
            }

            .foot{
                display: flex;
                flex-direction: column;
                padding-top: .1333333333rem;
                width: 90px;
                text-align: center;
                white-space: pre-wrap;
                word-break: break-word;
                color: #000;
            }

            &:last-child {
                margin-right: .4rem;
            }
        }
    }
}

div.vip_card, div.open_vip_card {
    .em {
        margin-left: .1rem;
        width: 22px;
        height: 12px;
        background: url('//image1.51tiangou.com/tgou2/img2/product/ico-up_02.png') no-repeat top center;
        background-size: contain;
    }

    .foot_btm {
        display: flex;
        justify-content: center;
    }

    .tow {
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        /*超出n行省略号*/
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .ehn {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
/*会员卡开卡样式--end*/

/*.cmui-affix-warp{
    min-height:85px;
    transition: min-height 0.3s ease;
    &.warpActive{
        min-height:45px;
    }
}
.cmui-affix{
    transition: background 0.3s linear;
    
    a span{
        transition: color 0.3s linear;
    }
    .inputB{
        background: #EEEFF4;
        height:36px;
        line-height:36px;
        right:0;
        left:0;
        top:44px;
        position:absolute;
        transition: background 0.3s linear, top 0.3s linear, right 0.3s linear, height 0.3s linear, line-height 0.3s linear;
        .text-light{
            transition: color 0.3s linear;
        }
    }
    &.affixActive{
        color:#222;
        background:#fff;
        z-index:2;
        height:45px;
        border-bottom:1px solid #ebebeb;
        a span{
            color:#222;
        }
        .inputB{
            background: #EEEFF4;
            top:5px;
            left:0;
            right:80px;
            height:34px;
            line-height:34px;
            .text-light{
                color:#222;
            }
        }
    }

} */
/* .powderShow {
  bottom:60px;
  box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  z-index:100;
} */
.coupon-list {
	position: relative;
	width: 100%;
	border-radius: 8px;
	// background-color: #313542;

	.coupon-description {
		color: #E9B983;
		width: 48px;
        min-width: 48px;
	}

	.coupon-item {
		border-radius: 8px;
		width: 132px;
		height: 48px;
		background-image: url('//image1.51tiangou.com/tgou2/img2/giftCard/coupon-bg.png');
		background-position: top;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		// background-color: #313542;

        &:nth-child(n + 2) {
			margin-left: .13rem;
		}
	}

	.coupon-detail {
		float: left;
		border-radius: 8px;
		// width: 94px;
		height: 100%;
		padding: 12px 6px;
	}

	.useInfo{
        min-width: 40px;
		width: 48px;
		height: 16px;
		border-radius: 0 8px 0 8px;
		background-color: #171A24;
		color: #E9B983;
		font-size: 9px;
        border: #e9b983 1px solid;
	}
}
.coupon{
    background-size: 100% 100%;
    //width: 70px;
    //height: 70px;
    //min-height: 70px;
    //border-radius: 10px;
    overflow: hidden;

    .couponNum{
        border-top-left-radius: 10px;
        background: #F6CB9C;
    }
}
.coupon-desc {
	width: 70px;
}

.price_bg{
	height: 24px;
	//padding-left: 24px;
	//background: url("//image1.51tiangou.com/tgou2/img2/index/price_bg.png") no-repeat top left/auto 24px;
	font-weight: 500;
}

.logoBarSearch{
	display: none;
}
//.warpActive{
//	.logo,
//	.slogan{
//		display: none;
//	}
//	.logoBarSearch{
//		display: flex;
//	}
//}
.brandName{
	width: 100%;
	border-radius: 10px;
	margin-top: 8px;
	background: linear-gradient(90deg, #29288A 0%, #150E36 100%);
	color: #fff;
	font-size: 10px;
	text-align: center;
	line-height: 22px;
}
.recommendName{
	background: linear-gradient( 91deg, #FF54B0 0%, #FF6A49 100%);
}
.waterfall{
	&.row:before{
		content: none;
	}

	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: repeat(auto-fill, 1px);
	grid-auto-flow: row dense;

	.item{
		margin-bottom: 8px;
		grid-column: span 1;
		grid-row: span 255;

		justify-self: center;
	}
	.item-long,
	.item-coupon{
		grid-row: span 275;
	}
	.item-long.item-coupon{
		grid-row: span 295;
	}
	.item-ad,
	.item-live{
		grid-row: span 300;
	}
    .item-slider {
        grid-row: span 300;
    }
    .item-topAdv {
        grid-row: span 200;
    }

	.box{
		overflow: hidden;
		border-radius: 8px;
        z-index: 1;
        position: relative;
		background: white;
	}
}
/* 品牌旗舰店 */
.badegBackground-1{
	background-image: linear-gradient(134deg, #3023AE 0%, #C86DD7 100%);
}

/* 自营海外购 */
.badegBackground-2{
	background: #FF2238 !important;
}

/* 商场专柜 商场门店 */
.badegBackground-3{
	background-image: linear-gradient(135deg, #FFA600 0%, #FF6D00 100%);
}
/* 超市优选 */
.badegBackground-4{
	background-image: linear-gradient(133deg, #16CC90 0%, #007C2F 100%);
}
/* 自营旗舰店 */
.badegBackground-5{
	background-image: linear-gradient(134deg, #004ACB 0%, #32C5FF 100%);
}

.swiper-pagination-bullet{
	width: 4px;
	height: 4px;
}
.swiper-pagination-bullet-active{
	background: #fff;
}

.brandBar-container {
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 0 4px 12px;

    .brandBar-item {
        margin-top: 12px;
        flex-shrink: 0;
        width: 20%;
    }
}
//运营分类
.hot-classify {
    margin-bottom: 8px;
    padding: 12px 0;
    width: 100%;
    border-radius: 8px;
}
.hot-classify .scroll-bar-container {
    margin: 0 auto;
    width: 36px;
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 4px;
}
.hot-classify .scroll-bar-container .scroll-bar {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-color: #ff2238;
    border-radius: 4px;
}
.hot-brands {
    margin: 0 4px 8px;
    width: calc(25% - 8px);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
}
.hot-brands div {
    bottom: -10px;
    width: 100%;
    height: 40px;
    line-height: 32px;
    background: url("//image1.51tiangou.com/tgou2/img2/index/bg-hot-brand.png") no-repeat;
    background-size: cover;
}
.tabHide {
    animation: tabGoHide 200ms;
    z-index: 2;
}
.tabShow {
    animation: tabGoShow 200ms;
    z-index: 2;
}
@keyframes tabGoHide{
    0%{ top: 92px; }
    100%{  top: 51px; }
}
@keyframes tabGoShow{
    0%{ top: 51px;  }
    100%{  top: 92px; }
}
.sort-list {
    padding: 0 12px;
    line-height: 28px;
}
.sort-list li {
    width: calc(25% - 1px);
    text-align: center;
    white-space: nowrap;
}
.sort-list .active {
    border-radius: 28px;
    background-color: #fff4f5;
}
.sort-list .open:after {
    background: #ff2238;
    transform: rotate(180deg);
    right: 28px;
}
.price-sort:before {
    content: '';
    height: 12px;
    width: 12px;
    position: absolute;
    background: #666;
    -webkit-clip-path: polygon(0 5px,6px 5px,3px 2px);
    clip-path: polygon(0 5px,6px 5px,3px 2px);
    top: 8px;
    right: 10px
}
.price-sort:after {
    content: '';
    height: 12px;
    width: 12px;
    position: absolute;
    background: #666;
    -webkit-clip-path: polygon(0 5px,6px 5px,3px 8px);
    clip-path: polygon(0 5px,6px 5px,3px 8px);
    top: 10px;
    right: 10px;
    left: auto;
    margin-left: 0;
    border: 0;
}
.price-sort.active.up:before {
    background: #ff2238;
}
.price-sort.active.down:after {
    background: #ff2238;
}
.bg-change-color {
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, #fff 0%, rgba(255,255,255,0) 100%);
}
.brand-screen:after {
    content: '';
    height: 12px;
    width: 12px;
    position: absolute;
    background: #666;
    -webkit-clip-path: polygon(0 5px,6px 5px,3px 8px);
    clip-path: polygon(0 5px,6px 5px,3px 8px);
    top: 9px;
    right: 22px;
    left: auto;
    margin-left: 0;
    border: 0;
}
.brand-screen.active:after {
    background: #ff2238;
}
//新人礼模块
.newer-contain {
    padding: 48px 8px 12px;
    width: 100%;
    background-size: 100% 100%;
}
// 每日秒杀
.bg-miaosha {
    padding: 12px;
    background: url('//image1.51tiangou.com/tgou2/img2/index/bg-miaosha.png') no-repeat;
    background-size: cover;
}
.bg-count-down .countdown-container {
  margin-right: 12px;
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  width: 19px;
  line-height: 17px;
  text-align: center;
}
.bg-count-down .countdown-container.sec {
  margin-right: 2px;
}
.bg-count-down .countdown-container:after {
  font-weight: bolder;
}
</style>