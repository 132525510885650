var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-xd-activity scroll-container-y",
      style: "padding-top: " + (_vm.isHybrid ? 40 : 84) + "px",
      attrs: { id: "scrollList" },
      on: { scroll: _vm.scrollHandle },
    },
    [
      _c(
        "div",
        { staticClass: "pos-f top0 back-ground-0" },
        [
          _c(
            "tg-head",
            {
              staticClass: "page-head",
              attrs: { isfixed: true, mode: "slot", "title-color": "white" },
            },
            [
              _c("img", {
                attrs: {
                  src: "//image1.51tiangou.com/tgou2/img2/index/xd/title-ppsg.png",
                  alt: "",
                  width: "78",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.tabList.length > 0
            ? _c(
                "div",
                { staticClass: "bar-tabs scroll-container" },
                _vm._l(_vm.tabList, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "fs-14 item-tab",
                      class:
                        Number(_vm.mxActivityId) == item.mxActivityId
                          ? "active"
                          : "normal",
                      attrs: {
                        "data-scp": "fl." + index,
                        "data-bk": "mxid-" + item.mxActivityId,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(item, index)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "dis-i",
                        domProps: {
                          textContent: _vm._s(item.shortName || item.name),
                        },
                      }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pos-f back-ground-1" }),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "bg-count-down flex-container center",
            staticStyle: { "margin-top": "8px" },
          },
          [
            _c(
              "div",
              {
                staticClass: "fs-12 text-white",
                staticStyle: { "margin-right": "6px" },
              },
              [_vm._v("距结束剩余")]
            ),
            _vm._v(" "),
            _c("cmui-countdown", {
              staticClass: "fs-10 brand-countdown",
              attrs: {
                "now-time": _vm.serverTime,
                "end-time": _vm.endTime,
                "show-day": true,
              },
              on: { countdownend: _vm.countdownend },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { padding: "8px" } },
          _vm._l(_vm.itemList, function (item, index) {
            return _c(
              "div",
              {
                staticClass: "item-product pos-r borderR8 bg-white overflow-h",
                attrs: {
                  "data-scp": "sp." + index,
                  "data-bk": "item-" + item.itemId,
                  "data-trace-id": "",
                },
                on: {
                  click: function ($event) {
                    return _vm.changeListing(item)
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: item.imageUrl, alt: "", width: "100" },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dis-i pos-r flex-container-col",
                    staticStyle: {
                      "margin-left": "8px",
                      width: "calc(100% - 112px)",
                      "vertical-align": "top",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "fs-14 text-black overflow-h",
                        staticStyle: {
                          "max-height": "20px",
                          "white-space": "normal",
                        },
                      },
                      [_vm._v(_vm._s(item.productName))]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "fs-12 text-light overflow-h",
                        staticStyle: {
                          "line-height": "16px",
                          "margin-top": "4px",
                          "white-space": "normal",
                        },
                      },
                      [_vm._v(_vm._s(item.promotion))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "min-height": "24px",
                          "margin-top": "4px",
                        },
                      },
                      [
                        item.promotionLabelList &&
                        item.promotionLabelList.length
                          ? _c(
                              "div",
                              { staticClass: "text-white fs-11" },
                              _vm._l(item.promotionLabelList, function (promo) {
                                return _c(
                                  "div",
                                  { staticClass: "icon-promotion" },
                                  [_vm._v(_vm._s(promo))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-container bottom bg-rank-price" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "fs-12 text-bolder",
                            staticStyle: {
                              "margin-bottom": "-3px",
                              color: "#ff2238",
                            },
                          },
                          [
                            _vm._v("¥\n                            "),
                            _c("span", { staticClass: "fs-20" }, [
                              _vm._v(_vm._s(item.price)),
                            ]),
                            _vm._v(" "),
                            item.price !== item.originalPrice
                              ? _c(
                                  "del",
                                  {
                                    staticClass: "text-light",
                                    staticStyle: { "font-weight": "normal" },
                                  },
                                  [_vm._v(" ¥" + _vm._s(item.originalPrice))]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(0, true),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-buy borderR8 fs-12" }, [
      _c("img", {
        attrs: {
          src: "//image1.51tiangou.com/tgou2/img2/index/xd/xd-ljgm.png",
          alt: "",
          width: "100",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }