var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.newData && _vm.newData.length
    ? _c("div", { staticClass: "newer-pop mask text-center" }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c(
            "div",
            {
              staticClass: "bg-newer-pop text-center",
              attrs: { "data-scp": "qukankan.0" },
              on: {
                click: function ($event) {
                  return _vm.changePage()
                },
              },
            },
            [
              _c("p", { staticClass: "fs-18 text-red text-bolder" }, [
                _vm._v("Hi，亲爱的会员"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "fs-18 text-red text-bolder",
                  staticStyle: { margin: "8px 0 15px" },
                },
                [_vm._v("恭喜您获得新人专属福利价")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "0 24px" } },
                _vm._l(_vm.newData.slice(0, 4), function (item) {
                  return _c(
                    "div",
                    {
                      staticClass: "dis-i text-center",
                      staticStyle: {
                        width: "calc(50% - 12px)",
                        "margin-bottom": "8px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "borderR8 pos-r overflow-h",
                          staticStyle: {
                            width: "100px",
                            border: "1px solid #999",
                            margin: "0 auto",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: item.imageUrl,
                              alt: "",
                              width: "100",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "fs-12",
                          staticStyle: {
                            margin: "4px 0 6px",
                            color: "#7d003c",
                          },
                        },
                        [_vm._v(_vm._s(item.title.slice(0, 6)))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "fs-12 text-center text-bolder text-red bg-price",
                        },
                        [
                          _vm._v("\n                            ￥"),
                          _c("span", { staticClass: "frs-30" }, [
                            _vm._v(_vm._s(item.price)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bg-red text-white",
                  staticStyle: {
                    width: "280px",
                    "line-height": "40px",
                    "border-radius": "20px",
                    margin: "18px auto 0",
                  },
                },
                [_vm._v("去看看")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("img", {
            staticStyle: { "margin-top": "16px" },
            attrs: {
              src: "//image1.51tiangou.com/tgou2/img2/cards/icon-close.png",
              alt: "",
              width: "28",
            },
            on: {
              click: function ($event) {
                _vm.newData = []
              },
            },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }