var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "article",
        {
          staticClass: "mask center",
          staticStyle: { "z-index": "99" },
          on: { click: _vm.closePopupPoster },
        },
        [
          _c(
            "div",
            {
              staticClass: "mask_content renew-content",
              class: _vm.countdown
                ? "renew-content-bg-countdown"
                : "renew-content-bg",
            },
            [
              _c("div", {
                staticClass: "bottom-close",
                attrs: { "data-scp": "popupPoster.close" },
                on: { click: _vm.closePopupPoster },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "renew-detail",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openRenewPage.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.countdown
                    ? _c("div", { staticClass: "renew-countdown-title" }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs-12",
                            staticStyle: { color: "#1A1D24" },
                          },
                          [_vm._v("VIP会员还有")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "fs-20",
                            staticStyle: { color: "#996B3A" },
                          },
                          [_vm._v(_vm._s(_vm.countdown))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "fs-12",
                            staticStyle: { color: "#1A1D24" },
                          },
                          [_vm._v("天到期")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.expiredDays
                    ? _c("div", { staticClass: "fs-14 expired-notice" }, [
                        _vm._v("您的vip会员已到期"),
                      ])
                    : _c("div", { staticClass: "fs-14 expired-notice" }, [
                        _vm._v("您的vip会员即将到期"),
                      ]),
                  _vm._v(" "),
                  !_vm.expiredDays
                    ? _c("div", { staticClass: "expired-cumulative" }, [
                        _c("img", {
                          attrs: {
                            src: "//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-cumulative-font.png",
                            width: "98",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "baseline",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "fs-18 line",
                                staticStyle: {
                                  color: "#E9B983",
                                  "line-height": "1",
                                },
                              },
                              [_vm._v("¥")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "daily-avg",
                                staticStyle: {
                                  "font-size": "30px",
                                  "font-family": "'DIN Alternate'",
                                  color: "#E9B983",
                                  "line-height": "1",
                                },
                              },
                              [_vm._v(_vm._s(_vm.data.totalDiscounts || 0))]
                            ),
                          ]
                        ),
                      ])
                    : _c("div", [
                        _vm.data.totalDiscounts > 66
                          ? _c("div", { staticClass: "already-save" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "fs-14",
                                  staticStyle: { color: "#B2B3B8" },
                                },
                                [_vm._v("已为您节省")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "fs-14",
                                  staticStyle: { color: "#E9B983" },
                                },
                                [_vm._v("¥")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "fs-20",
                                  staticStyle: {
                                    color: "#E9B983",
                                    "font-family": "'DIN Alternate'",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.data.totalDiscounts || 0))]
                              ),
                            ])
                          : _c("div", { staticClass: "already-save" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "fs-14",
                                  staticStyle: { color: "#B2B3B8" },
                                },
                                [_vm._v("更多权益等您来享")]
                              ),
                            ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "renew-daily" }, [
                          _c("img", {
                            staticStyle: { "vertical-align": "text-bottom" },
                            attrs: {
                              src: "//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-daily-font.png",
                              width: "158",
                              height: "26",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "baseline",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "fs-18 line",
                                  staticStyle: {
                                    color: "#E9B983",
                                    "line-height": "1",
                                  },
                                },
                                [_vm._v("¥")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "daily-avg",
                                  staticStyle: {
                                    "font-size": "30px",
                                    "font-family": "'DIN Alternate'",
                                    color: "#E9B983",
                                    "line-height": "1",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.data.dailyCost || 0))]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                  _vm._v(" "),
                  !_vm.expiredDays
                    ? _c(
                        "div",
                        { staticClass: "renew-divide renew-divide-bg-l fs-12" },
                        [_vm._v("再次开通 续享您的专属优惠")]
                      )
                    : _c(
                        "div",
                        { staticClass: "renew-divide renew-divide-bg-s fs-12" },
                        [_vm._v("续费立返")]
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "renew-coupon",
                      staticStyle: { "margin-top": "12px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "scroll-container",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        _vm._l(_vm.data.couponList, function (coupon) {
                          return _c(
                            "div",
                            { staticClass: "pos-r coupon-item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "coupon-detail text-darker fs-12 lh-1 flex-container",
                                  staticStyle: { "align-items": "baseline" },
                                },
                                [
                                  _vm._v("\n                ¥"),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "28px" } },
                                    [_vm._v(_vm._s(coupon.value))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-darker text-limit1 marginl10 dis-b",
                                      staticStyle: {
                                        "white-space": "normal",
                                        height: "11px",
                                        "font-weight": "bold",
                                      },
                                    },
                                    [_vm._v(_vm._s(coupon.name))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(0, true),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "renew-btn",
                      style: { bottom: !_vm.expiredDays ? "42px" : "34px" },
                    },
                    [_vm._v(_vm._s(!_vm.expiredDays ? "立即开通" : "立即续费"))]
                  ),
                  _vm._v(" "),
                  !_vm.expiredDays
                    ? _c(
                        "span",
                        {
                          staticClass: "fs-12",
                          staticStyle: { color: "#000" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.notAlert.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("不再提醒")]
                      )
                    : _c("span", { staticStyle: { "font-size": "10px" } }, [
                        _vm._v("以实际支付为准"),
                      ]),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "useInfo pos-a top0 right0 text-center" }, [
      _c("span", { staticStyle: { "line-height": "16px" } }, [
        _vm._v("全额抵用"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }