<!--新人弹窗-->
<template>
    <div class="newer-pop mask text-center" v-if="newData && newData.length">
        <div style="width: 100%;">
            <div class="bg-newer-pop text-center" @click="changePage()" data-scp="qukankan.0">
                <p class="fs-18 text-red text-bolder">Hi，亲爱的会员</p>
                <p class="fs-18 text-red text-bolder" style="margin: 8px 0 15px">恭喜您获得新人专属福利价</p>
                <div style="padding: 0 24px">
                    <div v-for="item in newData.slice(0, 4)" class="dis-i text-center" style="width: calc(50% - 12px);margin-bottom: 8px">
                        <div class="borderR8 pos-r overflow-h" style="width: 100px;border: 1px solid #999;margin: 0 auto">
                            <img :src="item.imageUrl" alt="" width="100">
<!--                            <img src="//image1.51tiangou.com/tgou2/img2/index/icon-pro-newer.png" alt="" class="pos-a left0 top0" width="56">-->
                        </div>
                        <p class="fs-12" style="margin: 4px 0 6px;color: #7d003c">{{item.title.slice(0, 6)}}</p>
                        <div class="fs-12 text-center text-bolder text-red bg-price">
                            ￥<span class="frs-30">{{item.price}}</span>
                        </div>
                    </div>
                </div>
                <div class="bg-red text-white" style="width: 280px;line-height: 40px;border-radius: 20px;margin: 18px auto 0">去看看</div>
            </div>
            <img src="//image1.51tiangou.com/tgou2/img2/cards/icon-close.png" alt="" @click="newData = []" width="28" style="margin-top: 16px">
        </div>
    </div>
</template>

<script>

import domain from "javascript/libs/tg/domain";

export default {
    name: "newer-pop",
    props: {
    },
    data() {
        return {
            newData: []
        }
    },
    methods: {
        setNewData(data) {
            this.newData = data;
        },
        changePage(){
            let contentId = 2108;
            if (domain.env === 'test') {
                contentId = 201;
            } else if (domain.env === 'pre') {
                contentId = 33;
            }
            let url = `./activity.html#/index?contentId=${contentId}&preview=true`, scp = 'qukankan.0';
            tg.changePage(url,{
                scp: `${tg.page}.${scp}.${Math.random().toString(36).slice(2, 7)}`
            });
        }
    }
};
</script>
<style>
.bg-newer-pop {
    margin: auto;
    padding-top: 154px;
    width: 320px;
    height: 602px;
    background: url("//image1.51tiangou.com/tgou2/img2/index/bg-newer-pop-2.png") no-repeat;
    background-size: 100% 100%;
}
.bg-price {
    margin: 0 auto;
    width: 80px;
    line-height: 16px;
    font-family: DINAlternate-Bold;
}
.borderR8{
    border-radius: 8px;
}
.text-red {
    color: #ff2238;
}
</style>