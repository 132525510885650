var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "article",
        {
          staticClass: "mask center",
          staticStyle: { "z-index": "99" },
          attrs: { id: "indexIframe" },
        },
        [
          _c(
            "div",
            {
              staticClass: "mask_content margint20",
              staticStyle: { width: "80%" },
            },
            [
              _c("div", {
                staticClass: "innerClose icon-close",
                attrs: { "data-scp": "popupPoster.close" },
                on: { click: _vm.closePopupPoster },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: _vm.checkUrl, "data-scp": "popupPoster.00" },
                  on: { click: _vm.closePopupPoster },
                },
                [
                  _c(
                    "div",
                    [
                      _vm.isWeixin && _vm.isList
                        ? _c(
                            "wx-open-launch-weapp",
                            {
                              attrs: {
                                id: "popup",
                                username: _vm.webcast.userName,
                                path: _vm.webcast.list,
                              },
                            },
                            [
                              _c(
                                "script",
                                { attrs: { type: "text/wxtag-template" } },
                                [
                                  _vm._v(
                                    '\r\n\t\t\t\t\t\t<img style="max-width: 80%;"\r\n\t\t\t\t\t\t     src="' +
                                      _vm._s(_vm.protocol) +
                                      _vm._s(_vm.data.imageUrl) +
                                      '" alt="">\r\n\t\t\t\t\t'
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm.isWeixin && _vm.isRoom
                        ? _c(
                            "wx-open-launch-weapp",
                            {
                              attrs: {
                                id: "popup",
                                username: _vm.webcast.userName,
                                path:
                                  _vm.webcast.room + "?roomId=" + _vm.roomId,
                              },
                            },
                            [
                              _c(
                                "script",
                                { attrs: { type: "text/wxtag-template" } },
                                [
                                  _vm._v(
                                    '\r\n\t\t\t\t\t\t<img style="max-width: 80%;"\r\n\t\t\t\t\t\t     src="' +
                                      _vm._s(_vm.protocol) +
                                      _vm._s(_vm.data.imageUrl) +
                                      '" alt="">\r\n\t\t\t\t\t'
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm.isWeixin && _vm.isApplets
                        ? _c(
                            "wx-open-launch-weapp",
                            {
                              attrs: {
                                id: "popup",
                                username: _vm.checkName,
                                path: _vm.data.clickUrl,
                              },
                            },
                            [
                              _c(
                                "script",
                                { attrs: { type: "text/wxtag-template" } },
                                [
                                  _vm._v(
                                    '\r\n\t\t\t\t\t\t<img style="max-width: 80%;"\r\n\t\t\t\t\t\t     src="' +
                                      _vm._s(_vm.protocol) +
                                      _vm._s(_vm.data.imageUrl) +
                                      '" alt="">\r\n\t\t\t\t\t'
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c("img", {
                            attrs: { src: _vm.data.imageUrl, alt: "" },
                          }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }