<template>
	<div :style="isHybrid?'background-color: #BFDAFC;':'background-color: #BFDAFC;position:absolute;height: 100%;'">
		<tg-head :right-items="[]" title="企业背书"></tg-head>

		<div>
			<img src="//image1.51tiangou.com/tgou2/img2/index/license_all.png" style="max-width: 100%;" alt="">
		</div>
	</div>
</template>
<script>
import app from 'javascript/libs/tg/app/index.js';
import domain from 'javascript/libs/tg/domain.js';

export default {
	name: 'license'
	, data(){
		let isHybrid = maple.cookie('hybrid')
			, isWeixin = maple.device.weixin
			, username = maple.cookie('username')
		;

		return {
			isHybrid
			, isWeixin
			, img: '//image1.51tiangou.com/tgou2/img2/tempActivity/img-publicity.png'
			, username
			, timer: null
		};
	}
	, created(){
		// app.getTgAppVersion();
		//
		// window.App.on('getTgAppVersion', this.version);
		//
		// this.isHybrid && (this.timer = setInterval(()=>{
		// 	app.getTgAppVersion();
		// }, 200));
	}
	, methods: {
		goToSee(){
			if( this.isHybrid ){
				app.startWxapp({
					userName: domain.webcast.userName
					, path: location.href
				});
			}
			else if( this.isWeixin ){

			}
		}
		, version(v){
			if( this.timer && /\d{8}/.test(v) ){
				clearInterval( this.timer );
			}

			this.isHybrid = maple.cookie('hybrid') && Number( v.toString().match(/\d+/)[0] ) >= 20201229;
		}
	}
};
</script>
<script>
export default {
	name: 'license'
};
</script>