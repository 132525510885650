import { render, staticRenderFns } from "./timeLimit.vue?vue&type=template&id=1d6fcbb3&"
import script from "./timeLimit.vue?vue&type=script&lang=js&"
export * from "./timeLimit.vue?vue&type=script&lang=js&"
import style0 from "./timeLimit.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_babel-core@6.26.3_css-loader@6.5.1_lodash@4.17.20_vue-template-compiler@2.6.14_webpack@5.69.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data1/jenkins/workspace/tgou/project/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d6fcbb3')) {
      api.createRecord('1d6fcbb3', component.options)
    } else {
      api.reload('1d6fcbb3', component.options)
    }
    module.hot.accept("./timeLimit.vue?vue&type=template&id=1d6fcbb3&", function () {
      api.rerender('1d6fcbb3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "spa/component/product/timeLimit.vue"
export default component.exports