<template>
<div class="bottomNotice blink" v-if="currentTopOrder" style="opacity: 0;z-index: 10">
    <div class="img-container full round float-left" style="width:33px;height: 33px;">
        <img :src="currentTopOrder.imageUrl | fixedDomain" alt="">
    </div>
    <div style="margin-left: 35px;">
        <a :href="currentTopOrder.clickUrl"
           data-scp="bottom.notice"
           v-text="currentTopOrder.title"
           class="bottomNoticeText text-limit1 text-white pos-r"></a>
    </div>
</div>
</template>

<script>
export default {
    name: "bottomNotice",
    props:{
        data:{type:Array,default:[]}
    },
    data(){
        return {
            currentTopOrder: null
            , index: -1
            , timer: null
        }
    }
    , created(){

    }
    , destroyed(){
        this.clearTimer();
    }
    , methods: {
        next(){
            this.index++;
            if( this.index < this.data.length ){
                this.currentTopOrder = this.data[this.index];

                this.timer = setTimeout(()=>{
                    this.timer = null;
                    this.next();
                }, 8000);
            }
            else{
                this.index = -1;
                this.currentTopOrder = null;
                this.timer = null;
            }
        }
        , clearTimer(){
            if( this.timer ){
                clearTimeout( this.timer );
                this.timer = null;
            }
        }
    }
    , watch: {
        data: {
            immediate: true
            , handler(val){
                if( val && val.length ){
                    this.clearTimer();

                    this.index = 0;
                    this.next();
                }
            }
        }
    }
}
</script>

<style scoped>
    .bottomNotice{
        position: fixed;
        bottom: 65px;
        left: 10px;
        right: 70px;
        height: 35px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 500px;
        padding: 1px;
    }
    .bottomNoticeText{
        line-height: 35px;
        padding-right:20px;
    }
    .bottomNoticeText:after{
        content: ">";
        display: block;
        position: absolute;
        right: 0;
        height: 20px;
        width: 20px;
        top: 0;
    }
    @-webkit-keyframes blink{
        0%{
            opacity: 0;
        }
        12.5%{
            opacity: 1;
        }
        50%{
            opacity: 1;
        }
        62.5%{
            opacity: 0;
        }
        to{
            opacity: 0;
        }
    }
    @keyframes blink{
        0%{
            opacity: 0;
        }
        12.5%{
            opacity: 1;
        }
        50%{
            opacity: 1;
        }
        62.5%{
            opacity: 0;
        }
        to{
            opacity: 0;
        }
    }
    .blink{
        -webkit-animation: blink 8s infinite;
        animation: blink 8s infinite;
    }
</style>