<!--吸底广告位-->
<template>
    <img :src="imgSrc" alt="" v-if="show" :data-scp="obj.scpCD" :data-bk="obj.bk" data-trace-id=""
         class="big-event-adv fixed-bottom" ref="bigEventAdv"
         :class="type === 1 ? 'adapt-h' : 'adapt-w'" @click="changePage">
</template>

<script>

export default {
    name: "bigEventAdv",
    props: {
        page: {
            type: String,
            default: ''
        },
        obj: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            imgSrc: this.obj.imgSrc || '',
            // 1是异形 2是矩形
            type: this.obj.type || 1,
            // 记录上一次滚动结束后的滚动距离
            oldScrollTop: 0,
            // 记录当前的滚动距离
            scrollTop: 0,
            // 动画是否进行中
            animationFlag: false,
            show: false,
            // 计算滑动的高度
            topH: 0
        }
    },
    watch: {
        scrollTop(newValue, oldValue) {
            setTimeout(() => {
                // 延时执行后当newValue等于window.scrollY，代表滚动结束
                if(newValue === window.scrollY) {
                    // 每次滚动结束后都要给oldScrollTop赋值
                    this.oldScrollTop = newValue
                    // 向上展开
                    this.showMail();
                }
            }, 1000);
            // 每次滚动开始时oldScrollTop与oldValue相等
            if(this.oldScrollTop === oldValue) {
                // 向下收起
                this.closeMail();
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll',() => {
            this.scrollTop = window.scrollY;
        })
    },
    methods: {
        // 向上展开
        showMail() {
            if (this.$refs.bigEventAdv) {
                this.$refs.bigEventAdv.classList.remove('entry-away');
                if (this.type === 1) {
                    this.$refs.bigEventAdv.classList.value += ' entry-arise';
                } else if (this.type === 2) {
                    this.$refs.bigEventAdv.classList.value += ' entry-arise-5';
                }
                this.$refs.bigEventAdv.style.bottom = `${this.topH}px`
            }
        },
        // 向下收起
        closeMail() {
            if (this.$refs.bigEventAdv) {
                if (this.type === 1) {
                    this.$refs.bigEventAdv.classList.remove('entry-arise');
                } else if (this.type === 2) {
                    this.$refs.bigEventAdv.classList.remove('entry-arise-5');
                }
                this.$refs.bigEventAdv.classList.value += ' entry-away';
                this.$refs.bigEventAdv.style.bottom = `-10px`
            }
        },
        setData(img, type) {
            this.imgSrc = img;
            this.type = type;
            this.show = true;
            setTimeout(() => {
                this.initFun();
            }, 500)
        },
        initFun() {
            const domH = document.getElementsByClassName('big-event-adv')[0].clientHeight;
            const footH = document.querySelector('.tg-foot') ? document.querySelector('.tg-foot').clientHeight : document.getElementsByClassName('foot-nav-cu')[0].clientHeight;
            this.topH = domH + footH;
            // 创建动画
            const runkeyframes = ` @keyframes entryUp{
                                    0%{ bottom: -10px;  }
                                    100%{  bottom: ${this.topH}px; }
                                }
                                @keyframes entryDown{
                                    0%{ bottom: ${this.topH}px;  }
                                    100%{  bottom: -10px; }
                                }
                                `
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = runkeyframes;
            document.getElementsByTagName('head')[0].appendChild(style);
            this.$nextTick(() => {
                this.showMail();
            })
            // 监听滚动
            window.addEventListener('scroll', () => {
                this.scrollTop = window.scrollY;
            })
        },
        changePage() {
            tg.changePage(this.obj.clickUrl)
        }
    }
};
</script>
<style scoped>
.big-event-adv {
    z-index: 2;
    transform: translateY(100%);
    bottom: -10px;
}
.adapt-h {
    height: 72px;
    left: 50%;
    transform: translate(-50%, 100%);
}
.adapt-h img {
    height: 100%;
}
.adapt-w {
    width: 100%;
}
.adapt-w img {
    width: 100%;
}
.entry-arise {
    animation: entryUp 700ms;
}
.entry-arise-5 {
    animation: entryUp 500ms;
}
.entry-away {
    animation: entryDown 500ms;
    bottom: -10px;
}
</style>