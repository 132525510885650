var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "a",
        {
          staticClass: "modalPoster",
          attrs: {
            href:
              _vm.isWeixin && (_vm.isList || _vm.isRoom)
                ? ""
                : _vm.data.clickUrl,
            "data-scp": "data.00",
          },
        },
        [
          _c(
            "div",
            [
              _vm.isWeixin && _vm.isList
                ? _c(
                    "wx-open-launch-weapp",
                    {
                      attrs: {
                        id: "popup",
                        username: _vm.webcast.userName,
                        path: _vm.webcast.list,
                      },
                    },
                    [
                      _c("script", { attrs: { type: "text/wxtag-template" } }, [
                        _vm._v(
                          '\r\n\t\t\t\t<img width="75"\r\n\t\t\t\t     height="75"\r\n\t\t\t\t     src="' +
                            _vm._s(_vm.protocol) +
                            _vm._s(_vm.data.imageUrl) +
                            '" alt="">\r\n\t\t\t'
                        ),
                      ]),
                    ]
                  )
                : _vm.isWeixin && _vm.isRoom
                ? _c(
                    "wx-open-launch-weapp",
                    {
                      attrs: {
                        id: "popup",
                        username: _vm.webcast.userName,
                        path: _vm.webcast.room + "?roomId=" + _vm.roomId,
                      },
                    },
                    [
                      _c("script", { attrs: { type: "text/wxtag-template" } }, [
                        _vm._v(
                          '\r\n\t\t\t\t<img width="75"\r\n\t\t\t\t     height="75"\r\n\t\t\t\t     src="' +
                            _vm._s(_vm.protocol) +
                            _vm._s(_vm.data.imageUrl) +
                            '" alt="">\r\n\t\t\t'
                        ),
                      ]),
                    ]
                  )
                : _c("img", {
                    attrs: {
                      src: _vm.data.imageUrl,
                      width: "75",
                      height: "75",
                      alt: "",
                    },
                  }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }