import { render, staticRenderFns } from "./app.vue?vue&type=template&id=328d7e40&scoped=true&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_babel-core@6.26.3_css-loader@6.5.1_lodash@4.17.20_vue-template-compiler@2.6.14_webpack@5.69.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328d7e40",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data1/jenkins/workspace/tgou/project/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('328d7e40')) {
      api.createRecord('328d7e40', component.options)
    } else {
      api.reload('328d7e40', component.options)
    }
    module.hot.accept("./app.vue?vue&type=template&id=328d7e40&scoped=true&", function () {
      api.rerender('328d7e40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "spa/apps/index/app.vue"
export default component.exports