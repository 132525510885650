var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { overflow: "hidden" },
      style:
        "background: #1E175A url('" +
        _vm.baseInfo.bg +
        "') no-repeat top center/100%;",
    },
    [
      _c("tg-head", {
        staticClass: "bg-transparent",
        attrs: { title: _vm.baseInfo.title, "title-color": "white" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { style: "margin:" + _vm.baseInfo.marginTop + " 12px 0;" },
        _vm._l(_vm.secKillEntry, function (sec, index) {
          return _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  "border-radius": "8px",
                  overflow: "hidden",
                  "margin-bottom": "8px",
                },
                style:
                  "background: url('" +
                  _vm.baseInfo.bgList[index % _vm.baseInfo.bgList.length] +
                  "') no-repeat top center/100%;",
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: sec.clickUrl,
                      "data-scp": "pinpai." + index,
                      "data-bk": "brand-" + sec.id,
                      "data-trace-id": "",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-container",
                        staticStyle: { padding: "0 12px", height: "58px" },
                      },
                      [
                        _c("div", {
                          staticClass: "fs-18",
                          staticStyle: {
                            "font-weight": "500",
                            "margin-top": "2px",
                          },
                          domProps: { textContent: _vm._s(sec.title) },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-container-y text-right" },
                          [
                            _c("div", {
                              staticStyle: { "font-weight": "500" },
                              domProps: { textContent: _vm._s(sec.subTitle) },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "text-black fs-10 flex-container",
                              },
                              [
                                _c("span", [_vm._v("距结束仅剩")]),
                                _vm._v(" "),
                                _c("cmui-countdown", {
                                  staticClass: "fs-10 brand-countdown",
                                  attrs: {
                                    "now-time": sec.serverTime,
                                    "end-time": sec.endTime,
                                    "show-day": true,
                                  },
                                  on: { countdownend: _vm.countdownend },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bg-white flex-container padding20" },
                  _vm._l(sec.items, function (item, i) {
                    return _c("div", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: sec.clickUrl + "&itemId=" + item.itemId,
                            "data-scp": "product." + index + "-" + i,
                            "data-bk": "item-" + item.itemId,
                            "data-trace-id": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "img-container pos-r",
                              staticStyle: { "border-radius": "8px" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: item.imageUrl,
                                  width: "105",
                                  height: "105",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              item.couponList && item.couponList.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pos-a bottom0 left0 right0 flex-container center",
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "bg-red fs-10 lh-16 text-white",
                                        staticStyle: {
                                          "border-radius": "4px",
                                          padding: "0 5px",
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.couponList[0]
                                          ),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "fs-12 text-center text-limit1",
                            staticStyle: { width: "105px" },
                            domProps: { textContent: _vm._s(item.title) },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-container center" }, [
                            _c("div", [
                              _c("span", { staticClass: "fs-10" }, [
                                _vm._v("¥"),
                              ]),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "fs-16",
                                staticStyle: {
                                  "font-weight": "500",
                                  "margin-left": "-4px",
                                },
                                domProps: { textContent: _vm._s(item.price) },
                              }),
                            ]),
                            _vm._v(" "),
                            item.originPrice
                              ? _c("del", {
                                  staticClass: "text-dark fs-10",
                                  domProps: {
                                    textContent: _vm._s("¥" + item.originPrice),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }