<template>
  <article class="mask center" v-if="data" style="z-index: 99;" @click="closePopupPoster">
    <div class="mask_content renew-content" :class="countdown ? 'renew-content-bg-countdown' : 'renew-content-bg'">
      <div class="bottom-close" @click="closePopupPoster" data-scp="popupPoster.close"></div>
      <!--<a href="/payCard.html" data-scp="popupPoster.00">-->
        <div class="renew-detail" @click.stop="openRenewPage">
          <div v-if="countdown" class="renew-countdown-title">
            <div class="fs-12" style="color: #1A1D24;">VIP会员还有</div>
            <div class="fs-20" style="color: #996B3A;">{{countdown}}</div>
            <div class="fs-12" style="color: #1A1D24;">天到期</div>
          </div>
          <div class="fs-14 expired-notice" v-if="!expiredDays">您的vip会员已到期</div>
          <div class="fs-14 expired-notice" v-else>您的vip会员即将到期</div>
          <div v-if="!expiredDays" class="expired-cumulative">
            <img src="//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-cumulative-font.png" width="98">
            <div style="display: flex;align-items: baseline;">
              <div class="fs-18 line" style="color: #E9B983;line-height: 1;">¥</div>
              <div class="daily-avg" style="font-size: 30px;font-family: 'DIN Alternate';color: #E9B983;line-height: 1;">{{data.totalDiscounts || 0}}</div>
            </div>
          </div>
          <div v-else>
            <div class="already-save" v-if="data.totalDiscounts > 66">
              <span class="fs-14" style="color: #B2B3B8;">已为您节省</span>
              <span class="fs-14" style="color: #E9B983;">¥</span>
              <span class="fs-20" style="color: #E9B983;font-family: 'DIN Alternate';">{{data.totalDiscounts || 0}}</span>
            </div>
            <div class="already-save" v-else>
              <span class="fs-14" style="color: #B2B3B8;">更多权益等您来享</span>
            </div>
            <div class="renew-daily">
              <img src="//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-daily-font.png" width="158" height="26" style="vertical-align: text-bottom;">
              <div style="display: flex;align-items: baseline;">
                <div class="fs-18 line" style="color: #E9B983;line-height: 1;">¥</div>
                <div class="daily-avg" style="font-size: 30px;font-family: 'DIN Alternate';color: #E9B983;line-height: 1;">{{data.dailyCost || 0}}</div>
              </div>
            </div>
          </div>
          <div v-if="!expiredDays" class="renew-divide renew-divide-bg-l fs-12">再次开通 续享您的专属优惠</div>
          <div v-else class="renew-divide renew-divide-bg-s fs-12">续费立返</div>
          <div class="renew-coupon" style="margin-top: 12px;">
            <div class="scroll-container" style="display: flex;justify-content: space-between;">
              <div v-for="coupon in data.couponList" class="pos-r coupon-item">
                <div class="coupon-detail text-darker fs-12 lh-1 flex-container" style="align-items: baseline;">
                  ¥<span style="font-size: 28px;">{{coupon.value}}</span>
                  <span class="text-darker text-limit1 marginl10 dis-b" style="white-space: normal;height: 11px;font-weight: bold;">{{coupon.name}}</span>
                </div>
                <div class="useInfo pos-a top0 right0 text-center">
                  <span style="line-height: 16px;">全额抵用</span>
                </div>
              </div>
            </div>
          </div>
          <button class="renew-btn" :style="{ bottom: !expiredDays ? '42px' : '34px'}">{{!expiredDays ? "立即开通" : "立即续费"}}</button>
          <span v-if="!expiredDays" class="fs-12" style="color: #000;" @click.prevent.stop="notAlert">不再提醒</span>
          <span v-else style="font-size: 10px;">以实际支付为准</span>
        </div>
      <!--</a>-->
    </div>
  </article>
</template>

<script>
import domain from 'javascript/libs/tg/domain.js';

let isWeixin = maple.device.weixin
    , protocol = location.protocol
;

export default {
  name: 'renewNotice',
  props: {
    data: {
      type: Object,
      default: null
    }
  }
  , data(){
    return {}
  },
  methods: {
    closePopupPoster () {
      this.$emit('close-popup-poster');
    },
    notAlert () {
      localStorage.setItem("TG-Renew-Alert", true);
      this.$emit('close-popup-poster');
    },
    openRenewPage () {
      tg.changePage('/payCard.html', { showVip: true });
    }
  },
  computed: {
    expiredDays () {
      return Math.ceil((new Date(this.data.endTime) - new Date()) / (1*24*60*60*1000));
    },
    countdown () {5
      return [1, 2, 3].includes(this.expiredDays) ? this.expiredDays : null;
    }
  }
};
</script>
<style scoped>
.bottom-close {
  position: absolute;
  background-image: url("//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-close-notice.png");
  background-size: cover;
  bottom: -64px;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}
.renew-content {
  background-size: cover;
  width: 250px;
  height: 350px;
}
.renew-content-bg {
  background-image: url("//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-bg.png");
}
.renew-content-bg-countdown {
  background-image: url("//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-content-bg-countdown.png");
}
.renew-content > a {
  display: block;
  height: 100%;
}
.renew-detail {
  position: relative;
  height: 100%;
  padding: 0 12px 12px;
}
.renew-countdown-title {
  position: absolute;
  display: flex;
  align-items: baseline;
  height: 28px;
}
.expired-notice {
  padding-top: 40px;
  color: #B2B3B8;
}
.expired-cumulative {
  height: 75px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.already-save {
  height: 30px;
}
.renew-daily {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  height: 30px;
}
.renew-divide {
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #E9B983;
  margin-top: 12px;
}
.renew-divide-bg-l {
  background-image: url(//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-divide-l.png);
  background-size: 250px 2px;
}
.renew-divide-bg-s {
  background-image: url(//image1.51tiangou.com/tgou2/img2/cards/payCard/renew-divide-s.png);
  background-size: contain;
}
.coupon-item {
  border-radius: 8px;
  width: 109px;
  height: 48px;
  background-image: url('//image1.51tiangou.com/tgou2/img2/giftCard/coupon-bg.png');
  background-position: top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.coupon-detail {
  float: left;
  border-radius: 8px;
  height: 100%;
  padding: 12px 6px;
}
.useInfo{
  min-width: 40px;
  width: 48px;
  height: 16px;
  border-radius: 0 8px 0 8px;
  background-color: #171A24;
  color: #E9B983;
  font-size: 9px;
  border: #e9b983 1px solid;
}
.renew-btn {
  position: absolute;
  width: 226px;
  height: 40px;
  bottom: 34px;
  background-image: linear-gradient(90deg, #33384A 0%, #171A24 50%);
  border-radius: 8px;
  color: #E9B983;
  border: none;
}
.renew-detail > span:last-child {
  position: absolute;
  bottom: 12px;
  left: 0;
  text-align: center;
  color: #666;
  width: 100%;
  font-weight: 400;
}
</style>
