<template>
    <div class="page-xd-activity scroll-container-y" @scroll="scrollHandle" id="scrollList"
         :style="`padding-top: ${isHybrid ? 40 : 84}px`">
        <div class="pos-f top0 back-ground-0">
            <tg-head :isfixed="true" mode="slot" title-color="white" class="page-head">
                <img src="//image1.51tiangou.com/tgou2/img2/index/xd/title-ppsg.png" alt="" width="78">
            </tg-head>
            <!-- tab切换 -->
            <div class="bar-tabs scroll-container" v-if="tabList.length > 0">
                <div class="fs-14 item-tab" v-for="(item, index) in tabList"
                     :data-scp="`fl.${index}`" :data-bk="`mxid-${item.mxActivityId}`"
                     :class="Number(mxActivityId) == item.mxActivityId ? 'active' : 'normal'" @click="changeTab(item, index)">
                    <div v-text="item.shortName || item.name" class="dis-i"></div>
                </div>
            </div>
        </div>
        <div class="pos-f back-ground-1"></div>
        <div>
            <!-- 倒计时 -->
            <div class="bg-count-down flex-container center" style="margin-top: 8px">
                <div class="fs-12 text-white" style="margin-right: 6px">距结束剩余</div>
                <!-- 具体时间 -->
                <cmui-countdown
                    class="fs-10 brand-countdown"
                    @countdownend="countdownend"
                    :now-time="serverTime"
                    :end-time="endTime"
                    :show-day="true"></cmui-countdown>
            </div>
            <!-- 活动列表 -->
            <div style="padding: 8px;">
                <div v-for="(item, index) in itemList"
                     :data-scp="`sp.${index}`" :data-bk="`item-${item.itemId}`" data-trace-id=""
                     class="item-product pos-r borderR8 bg-white overflow-h" @click="changeListing(item)">
                    <img :src="item.imageUrl" alt="" width="100">
                    <div class="dis-i pos-r flex-container-col" style="margin-left: 8px;width: calc(100% - 112px);vertical-align: top;">
                        <p class="fs-14 text-black overflow-h" style="max-height: 20px;white-space: normal;">{{item.productName}}</p>
                        <p class="fs-12 text-light overflow-h" style="line-height: 16px;margin-top: 4px;white-space: normal;">{{item.promotion}}</p>
                        <div style="min-height: 24px;margin-top: 4px">
                            <div class="text-white fs-11"
                                 v-if="item.promotionLabelList && item.promotionLabelList.length">
                                <div class="icon-promotion" v-for="promo in item.promotionLabelList">{{promo}}</div>
                            </div>
                        </div>
                        <div class="flex-container bottom bg-rank-price">
                            <div class="fs-12 text-bolder" style="margin-bottom: -3px;color: #ff2238">¥
                                <span class="fs-20">{{item.price}}</span>
                                <del class="text-light" style="font-weight: normal;" v-if="item.price !== item.originalPrice">&nbsp;¥{{item.originalPrice}}</del>
                            </div>
                            <div class="btn-buy borderR8 fs-12">
                                <img src="//image1.51tiangou.com/tgou2/img2/index/xd/xd-ljgm.png" alt="" width="100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import itemService from "spa/lib/api/item";
import domain from "javascript/libs/tg/domain";

export default {
    name: 'xdActivity',
    config: {
        page: '28.sgxq'
    },
    data(){
        return {
            curBar: 0,
            mxActivityId: 0,
            page: 1,
            noData: false,
            isRequestOver: true,
            state: 'loading',
            itemList: [],
            tabList: [],
            isHybrid: maple.cookie('hybrid'),
            serverTime: 0,
            endTime: 0,
            startX: 0,
            startY: 0,
            isBottom: false,
            scrollEvent: _.throttle(() => {
                this.scrollEventFun();
            },1000,{
                leading:false
            })
        };
    },
    mounted(){
        this.params = tg.getParams().hashParams;
        this.mxActivityId = this.params.mxActivityId;
        this.requestTabs();
    },
    methods: {
        requestTabs() {
            itemService.xdListTab({
                data:{
                    cateId: this.params.cateId,
                    groupId: this.params.groupId
                },
                useCatch:false
            }).then(data=>{
                if (data && data.length) {
                    this.tabList = data;
                    this.serverTime = new Date().getTime();
                    this.endTime = this.tabList[0].endTime;
                    if (this.mxActivityId) {
                        this.curBar = this.tabList.findIndex(item => {
                            return item.mxActivityId === this.mxActivityId;
                        })
                    }
                    this.requestXdActivity();
                }
            });
        },
        // 滚动加载活动列表
        requestXdActivity() {
            this.isRequestOver = false;
            itemService.xdProductList({
                data: {
                    activityId: this.mxActivityId,
                    groupId: this.params.groupId,
                    page: this.page++,
                    rows: 10
                }
            }).then(data=>{
                if (data && data.rows && data.rows.length) {
                    if (data.rows.length < 10) {
                        this.noData = true;
                    }
                    this.handleProduct(JSON.parse(JSON.stringify(data.rows)));
                } else {
                    this.noData = true;
                }
                this.state = 'end';
                this.isRequestOver = true;
            });
        },
        handleProduct(data) {
            data.forEach(item => {
                item.imageUrl = this.getImageUrl(item.image);
                this.itemList.push(item)
            })
            // 需要手动把前一个页点击的品排到第一个
            if (this.params.itemId) {
                let itemId = Number(this.params.itemId);
                let index = this.itemList.findIndex(item => {
                    return itemId === item.itemId;
                })
                if (index && index !== -1) {
                    let obj = this.itemList.splice(index, 1)[0];
                    this.itemList.unshift(obj)
                }
            }
            this.$nextTick(() => {
                tg.updateTraceProductList();
                // 上滑自动切换至下一屏
                let dom = document.getElementById('scrollList');
                dom.addEventListener('scroll', () => {
                    this.isBottom = false;
                    this.scrollEvent();
                });
                dom.addEventListener('touchstart', this.handleTouchEvent);
                dom.addEventListener('touchend', this.handleTouchEvent);
                setTimeout(() => {
                    // 判断三级分类是否超出一屏
                    let screenHeight = dom.clientHeight,
                        scrollHeight = dom.scrollHeight;
                    if (screenHeight >= scrollHeight) {
                        this.isBottom = true;
                    }
                }, 500)
            })
        },
        changeTab(item, index) {
            this.page = 1;
            this.mxActivityId = item.mxActivityId;
            this.curBar = index;
            this.noData = false;
            this.itemList = [];
            this.serverTime = new Date().getTime();
            this.endTime = this.tabList[index].endTime;
            this.requestXdActivity();
            this.$router.replace({path: 'xdActivity', query: {cateId: this.params.cateId, groupId: this.params.groupId, mxActivityId: this.mxActivityId}})
        },
        getImageUrl(imageUrl) {
            return domain.getImageUrl(imageUrl);
        },
        changeListing(item) {
            tg.changePage('/product/listing.html',{
                id: item.itemId
            });
        },
        scrollHandle(e) {
            const that = this;
            _.debounce(() => {
                // 滚动加载
                if( this.scrollBarBottom(e.target) < 1 ){
                    if( that.noData || !that.isRequestOver ){
                        return;
                    }
                    this.requestXdActivity();
                }
            }, 300)();
        },
        scrollBarBottom(dom) {
            let total = dom.scrollHeight, view = dom.clientHeight,
                curr = total - dom.scrollTop - view;
            return parseFloat((curr / view).toFixed(1));
        },
        countdownend() {
            location.reload();
        },
        handleTouchEvent(event) {
            switch (event.type) {
                case 'touchstart':
                    this.startX = event.touches[0].pageX;
                    this.startY = event.touches[0].pageY;
                    break;
                case 'touchend':
                    let spanY = event.changedTouches[0].pageY - this.startY;
                    // 向上
                    if (spanY < -30 && this.curBar < this.tabList.length - 1 && this.isBottom) {
                        this.$nextTick(() => {
                            let tabDomList = document.getElementsByClassName('item-tab');
                            tabDomList[this.curBar + 1].scrollIntoView({
                                // behavior: 'smooth',
                                inline: 'center'
                            })
                        })
                        this.changeTab(this.tabList[this.curBar + 1], this.curBar + 1);
                    }
                    break;
            }
        },
        scrollEventFun() {
            let dom = document.getElementById('scrollList'),
                screenHeight = dom.clientHeight,
                scrollTop = dom.scrollTop,
                scrollHeight = dom.scrollHeight;
            // 滚动到底再滑动
            this.isBottom = screenHeight + Math.ceil(scrollTop) >= scrollHeight;
        }
    }
};
</script>

<style scope lang="scss">
.page-xd-activity {
    width: 100%;
    height: 100%;

    .back-ground-0 {
        width: 100%;
        background: url('//image1.51tiangou.com/tgou2/img2/index/xd/page-back-0.png') no-repeat;
        background-size: 100% 100%;
        z-index: 1;
    }

    .back-ground-1 {
        width: 100%;
        height: 100%;
        background: url("//image1.51tiangou.com/tgou2/img2/index/xd/page-back-1.png") no-repeat;
        background-size: 100% 100%;
        z-index: -1;
    }

    .page-head .tg-head {
        background: transparent;
        .flex1 {
            text-align: center;
        }
        a {
            color: #fff;
        }
    }

    .bar-tabs {
        .item-tab {
            margin-left: 20px;
            line-height: 40px;
            &:last-child {
                margin-right: 20px;
            }
        }
        .normal {
            color: #DAD4FF;
        }
        .active {
            color: #fff;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 8px;
                height: 2px;
                background: #fff;
                bottom: 4px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 8px;
            }
        }
    }

    .bg-count-down {
        margin: 0 8px;
        height: 34px;
        background: rgba(255,255,255,0.2);
        border-radius: 4px;
    }

    .brand-countdown {
        .countdown-container {
            background-color: transparent;
            margin-right: 0;
            &::after {
                right: -15px;
                color: #fff;
            }
            &.day, &.hour, &.minute {
                margin-right: 13px;
                margin-left: 5px;
                width: auto;
                color: #052360;
                line-height: 17px;
                background: #fff;
                border-radius: 4px;
                padding: 0 3px;
                font-size: 12px;
            }
            &.day::after {
                content:'天';
            }
            &.hour::after {
                content:'时';
            }
            &.minute::after {
                content:'分';
            }
            &.sec{
                display: none;
            }
        }
    }

    .item-product {
        margin-bottom: 8px;
        padding: 8px;
        .icon-promotion {
            padding: 0 4px;
            width: fit-content;
            background: linear-gradient( 141deg, #FF5D22 0%, #FF9E61 100%);
            border-radius: 4px;
            line-height: 18px;
        }
    }
}
</style>