var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("img", {
        ref: "bigEventAdv",
        staticClass: "big-event-adv fixed-bottom",
        class: _vm.type === 1 ? "adapt-h" : "adapt-w",
        attrs: {
          src: _vm.imgSrc,
          alt: "",
          "data-scp": _vm.obj.scpCD,
          "data-bk": _vm.obj.bk,
          "data-trace-id": "",
        },
        on: { click: _vm.changePage },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }