<template>
    <cmui-list :col="3" :space="10" class="padding10">
        <cmui-list-item v-for="(item,$index) in data">
            <div class="border border-radius bg-white pos-r">
                <div v-show="hot" class="pos-a" style="top:-2px;left:-2px;z-index: 1">
                    <!-- 金牌 -->
                    <img v-if="$index===0" src="//image1.51tiangou.com/tgou2/img2/index/img-renqi_gold_2.png" alt="" height="31">
                    <!-- 银牌 -->
                    <img v-else src="//image1.51tiangou.com/tgou2/img2/index/img-renqi_silver_2.png" alt="" height="31">
                </div>
                <a :href="item.clickUrl" :data-scp="`pop.${$index}`" :data-trace-id="item.traceId" :data-bk="`id-${item.id}`">
                    <div class="ratio-container border-radius img-container pos-r" style="overflow: hidden;">
                        <img alt="" width="100%"  :src="item.imageUrl">

                        <div v-show="hot" class="pos-a left0 right0 bottom0" style="background: linear-gradient(to top,rgba(247,107,28,.7) 0,rgba(255,255,255,0) 70%);top:50%"></div>
                        <div class="pos-a flex-container center bottom10" style="top:auto;">
                            <div class="text-red paddingh10"
                                 style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius: 8px;font-size:9px;"
                                 v-if="item.couponList && item.couponList.length"
                                 v-text="item.couponList[0]"></div>
                        </div>
                    </div>
                    <div class="paddingh10 text-darker fs-12 text-center margint10 text-fixed2 text-bolder" style="min-height:14px;">{{item.title}}</div>
                    <div class="flex-container paddingb10 text-center">
                        <span class="flex1 text-red fs-14  marginh10">¥{{item.price}}<del v-if="item.originalPrice!==item.price" class="flex1 text-light fs-10 marginh10">¥{{item.originalPrice}}</del></span>
                    </div>
                </a>
            </div>
        </cmui-list-item>
    </cmui-list>
</template>

<script>
    export default {
        name: "popularity",
        props:{
            hot:{
                type:Boolean,
                default:true
            },
            data:{type:Array,default:[]}
        }
    }
</script>

<style scoped>

</style>