var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-xd-brand scroll-container-y",
      style: "padding-top: " + (_vm.isHybrid ? 40 : 84) + "px",
      attrs: { id: "scrollList" },
      on: { scroll: _vm.scrollHandle },
    },
    [
      _c(
        "div",
        { staticClass: "pos-f top0 back-ground-0" },
        [
          _c(
            "tg-head",
            {
              staticClass: "page-head",
              attrs: { isfixed: true, mode: "slot", "title-color": "white" },
            },
            [
              _c("img", {
                attrs: {
                  src: "//image1.51tiangou.com/tgou2/img2/index/xd/title-ppsg.png",
                  alt: "",
                  width: "78",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.tabList.length
            ? _c(
                "div",
                { staticClass: "bar-tabs scroll-container" },
                _vm._l(_vm.tabList, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "fs-14 item-tab",
                      class: item.id === _vm.id ? "active" : "normal",
                      attrs: {
                        "data-scp": "fl." + index,
                        "data-bk": "fl-" + item.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(item, index)
                        },
                      },
                    },
                    [
                      item.img
                        ? _c("img", {
                            staticStyle: { "vertical-align": "text-bottom" },
                            attrs: {
                              src: _vm.getImageUrl(item.img),
                              height: "16",
                              alt: "",
                            },
                          })
                        : _c("div", {
                            staticClass: "dis-i",
                            domProps: { textContent: _vm._s(item.title) },
                          }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activityList.length
        ? _c(
            "div",
            { staticStyle: { padding: "8px" } },
            _vm._l(_vm.activityList, function (sec, index) {
              return _c(
                "div",
                {
                  staticClass: "item-activity overflow-h",
                  class: "bg-activity-" + (index % 5),
                },
                [
                  _c(
                    "div",
                    {
                      attrs: {
                        "data-scp": "hd." + index,
                        "data-bk": "mxid-" + sec.mxActivityId,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path: "xdActivity",
                            query: {
                              mxActivityId: sec.mxActivityId,
                              cateId: _vm.id,
                              groupId: _vm.groupId,
                            },
                          })
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-container",
                          staticStyle: { padding: "0 12px", height: "36px" },
                        },
                        [
                          _c("div", {
                            staticClass: "fs-14 text-bolder",
                            domProps: { textContent: _vm._s(sec.name) },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-container-y text-right" },
                            [
                              _c("div", {
                                staticStyle: { "font-weight": "500" },
                                domProps: { textContent: _vm._s(sec.subTitle) },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "fs-12 flex-container",
                                  staticStyle: { color: "#8e8e8e" },
                                },
                                [
                                  _c("span", [_vm._v("距结束")]),
                                  _vm._v(" "),
                                  _c("cmui-countdown", {
                                    staticClass: "brand-countdown",
                                    attrs: {
                                      "now-time": sec.serverTime,
                                      "end-time": sec.endTime,
                                      "show-second": false,
                                      "show-day": true,
                                    },
                                    on: { countdownend: _vm.countdownend },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "scroll-container",
                      staticStyle: { padding: "0 8px 8px" },
                    },
                    [
                      _vm._l(sec.itemList, function (item, i) {
                        return _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-right": "8px",
                              "max-width": "105px",
                            },
                            attrs: {
                              "data-scp": "hdsp." + index,
                              "data-bk": "mxid-" + sec.mxActivityId,
                              "data-trace-id": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeActive(sec, item.itemId)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-container pos-r",
                                staticStyle: { "border-radius": "8px" },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.imageUrl,
                                    width: "105",
                                    height: "105",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                item.promotionLabelList &&
                                item.promotionLabelList.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pos-a icon-promotion text-white fs-11",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.promotionLabelList[0])
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "fs-12 text-center text-limit1 text-black",
                              staticStyle: { "margin-top": "5px" },
                              domProps: {
                                textContent: _vm._s(
                                  item.shortName || item.productName
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "dis-i",
                                  staticStyle: { color: "#ff2238" },
                                },
                                [
                                  _c("span", { staticClass: "fs-12" }, [
                                    _vm._v("¥"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "fs-20",
                                    staticStyle: {
                                      "font-weight": "500",
                                      "margin-left": "-4px",
                                    },
                                    domProps: {
                                      textContent: _vm._s(item.price),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              item.originalPrice
                                ? _c("del", {
                                    staticClass: "text-light fs-11",
                                    staticStyle: { "margin-left": "2px" },
                                    domProps: {
                                      textContent: _vm._s(
                                        "¥" + item.originalPrice
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "vertical-align": "top" },
                          attrs: {
                            "data-scp": "hd.more",
                            "data-bk": "mxid-" + sec.mxActivityId,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                path: "xdActivity",
                                query: {
                                  mxActivityId: sec.mxActivityId,
                                  cateId: _vm.id,
                                  groupId: _vm.groupId,
                                },
                              })
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "//image1.51tiangou.com/tgou2/img2/index/xd/xd_see_more.png",
                              width: "90",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      (!_vm.tabList.length || !_vm.activityList.length) && _vm.isRequestOver
        ? _c(
            "div",
            {
              staticClass: "text-center fs-16",
              style:
                "height: calc(100% - " +
                (_vm.tabList.length ? 84 : 44) +
                "px);background: #fbfbfb;color: #999;padding-top: 4.5rem",
            },
            [
              _c("img", {
                attrs: {
                  src: "//image1.51tiangou.com/tgou2/img2/blank/blankNew/blankGoods.png",
                  alt: "",
                  width: "100%",
                },
              }),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": ".5rem" } }, [
                _vm._v("活动已结束~"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("tg-loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isRequestOver,
            expression: "!isRequestOver",
          },
        ],
        attrs: { state: _vm.state },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }