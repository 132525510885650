<template>
<div class="marginh20 marginb20 timeLimit"
     v-if="data.items.length>2" :style="(type === 1 || type === 2) && 'background:#fff url('+data.backgroundImg+') no-repeat top center;background-size:100% auto;'"
     style="border-radius:3px;background-color:#fff;">
	<a :href="data.clickUrl"
	   :data-scp="`timeLimit.${index}`"
	   :data-bk="`id-${data.id}`"
	   :data-trace-id="data.traceId">
		<div class="pos-r" style="min-height:67px;">
			<div class="topBg"
				 style="border-top-right-radius: 2px;border-top-left-radius: 2px;width: 100%;"
				 v-if="type === 3">
				<img :src="data.backgroundImg" width="100%" height="67" alt="" style="opacity: 0">
			</div>
			<!-- <img width="100%" alt="" style="border-top-right-radius: 2px;border-top-left-radius: 2px;min-height:2.8rem"
				 :src="data.backgroundImg"
				 v-if="type === 1 || type === 2"> -->
			<div class="pos-a text-center bgClass flex-container-col right"
				 :style="type === 1 ? 'height:67px;padding: 9px 8px;' : 'height:67px;padding: 9px 8px;'">
<!-- 				<div class="pos-r flex-container center" style="width:70px;height:20px;"
					 v-if="type === 1">
					<div class="pos-a top-n30 bg-black" v-if="data.logoImg">
						<img width="70" alt=""
							 :src="data.logoImg">
					</div>
				</div> -->
<!-- 				<div class="text-limit1 text-white" style="font-size: 9px;"
					 v-if="type === 1"
					 v-text="data.subTitle"></div> -->
				<div class="flex-container text-white center fs-12 paddingh20" style="background:rgba(0,0,0,.2);border-radius:20px; line-height: 20px;">
					<span class="marginr10">距结束</span>
					<!-- 天数 -->
					<span class="dis-i text-white border-radius text-center fs-14" v-if="showTimeType===1" v-text="day"></span>
					<span class="marginl10"
						  v-if="showTimeType===1">天</span>
					<!-- 具体时间 -->
					<cmui-countdown
						class="fs-14"
						:now-time="data.serverTime"
						:end-time="data.endTime"
						v-if="showTimeType===2"></cmui-countdown>
				</div>
				<div class="fs-16 text-limit1 text-white paddingt10 text-bolder"
					 v-text="data.title" style="text-shadow: -3px 4px 3px rgba(0,0,0,.2);"></div>
			</div>
		</div>
	</a>
	<div class="flex-container marginh20">
		<div class="flex1 bg-white" v-for="(item,innerIndex) in data.items" :class="innerIndex > 0 && 'marginl20'" style="padding:2px;border-radius:3px;">
			<a :href="data.clickUrl+'&itemId='+item.itemId"
			   :data-scp="`timeLimit.${index}-${innerIndex}`"
			   :data-bk="`id-${data.id}`"
			   :data-trace-id="data.traceId">
				<div class="ratio-container img-container pos-r">
					<img alt="" width="100%"
						 :src="item.imageUrl">
					<div v-if="item.couponList && item.couponList.length" class="pos-a flex-container center bottom10" style="top:auto">
						<div v-if="item.couponList && item.couponList.length">
							<div class="text-red paddingh10" style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius: 8px;font-size:9px"
							     v-text="item.couponList[0]"></div>
						</div>
					</div>
					<div class="pos-a bottom10 fs-12 flex-container center lh-20 paddingh20"
					     style="background: rgba(0,0,0,.7);color: #E9B983;top:auto;left:auto;right:auto;border-radius:20px;"
					     v-if="!(item.couponList && item.couponList.length) && item.vipPrice">
						<img src="//image1.51tiangou.com/tgou2/img2/product/ico-up.png" width="16" alt="">
						¥{{item.vipPrice}}
					</div>
				</div>
				<div class="text-darker fs-12 text-center margint10 text-bolder text-fixed1"
					 v-text="item.title"></div>
				<div class="flex-container text-center fs-10">
					<span class="flex1 text-red paddingr10"
						  :class="item.originalPrice&&(item.originalPrice!==item.price)&&'text-right'">¥<span class="fs-14" v-text="`${item.price}`"></span></span>
					<del class="flex1 text-light text-left" 
						 v-if="item.originalPrice&&(item.originalPrice!==item.price)"
						 v-text="`¥${item.originalPrice}`"></del>
				</div>
			</a>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: "timeLimit",
	props:{
		type:{	// type:1 超级品牌 type:2 常规品牌 type:3 聚合品
			type: Number
			, default: 1
		}
		, data: {
			type: Object
			, default: {
				items: []
			}
		},
		index:{type:Number,default:0}
	}
	, computed: {
		showTimeType(){
			if( this.data ){
				return this.data.endTime - this.data.serverTime > 86400000 ? 1 : 2
			}

			return 2;
		}
		, day(){
			if( this.data ){
				return Math.floor((this.data.endTime - this.data.serverTime) / 86400000)
			}

			return 0;
		}
	}
}
</script>

<style lang="scss" type="text/scss">
	.timeLimit{
		.bgClass{
			width:185px;
			top:0;
			right:0;
		}
		.countdown-container{
			padding:0;
			width:16px;
			text-align: center;
			display:inline-block;
			line-height:20px;
			background: none;
		}
		.countdown-container:not(:last-child):after {
		    color: #fff;
		    top:-1px;
		}
		&:nth-of-type(7n-6){
			background:url('//image1.51tiangou.com/tgou2/img2/index/bg-index_new_1.png') no-repeat center top;
			background-size:100% auto;
		}
		&:nth-of-type(7n-5){
			background:url('//image1.51tiangou.com/tgou2/img2/index/bg-index_new_2.png') no-repeat center top;
			background-size:100% auto;
		}
		&:nth-of-type(7n-4){
			background:url('//image1.51tiangou.com/tgou2/img2/index/bg-index_new_3.png') no-repeat center top;
			background-size:100% auto;
		}
		&:nth-of-type(7n-3){
			background:url('//image1.51tiangou.com/tgou2/img2/index/bg-index_new_4.png') no-repeat center top;
			background-size:100% auto;
		}
		&:nth-of-type(7n-2){
			background:url('//image1.51tiangou.com/tgou2/img2/index/bg-index_new_5.png') no-repeat center top;
			background-size:100% auto;
		}
		&:nth-of-type(7n-1){
			background:url('//image1.51tiangou.com/tgou2/img2/index/bg-index_new_6.png') no-repeat center top;
			background-size:100% auto;
		}
		&:nth-of-type(7n){
			background:url('//image1.51tiangou.com/tgou2/img2/index/bg-index_new_7.png') no-repeat center top;
			background-size:100% auto;
		}
	}

</style>