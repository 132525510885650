<template>
    <div class="page-xd-brand scroll-container-y" @scroll="scrollHandle" id="scrollList"
         :style="`padding-top: ${isHybrid ? 40 : 84}px`">
        <div class="pos-f top0 back-ground-0">
            <tg-head :isfixed="true" mode="slot" title-color="white" class="page-head">
                <img src="//image1.51tiangou.com/tgou2/img2/index/xd/title-ppsg.png" alt="" width="78">
            </tg-head>
            <!-- tab切换 -->
            <div class="bar-tabs scroll-container" v-if="tabList.length">
                <div class="fs-14 item-tab" v-for="(item, index) in tabList"
                     :data-scp="`fl.${index}`" :data-bk="`fl-${item.id}`"
                     :class="item.id === id ? 'active' : 'normal'" @click="changeTab(item, index)">
                    <img v-if="item.img" :src="getImageUrl(item.img)" height="16" style="vertical-align: text-bottom" alt=""/>
                    <div v-else v-text="item.title" class="dis-i"></div>
                </div>
            </div>
        </div>
<!--        <div class="pos-f back-ground-1"></div>-->
        <!-- 活动列表 -->
        <div v-if="activityList.length" style="padding: 8px">
            <div v-for="(sec, index) in activityList" class="item-activity overflow-h" :class="`bg-activity-${index%5}`">
                <div @click="$router.push({path:'xdActivity',query:{mxActivityId: sec.mxActivityId, cateId: id, groupId}})"
                     :data-scp="`hd.${index}`" :data-bk="`mxid-${sec.mxActivityId}`">
                    <div class="flex-container" style="padding: 0 12px;height: 36px;">
                        <div class="fs-14 text-bolder" v-text="sec.name"></div>
                        <div class="flex-container-y text-right">
                            <div style="font-weight: 500;" v-text="sec.subTitle"></div>
                            <div class="fs-12 flex-container" style="color: #8e8e8e">
                                <span>距结束</span>
                                <!-- 具体时间 -->
                                <cmui-countdown
                                    class="brand-countdown"
                                    @countdownend="countdownend"
                                    :now-time="sec.serverTime"
                                    :end-time="sec.endTime"
                                    :show-second="false"
                                    :show-day="true"></cmui-countdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scroll-container" style="padding: 0 8px 8px">
                    <div v-for="(item, i) in sec.itemList"
                         style="margin-right: 8px;max-width: 105px;"
                         @click="changeActive(sec, item.itemId)"
                         :data-scp="`hdsp.${index}`"
                         :data-bk="`mxid-${sec.mxActivityId}`"
                         data-trace-id="">
                        <div class="img-container pos-r" style="border-radius: 8px;">
                            <img :src="item.imageUrl"
                                 width="105"
                                 height="105"
                                 alt="">
                            <div class="pos-a icon-promotion text-white fs-11"
                                 v-if="item.promotionLabelList && item.promotionLabelList.length">{{item.promotionLabelList[0]}}</div>
                        </div>
                        <div class="fs-12 text-center text-limit1 text-black" style="margin-top: 5px" v-text="item.shortName || item.productName"></div>
                        <div class="text-center">
                            <div class="dis-i" style="color: #ff2238">
                                <span class="fs-12">¥</span>
                                <span class="fs-20" style="font-weight: 500;margin-left: -4px;" v-text="item.price"></span>
                            </div>
                            <del class="text-light fs-11" v-if="item.originalPrice" style="margin-left: 2px" v-text="`¥${item.originalPrice}`"></del>
                        </div>
                    </div>
                    <div @click="$router.push({path:'xdActivity',query:{mxActivityId: sec.mxActivityId, cateId: id, groupId}})"
                         :data-scp="`hd.more`" :data-bk="`mxid-${sec.mxActivityId}`" style="vertical-align: top">
                        <img src="//image1.51tiangou.com/tgou2/img2/index/xd/xd_see_more.png" width="90" alt=""/>
                    </div>
                </div>
            </div>
        </div>
        <!-- 缺省状态 -->
        <div v-if="(!tabList.length || !activityList.length) && isRequestOver" class="text-center fs-16"
             :style="`height: calc(100% - ${tabList.length ? 84 : 44}px);background: #fbfbfb;color: #999;padding-top: 4.5rem`">
            <img src="//image1.51tiangou.com/tgou2/img2/blank/blankNew/blankGoods.png" alt="" width="100%">
            <p style="padding-top: .5rem">活动已结束~</p>
        </div>
        <tg-loading v-show="!isRequestOver" :state="state"></tg-loading>
    </div>
</template>

<script>
import itemService from "spa/lib/api/item";
import domain from "javascript/libs/tg/domain";

export default {
    name: 'xdBrand',
    config: {
        page: '27.ppsg'
    },
    data(){
        return {
            curBar: 0,
            id: 0,
            page: 1,
            noData: false,
            isRequestOver: false,
            state: 'loading',
            activityList: [],
            tabList: [],
            isHybrid: maple.cookie('hybrid'),
            groupId: null,
            startX: 0,
            startY: 0,
            isBottom: false,
            scrollEvent: _.throttle(() => {
                this.scrollEventFun();
            },1000,{
                leading:false
            })
        };
    },
    mounted(){
        this.params = tg.getParams().hashParams;
        this.groupId = this.params.groupId;
        this.requestTabs();
    },
    methods: {
        requestTabs() {
            itemService.xdBrandTab({
                data:{
                    groupId: this.groupId
                },
                useCatch:false
            }).then(data=>{
                if (data && data.length) {
                    this.tabList = data;
                    if (this.params && this.params.id) {
                        this.id = Number(this.params.id)
                        this.curBar = this.tabList.findIndex(item => {
                            return item.id === Number(this.params.id);
                        })
                        this.$nextTick(() => {
                            let tabDomList = document.getElementsByClassName('item-tab');
                            tabDomList[this.curBar].scrollIntoView({
                                // behavior: 'smooth',
                                inline: 'center'
                            })
                        })
                    } else {
                        this.id = data[0].id;
                    }
                    this.requestXdActivity();
                }
            });
        },
        // 滚动加载活动列表
        requestXdActivity() {
            this.isRequestOver = false;
            this.state = 'loading';
            itemService.xdActivityList({
                data: {
                    cateId: this.id,
                    groupId: this.groupId,
                    page: this.page++,
                    rows: 10
                }
            }).then(data=>{
                if (data && data.rows) {
                    if (data.rows.length < 10) {
                        this.noData = true;
                    }
                    this.handleProduct(JSON.parse(JSON.stringify(data.rows)));
                } else {
                    this.noData = true;
                }
                this.state = 'end';
                this.isRequestOver = true;
            });
        },
        handleProduct(data) {
            data.forEach(activity => {
                activity.itemList.forEach(item =>{
                    item.imageUrl = this.getImageUrl(item.image);
                })
                activity.mxActivityId = activity.mxActivityId.trim();
                this.activityList.push(activity)
            })
            this.$nextTick(() => {
                tg.updateTraceProductList();
                // 上滑自动切换至下一屏
                let dom = document.getElementById('scrollList');
                dom.addEventListener('scroll', () => {
                    this.isBottom = false;
                    this.scrollEvent();
                });
                dom.addEventListener('touchstart', this.handleTouchEvent);
                dom.addEventListener('touchend', this.handleTouchEvent);
                setTimeout(() => {
                    // 判断三级分类是否超出一屏
                    let screenHeight = dom.clientHeight,
                        scrollHeight = dom.scrollHeight;
                    if (screenHeight >= scrollHeight) {
                        this.isBottom = true;
                    }
                }, 500)
            })
        },
        changeTab(item, index) {
            this.curBar = index;
            this.noData = false;
            this.page = 1;
            this.id = item.id;
            this.activityList = [];
            this.requestXdActivity();
            this.$router.replace({path: 'xdBrand', query: {id: item.id, groupId: this.groupId}})
        },
        getImageUrl(imageUrl) {
            return domain.getImageUrl(imageUrl);
        },
        changeActive(sec, id) {
            this.$router.push({path:'xdActivity', query: {
                mxActivityId: sec.mxActivityId,
                cateId: this.id,
                itemId: id,
                groupId: this.groupId
            }})
        },
        scrollHandle(e) {
            const that = this;
            _.debounce(() => {
                // 滚动加载
                if( this.scrollBarBottom(e.target) < 1 ){
                    if( that.noData || !that.isRequestOver ){
                        return;
                    }
                    this.requestXdActivity();
                }
            }, 300)();
        },
        scrollBarBottom(dom) {
            let total = dom.scrollHeight, view = dom.clientHeight,
                curr = total - dom.scrollTop - view;
            return parseFloat((curr / view).toFixed(1));
        },
        countdownend() {
            location.reload();
        },
        handleTouchEvent(event) {
            switch (event.type) {
                case 'touchstart':
                    this.startX = event.touches[0].pageX;
                    this.startY = event.touches[0].pageY;
                    break;
                case 'touchend':
                    let spanY = event.changedTouches[0].pageY - this.startY;
                    // 向上
                    if (spanY < -30 && this.curBar < this.tabList.length - 1 && this.isBottom) {
                        this.$nextTick(() => {
                            let tabDomList = document.getElementsByClassName('item-tab');
                            tabDomList[this.curBar + 1].scrollIntoView({
                                // behavior: 'smooth',
                                inline: 'center'
                            })
                        })
                        this.changeTab(this.tabList[this.curBar + 1], this.curBar + 1);
                    }
                    break;
            }
        },
        scrollEventFun() {
            let dom = document.getElementById('scrollList'),
                screenHeight = dom.clientHeight,
                scrollTop = dom.scrollTop,
                scrollHeight = dom.scrollHeight;
            // 滚动到底再滑动
            this.isBottom = screenHeight + Math.ceil(scrollTop) >= scrollHeight;
        }
    }
};
</script>

<style scope lang="scss">
.page-xd-brand {
    width: 100%;
    height: 100%;
    background-color: #04175e;

    .back-ground-0 {
        width: 100%;
        background: url('//image1.51tiangou.com/tgou2/img2/index/xd/page-back-0.png') no-repeat;
        background-size: 100% 100%;
        z-index: 1;
    }

    .back-ground-1 {
        width: 100%;
        height: 100%;
        background: url("//image1.51tiangou.com/tgou2/img2/index/xd/page-back-1.png") no-repeat;
        background-size: 100% 100%;
        z-index: -1;
    }

    .page-head .tg-head {
        background: transparent;
        .flex1 {
            text-align: center;
        }
        a {
            color: #fff;
        }
    }

    .bar-tabs {
        .item-tab {
            margin-left: 20px;
            line-height: 40px;
            &:last-child {
                margin-right: 20px;
            }
        }
        .normal {
            color: #DAD4FF;
        }
        .active {
            color: #fff;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 8px;
                height: 2px;
                background: #fff;
                bottom: 4px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 8px;
            }
        }
    }

    .bg-activity-0 {
        background: url("//image1.51tiangou.com/tgou2/img2/index/xd/xd_tj_0.png") no-repeat;
        background-size: 100% 100%;
    }
    .bg-activity-1 {
        background: url("//image1.51tiangou.com/tgou2/img2/index/xd/xd_tj_1.png") no-repeat;
        background-size: 100% 100%;
    }
    .bg-activity-2 {
        background: url("//image1.51tiangou.com/tgou2/img2/index/xd/xd_tj_2.png") no-repeat;
        background-size: 100% 100%;
    }
    .bg-activity-3 {
        background: url("//image1.51tiangou.com/tgou2/img2/index/xd/xd_tj_3.png") no-repeat;
        background-size: 100% 100%;
    }
    .bg-activity-4 {
        background: url("//image1.51tiangou.com/tgou2/img2/index/xd/xd_tj_4.png") no-repeat;
        background-size: 100% 100%;
    }

    .item-activity {
        background-size: 100% 100%;
        border-radius: 8px;
        margin-bottom: 8px;
        .icon-promotion {
            bottom: 0;
            padding: 0 4px;
            background: linear-gradient( 141deg, #FF5D22 0%, #FF9E61 100%);
            border-radius: 4px;
            line-height: 18px;
        }
    }

    .brand-countdown {
        .countdown-container {
            background-color: transparent;
            margin-right: 0;
            &::after {
                right: -15px;
                color: #8e8e8e;
            }
            &.day, &.hour, &.minute {
                margin-right: 13px;
                margin-left: 5px;
                color: #052360;
                line-height: 17px;
                background: #fff;
                border-radius: 4px;
                padding: 0 3px;
                font-size: 12px;
            }
            &.day::after {
                content:'天';
            }
            &.hour::after {
                content:'时';
            }
            &.minute::after {
                content:'分';
            }
            &.sec{
                display: none;
            }
        }
    }
}
</style>