<template>
<div style="overflow:hidden;"
     :style="`background: #1E175A url('${baseInfo.bg}') no-repeat top center/100%;`">
	<tg-head :title="baseInfo.title" title-color="white" class="bg-transparent"></tg-head>
	<div :style="`margin:${baseInfo.marginTop} 12px 0;`">
		<div v-for="(sec, index) in secKillEntry">
			<div style="border-radius: 8px;overflow: hidden;margin-bottom: 8px;"
			     :style="`background: url('${baseInfo.bgList[index % baseInfo.bgList.length]}') no-repeat top center/100%;`">
				<a :href="sec.clickUrl"
				   :data-scp="`pinpai.${index}`"
				   :data-bk="`brand-${sec.id}`"
				   data-trace-id="">
					<div class="flex-container" style="padding: 0 12px;height: 58px;">
						<div class="fs-18"
						     style="font-weight: 500;margin-top: 2px;"
						     v-text="sec.title"></div>
						<div class="flex-container-y text-right">
							<div style="font-weight: 500;" v-text="sec.subTitle"></div>
							<div class="text-black fs-10 flex-container">
								<span>距结束仅剩</span>
								<!-- 天数 -->
								<!-- <span v-if="showTimeType(sec)===1" v-text="day(sec)"></span>
								<span v-if="showTimeType(sec)===1">天</span> -->
								<!-- 具体时间 -->
								<cmui-countdown
									class="fs-10 brand-countdown"
									@countdownend="countdownend"
									:now-time="sec.serverTime"
									:end-time="sec.endTime"
									:show-day="true"></cmui-countdown>
							</div>
						</div>
					</div>
				</a>

				<div class="bg-white flex-container padding20">
					<div v-for="(item, i) in sec.items">
						<a :href="`${sec.clickUrl}&itemId=${item.itemId}`"
						   :data-scp="`product.${index}-${i}`"
						   :data-bk="`item-${item.itemId}`"
						   data-trace-id="">
							<div class="img-container pos-r" style="border-radius: 8px;">
								<img :src="item.imageUrl"
								     width="105"
								     height="105"
								     alt="">
								<div v-if="item.couponList && item.couponList.length" class="pos-a bottom0 left0 right0 flex-container center">
									<div v-text="item.couponList[0]"
									     class="bg-red fs-10 lh-16 text-white"
									     style="border-radius: 4px;padding: 0 5px;"></div>
								</div>
							</div>
							<div class="fs-12 text-center text-limit1" style="width: 105px;" v-text="item.title"></div>
							<div class="flex-container center">
							<div>
								<span class="fs-10">¥</span>
								<span class="fs-16"
								      style="font-weight: 500;margin-left: -4px;"
								      v-text="item.price"></span>
							</div>
							<del class="text-dark fs-10"
							     v-if="item.originPrice"
							     v-text="`¥${item.originPrice}`"></del>
						</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'brand'
	, config: {
		page: '14.pp'
	}
	, data(){
		return {
			baseInfo: {}
			, secKillEntry: []
		};
	}
	, created(){
		document.body.style.backgroundColor = '#1E175A';
		this.fetchData = tg.autoMidway({
			// url: '/shopping/home/init.node'
			url: '/index/brand.node'
			, data: {
				storeId: maple.cookie('storeId')
				, cityId: maple.cookie('cityId')
				, lat: maple.cookie('lat')
				, lon: maple.cookie('lon')
			}
			, listCom: ['secKillEntry']
		}, this).then(()=>{
		});
	}
	, destroyed(){
		document.body.style.backgroundColor = '#fff';
	}
	, methods: {
		showTimeType(data){
			if( data ){
				return data.endTime - data.serverTime > 86400000 ? 1 : 2
			}

			return 2;
		}
		, day(data){
			if( data ){
				return Math.floor((data.endTime - data.serverTime) / 86400000)
			}

			return 0;
		},
		countdownend() {
			location.reload();
		}
	}
};
</script>

<style scope lang="scss">
.bg-transparent{
	background: transparent;

	.bg-white{
		background: transparent;
	}

	.header_left a,
	.header_right a{
		color: #fff;
	}
}
.brand-countdown .countdown-container.day:after{
	content:'天';
	right: -13px;
}
.brand-countdown .countdown-container.day {
	margin-right: 13px;
}
.brand-countdown .countdown-container {
	background-color: transparent;
	color: #000;
	margin-right: 0;
}
.brand-countdown .countdown-container:after {
	right: -2px;
}
</style>