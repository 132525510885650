var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cmui-list",
    { staticClass: "padding10", attrs: { col: 3, space: 10 } },
    _vm._l(_vm.data, function (item, $index) {
      return _c("cmui-list-item", [
        _c("div", { staticClass: "border border-radius bg-white pos-r" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hot,
                  expression: "hot",
                },
              ],
              staticClass: "pos-a",
              staticStyle: { top: "-2px", left: "-2px", "z-index": "1" },
            },
            [
              $index === 0
                ? _c("img", {
                    attrs: {
                      src: "//image1.51tiangou.com/tgou2/img2/index/img-renqi_gold_2.png",
                      alt: "",
                      height: "31",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: "//image1.51tiangou.com/tgou2/img2/index/img-renqi_silver_2.png",
                      alt: "",
                      height: "31",
                    },
                  }),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: item.clickUrl,
                "data-scp": "pop." + $index,
                "data-trace-id": item.traceId,
                "data-bk": "id-" + item.id,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ratio-container border-radius img-container pos-r",
                  staticStyle: { overflow: "hidden" },
                },
                [
                  _c("img", {
                    attrs: { alt: "", width: "100%", src: item.imageUrl },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hot,
                        expression: "hot",
                      },
                    ],
                    staticClass: "pos-a left0 right0 bottom0",
                    staticStyle: {
                      background:
                        "linear-gradient(to top,rgba(247,107,28,.7) 0,rgba(255,255,255,0) 70%)",
                      top: "50%",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "pos-a flex-container center bottom10",
                      staticStyle: { top: "auto" },
                    },
                    [
                      item.couponList && item.couponList.length
                        ? _c("div", {
                            staticClass: "text-red paddingh10",
                            staticStyle: {
                              background: "rgba(255,255,255,0.80)",
                              border: "1px solid #FF4C48",
                              "border-radius": "8px",
                              "font-size": "9px",
                            },
                            domProps: {
                              textContent: _vm._s(item.couponList[0]),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "paddingh10 text-darker fs-12 text-center margint10 text-fixed2 text-bolder",
                  staticStyle: { "min-height": "14px" },
                },
                [_vm._v(_vm._s(item.title))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-container paddingb10 text-center" },
                [
                  _c(
                    "span",
                    { staticClass: "flex1 text-red fs-14  marginh10" },
                    [
                      _vm._v("¥" + _vm._s(item.price)),
                      item.originalPrice !== item.price
                        ? _c(
                            "del",
                            { staticClass: "flex1 text-light fs-10 marginh10" },
                            [_vm._v("¥" + _vm._s(item.originalPrice))]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }