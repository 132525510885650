<template>
<a class="modalPoster"
   v-if="data"
   :href="isWeixin && (isList || isRoom) ? '' : data.clickUrl"
   data-scp="data.00">
	<div>
		<wx-open-launch-weapp id="popup" v-if="isWeixin && isList"
		                      :username="webcast.userName"
		                      :path="webcast.list">
			<script type="text/wxtag-template">
				<img width="75"
				     height="75"
				     src="{{protocol}}{{data.imageUrl}}" alt="">
			</script>
		</wx-open-launch-weapp>
		<wx-open-launch-weapp id="popup" v-else-if="isWeixin && isRoom"
		                      :username="webcast.userName"
		                      :path="`${webcast.room}?roomId=${roomId}`">
			<script type="text/wxtag-template">
				<img width="75"
				     height="75"
				     src="{{protocol}}{{data.imageUrl}}" alt="">
			</script>
		</wx-open-launch-weapp>
		<img v-else :src="data.imageUrl" width="75" height="75" alt="">
	</div>
</a>
</template>

<script>
import domain from 'javascript/libs/tg/domain.js';

let isWeixin = maple.device.weixin
	, protocol = location.protocol
	;

export default {
	name: 'modalPoster'
	, props: {
		data: {
			type: Object
			, default: null
		}
	}
	, data(){
		return {
			isWeixin
			, webcast: {
				userName: domain.webcast.userName
				, list: `${domain.webcast.path}.html`
				, room: `${domain.webcast.room}.html`
			}
			, protocol
			, roomId: ''

		};
	}
	, watch: {
		data: {
			handler(newValue){
				if( newValue ){
					this.show = true;

					if( this.isRoom ){
						let rs = /webcast\.html.*?#\/room\?roomId=([^&]*)/.exec( newValue.clickUrl )
						;

						if( rs ){
							this.roomId = rs[1];
						}
					}
				}
			}
			, immediate: true
		}
	}
	, computed: {
		isList(){
			return /webcast\.html.*?#\/publicity/.test( this.data.clickUrl );
		}
		, isRoom(){
			return /webcast\.html.*?#\/room/.test( this.data.clickUrl );
		}
	}
};
</script>
<style scoped>
.modalPoster{
	position: fixed;
	left: 10px;
	/*right: 10px;*/
	/*bottom: 80px;*/
	/*z-index: 2147483647;*/
	/*width: 40px;*/
	/*height: 40px;*/
	border-radius: 50%;
	text-align: center;
}
.modalPoster{
	bottom: 60px;
	/*right: 0;*/
	z-index: 99;
	height: auto;
	width: auto;
	-webkit-transition: opacity .5s;
	transition: opacity .5s;
}
.pageScrollIng .modalPoster{
	opacity: .5;
}
</style>