var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%", background: "#f8f8f8" } },
    [
      _c("tg-head", { attrs: { title: "全部分类" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pos-r", staticStyle: { padding: "8px 12px 0" } },
        [
          _c(
            "div",
            {
              staticClass: "flex-container bg-white",
              staticStyle: { "border-radius": "200px", padding: "4px" },
              attrs: { "data-scp": "search.0" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    path: "search",
                    query: {
                      type: "index",
                      placeholder: "请输入您要搜索的商品",
                    },
                  })
                },
              },
            },
            [_vm._m(0)]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "clearfix",
          staticStyle: { width: "100%", height: "calc(100% - 88px)" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "classify-fir text-dark scroll-container-y text-center fs-14",
            },
            _vm._l(_vm.treeData, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "text text-limit1 anchor-fir",
                  class:
                    _vm.curFirLevel === index
                      ? "active text-red fs-18 text-bolder"
                      : "",
                  attrs: {
                    "data-scp": "one." + index,
                    "data-bk": "id-" + item.id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeFirLevel(index)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(item.name.slice(0, 5)) +
                      "\n            "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "classify-right" }, [
            _c(
              "div",
              { staticClass: "classify-sec text-black scroll-container" },
              _vm._l(_vm.curClassifySec, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "fs-12 text anchor-sec",
                    class: _vm.curSecLevel === index ? "active text-red" : "",
                    attrs: {
                      "data-scp": "two." + index,
                      "data-bk": "id-" + item.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeSecLevel(index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.name) +
                        "\n                "
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "classify-thd text-dark scroll-container-y" },
              [
                _vm._l(_vm.curClassifySec, function (item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "text fs-12 anchor-thd" },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "fs-16 text-black text-bolder",
                          staticStyle: {
                            "margin-left": "4px",
                            "margin-bottom": "4px",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex-container left",
                          staticStyle: { "flex-wrap": "wrap" },
                        },
                        _vm._l(item.subCategory, function (itemT, indexT) {
                          return _c(
                            "div",
                            {
                              key: itemT.id,
                              staticClass: "text-center text-limit1",
                              staticStyle: { width: "33%" },
                              attrs: {
                                "data-scp": "three." + indexT,
                                "data-bk": "id-" + itemT.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goSearchClassify(indexT, itemT)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img-container",
                                  staticStyle: { padding: "0 4px" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getImageUrl(itemT.imageUrl),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticStyle: { margin: "4px 0" } }, [
                                _vm._v(_vm._s(itemT.name)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.curFirLevel < _vm.treeData.length - 1 &&
                _vm.treeData[_vm.curFirLevel + 1]
                  ? _c("div", { staticClass: "text-center" }, [
                      _vm._m(1),
                      _vm._v(
                        "\n                    向上继续拉浏览 " +
                          _vm._s(_vm.treeData[_vm.curFirLevel + 1].name) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-container" }, [
      _c("img", {
        staticStyle: { "margin-left": "4px" },
        attrs: {
          src: "//image1.51tiangou.com/tgou2/img2/index/icon_search.png",
          width: "24",
          height: "24",
          alt: "",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "text-limit1 text-left text-light fs-12",
          staticStyle: { "margin-left": "8px" },
        },
        [_vm._v("请输入您要搜索的商品")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "dis-i", staticStyle: { transform: "rotate(-90deg)" } },
      [_c("span", { staticClass: "icon-newPageGo fs-13 text-light" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }