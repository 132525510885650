<template>
<article class="mask center"
		 v-if="data" style="z-index: 99;" id="indexIframe">
	<div class="mask_content margint20" style="width:80%">
		<div class="innerClose icon-close" @click="closePopupPoster" data-scp="popupPoster.close"></div>
		<a :href="checkUrl"
		   @click="closePopupPoster"
		   data-scp="popupPoster.00">
			<div>
				<wx-open-launch-weapp id="popup" v-if="isWeixin && isList"
				                      :username="webcast.userName"
				                      :path="webcast.list">
					<script type="text/wxtag-template">
						<img style="max-width: 80%;"
						     src="{{protocol}}{{data.imageUrl}}" alt="">
					</script>
				</wx-open-launch-weapp>
				<wx-open-launch-weapp id="popup" v-else-if="isWeixin && isRoom"
				                      :username="webcast.userName"
				                      :path="`${webcast.room}?roomId=${roomId}`">
					<script type="text/wxtag-template">
						<img style="max-width: 80%;"
						     src="{{protocol}}{{data.imageUrl}}" alt="">
					</script>
				</wx-open-launch-weapp>
				<wx-open-launch-weapp id="popup" v-else-if="isWeixin && isApplets"
				                      :username="checkName"
				                      :path="data.clickUrl">
					<script type="text/wxtag-template">
						<img style="max-width: 80%;"
						     src="{{protocol}}{{data.imageUrl}}" alt="">
					</script>
				</wx-open-launch-weapp>
				<img v-else :src="data.imageUrl" alt="">
			</div>
		</a>
	</div>
</article>
</template>

<script>
import domain from 'javascript/libs/tg/domain.js';

let isWeixin = maple.device.weixin
	, protocol = location.protocol
	;

export default {
	name: 'popupPoster'
	, props: {
		data: {
			type: Object
			, default: null
		}
	}
	, data(){
		return {
			show: false
			, isWeixin
			, webcast: {
				userName: domain.webcast.userName
				, list: `${domain.webcast.path}.html`
				, room: `${domain.webcast.room}.html`
			},
      overSea: {
        userName: domain.overSea.userName
      }
			, protocol
			, roomId: ''
		}
	}
	, mounted(){
	  // console.log(this.data)
		this.$nextTick(()=>{
			let dom = document.querySelector('#popup')
				;
			if( dom ){
				dom.addEventListener('launch', this.closePopupPoster);
			}
		});
	}
	, destroy(){
		let dom = document.querySelector('#popup')
			;

		if( dom ){
			dom.removeEventListener('launch', this.closePopupPoster);
		}
	}
	, methods: {
		closePopupPoster(){
			this.show = false;
			this.$emit('close-popup-poster');
		}
	}
	, watch: {
		data: {
			handler(newValue){
				if( newValue ){
					this.show = true;

					if( this.isRoom ){
						let rs = /webcast\.html.*?#\/room\?roomId=([^&]*)/.exec( newValue.clickUrl )
						;

						if( rs ){
							this.roomId = rs[1];
						}
					}
				}
			}
			, immediate: true
		}
	}
	, computed: {
    checkName() {
      const item = this.data
      if(item.miniProgramKey && item.miniProgramKey === 'DASHANG_APPLET') {
        return this.webcast.userName
      } else {
        return this.overSea.userName
      }
    },
    checkUrl() {
      if (!this.isApplets) {
        return this.isWeixin && (this.isList || this.isRoom ) ? '' : this.data.clickUrl
      } else {
        if (!this.isWeixin) return `${this.protocol}/wechatQrcode.html?appletsType=${this.data.miniProgramKey}`
      }
    },
		isList(){
			return /webcast\.html.*?#\/publicity/.test( this.data.clickUrl );
		}
		, isRoom(){
			return /webcast\.html.*?#\/room/.test( this.data.clickUrl );
		},
    isApplets() {
      return this.data.clickUrlType === 4
    },
	}
};
</script>
<style scoped lang="scss" type="text/scss">
.innerClose{
	position: absolute;
	background-color: rgba(0,0,0,0.4);
	top: -30px;
	right: 0;
	width: 20px;
	height: 20px;
	line-height: 19px;
	border: 1px solid rgba(255,255,255,0.8);
	border-radius: 50%;
	text-align: center;
	color:white;
}
</style>
