var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: _vm.isHybrid
        ? "background-color: #BFDAFC;"
        : "background-color: #BFDAFC;position:absolute;height: 100%;",
    },
    [
      _c("tg-head", { attrs: { "right-items": [], title: "企业背书" } }),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { "max-width": "100%" },
        attrs: {
          src: "//image1.51tiangou.com/tgou2/img2/index/license_all.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }