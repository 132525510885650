var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.items.length > 2
    ? _c(
        "div",
        {
          staticClass: "marginh20 marginb20 timeLimit",
          staticStyle: { "border-radius": "3px", "background-color": "#fff" },
          style:
            (_vm.type === 1 || _vm.type === 2) &&
            "background:#fff url(" +
              _vm.data.backgroundImg +
              ") no-repeat top center;background-size:100% auto;",
        },
        [
          _c(
            "a",
            {
              attrs: {
                href: _vm.data.clickUrl,
                "data-scp": "timeLimit." + _vm.index,
                "data-bk": "id-" + _vm.data.id,
                "data-trace-id": _vm.data.traceId,
              },
            },
            [
              _c(
                "div",
                { staticClass: "pos-r", staticStyle: { "min-height": "67px" } },
                [
                  _vm.type === 3
                    ? _c(
                        "div",
                        {
                          staticClass: "topBg",
                          staticStyle: {
                            "border-top-right-radius": "2px",
                            "border-top-left-radius": "2px",
                            width: "100%",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { opacity: "0" },
                            attrs: {
                              src: _vm.data.backgroundImg,
                              width: "100%",
                              height: "67",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pos-a text-center bgClass flex-container-col right",
                      style:
                        _vm.type === 1
                          ? "height:67px;padding: 9px 8px;"
                          : "height:67px;padding: 9px 8px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-container text-white center fs-12 paddingh20",
                          staticStyle: {
                            background: "rgba(0,0,0,.2)",
                            "border-radius": "20px",
                            "line-height": "20px",
                          },
                        },
                        [
                          _c("span", { staticClass: "marginr10" }, [
                            _vm._v("距结束"),
                          ]),
                          _vm._v(" "),
                          _vm.showTimeType === 1
                            ? _c("span", {
                                staticClass:
                                  "dis-i text-white border-radius text-center fs-14",
                                domProps: { textContent: _vm._s(_vm.day) },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showTimeType === 1
                            ? _c("span", { staticClass: "marginl10" }, [
                                _vm._v("天"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showTimeType === 2
                            ? _c("cmui-countdown", {
                                staticClass: "fs-14",
                                attrs: {
                                  "now-time": _vm.data.serverTime,
                                  "end-time": _vm.data.endTime,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "fs-16 text-limit1 text-white paddingt10 text-bolder",
                        staticStyle: {
                          "text-shadow": "-3px 4px 3px rgba(0,0,0,.2)",
                        },
                        domProps: { textContent: _vm._s(_vm.data.title) },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-container marginh20" },
            _vm._l(_vm.data.items, function (item, innerIndex) {
              return _c(
                "div",
                {
                  staticClass: "flex1 bg-white",
                  class: innerIndex > 0 && "marginl20",
                  staticStyle: { padding: "2px", "border-radius": "3px" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.data.clickUrl + "&itemId=" + item.itemId,
                        "data-scp": "timeLimit." + _vm.index + "-" + innerIndex,
                        "data-bk": "id-" + _vm.data.id,
                        "data-trace-id": _vm.data.traceId,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ratio-container img-container pos-r" },
                        [
                          _c("img", {
                            attrs: {
                              alt: "",
                              width: "100%",
                              src: item.imageUrl,
                            },
                          }),
                          _vm._v(" "),
                          item.couponList && item.couponList.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pos-a flex-container center bottom10",
                                  staticStyle: { top: "auto" },
                                },
                                [
                                  item.couponList && item.couponList.length
                                    ? _c("div", [
                                        _c("div", {
                                          staticClass: "text-red paddingh10",
                                          staticStyle: {
                                            background:
                                              "rgba(255,255,255,0.80)",
                                            border: "1px solid #FF4C48",
                                            "border-radius": "8px",
                                            "font-size": "9px",
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.couponList[0]
                                            ),
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !(item.couponList && item.couponList.length) &&
                          item.vipPrice
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pos-a bottom10 fs-12 flex-container center lh-20 paddingh20",
                                  staticStyle: {
                                    background: "rgba(0,0,0,.7)",
                                    color: "#E9B983",
                                    top: "auto",
                                    left: "auto",
                                    right: "auto",
                                    "border-radius": "20px",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "//image1.51tiangou.com/tgou2/img2/product/ico-up.png",
                                      width: "16",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t¥" +
                                      _vm._s(item.vipPrice) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "text-darker fs-12 text-center margint10 text-bolder text-fixed1",
                        domProps: { textContent: _vm._s(item.title) },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-container text-center fs-10" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "flex1 text-red paddingr10",
                              class:
                                item.originalPrice &&
                                item.originalPrice !== item.price &&
                                "text-right",
                            },
                            [
                              _vm._v("¥"),
                              _c("span", {
                                staticClass: "fs-14",
                                domProps: {
                                  textContent: _vm._s("" + item.price),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          item.originalPrice &&
                          item.originalPrice !== item.price
                            ? _c("del", {
                                staticClass: "flex1 text-light text-left",
                                domProps: {
                                  textContent: _vm._s("¥" + item.originalPrice),
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }