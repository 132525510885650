import tg      from 'tgspa';
import App     from './app.vue';
import index   from './index.vue';
import brand   from './brand.vue';
import xdBrand   from './xdBrand.vue';
import xdActivity   from './xdActivity.vue';
import license from './license.vue';
import search  from 'spa/page/search.vue';
import i18n    from './i18n.vue';
import classify    from './classify.vue';

tg.appInit(App, {
	index
	, brand
	, xdBrand
	, xdActivity
	, license
	, search
	, i18n,
	classify
});