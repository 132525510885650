<template>
    <div style="width:100%;height:100%;background:#f8f8f8;">
        <tg-head title="全部分类"></tg-head>
        <!--点击进入搜索结果页-->
        <div style="padding: 8px 12px 0;" class="pos-r">
            <div @click="$router.push({path:'search',query:{type:'index',placeholder:'请输入您要搜索的商品'}})"
                 class="flex-container bg-white"
                 style="border-radius: 200px;padding: 4px;"
                 data-scp="search.0">
                <div class="flex-container">
                    <img src="//image1.51tiangou.com/tgou2/img2/index/icon_search.png"
                         style="margin-left: 4px;"
                         width="24"
                         height="24"
                         alt="">
                    <div class="text-limit1 text-left text-light fs-12"
                         style="margin-left: 8px;">请输入您要搜索的商品</div>
                </div>
            </div>
        </div>
        <!-- 分类 -->
        <div class="clearfix" style="width: 100%;height: calc(100% - 88px)">
            <!-- 左侧一级分类 -->
            <div class="classify-fir text-dark scroll-container-y text-center fs-14">
                <div v-for="(item, index) in treeData" :key="item.id"
                     @click="changeFirLevel(index)"
                     :data-scp="`one.${index}`" :data-bk="`id-${item.id}`"
                     class="text text-limit1 anchor-fir" :class="curFirLevel === index ? 'active text-red fs-18 text-bolder' : ''">
                    {{item.name.slice(0, 5)}}
                </div>
            </div>
            <div class="classify-right">
                <!-- 二级分类 -->
                <div class="classify-sec text-black scroll-container">
                    <div v-for="(item, index) in curClassifySec" :key="item.id"
                         @click="changeSecLevel(index)"
                         :data-scp="`two.${index}`" :data-bk="`id-${item.id}`"
                         class="fs-12 text anchor-sec" :class="curSecLevel === index ? 'active text-red' : ''">
                        {{item.name}}
                    </div>
                </div>
                <!-- 三级分类 -->
                <div class="classify-thd text-dark scroll-container-y">
                    <div v-for="(item, index) in curClassifySec" class="text fs-12 anchor-thd" :key="item.id">
                        <p class="fs-16 text-black text-bolder" style="margin-left: 4px;margin-bottom: 4px">{{item.name}}</p>
                        <div class="flex-container left" style="flex-wrap: wrap;">
                            <div v-for="(itemT, indexT) in item.subCategory"
                                 :data-scp="`three.${indexT}`" :data-bk="`id-${itemT.id}`"
                                 @click="goSearchClassify(indexT, itemT)" :key="itemT.id"
                                 class="text-center text-limit1"
                                 style="width: 33%">
                                <div class="img-container" style="padding: 0 4px">
                                    <img :src="getImageUrl(itemT.imageUrl)" alt="">
                                </div>
                                <p style="margin: 4px 0">{{itemT.name}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="text-center" v-if="curFirLevel < treeData.length - 1 && treeData[curFirLevel + 1]">
                        <div style="transform: rotate(-90deg)" class="dis-i"><span class="icon-newPageGo fs-13 text-light"></span></div>
                        向上继续拉浏览 {{treeData[curFirLevel + 1].name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" type="text/scss">
html, body, #app {
    width: 100%;
    height: 100%;
}
.classify-fir {
    float: left;
    width: 104px;
    height: 100%;
}
.classify-fir .text {
    width: 100%;
    line-height: 56px;
}
.classify-right {
    float: right;
    padding-top: 12px;
    width: calc(100% - 104px);
    height: 100%
}
.classify-sec {
    width: 100%;
    padding-bottom: 10px;
}
.classify-sec .text {
    margin-right: 8px;
    padding: 0 12px;
    line-height: 28px;
    border-radius: 28px;
    background-color: #fff;
}
.classify-sec .active {
    border: 1px solid #ff4c48;
    background: #FFEBED;
}
.classify-thd {
    padding-right: 12px;
    height: calc(100% - 45px);
}
.classify-thd .text {
    margin-bottom: 12px;
    padding: 12px 8px;
    background: #fff;
    border-radius: 12px;
    width: 100%;
}
</style>
<script>

import tabbar from 'spa/apps/activity/component/tabbar.vue';
import itemService from "spa/lib/api/item";
import domain from "javascript/libs/tg/domain";
export default {
    name: "classify",
    config:{
        page: '14.fenlei',
        shareData:true,
    },
    components:{tabbar},
    data(){
        return {
            treeData: [],
            curClassifySec: [],
            // 当前选中的一级分类
            curFirLevel: 0,
            // 当前选中的二级分类
            curSecLevel:0,
            scrollEvent: _.throttle(() => {
                this.scrollEventFun();
            },1000,{
                leading:false
            }),
            startX: 0,
            startY: 0,
            isBottom: false
        }
    },
    mounted(){
        this.requestClassifyData();
        this.params = tg.getParams();
    },
    methods:{
        requestClassifyData() {
            itemService.supplierCategoryTree({
                data: {}
            }).then((data)=>{
                if (data) {
                    this.treeData = data;
                    if (this.params && this.params.hashParams.id) {
                        // 默认选中
                        let curIndex = 0;
                        this.treeData.forEach((item, index) => {
                            if (item.id === Number(this.params.hashParams.id)) {
                                curIndex = index;
                            }
                        })
                        this.$nextTick(() => {
                            this.changeFirLevel(curIndex);
                        })
                    } else {
                        this.curClassifySec = data[0].subCategory;
                    }
                    this.$nextTick(() => {
                        let dom = document.getElementsByClassName('classify-thd')[0];
                        dom.addEventListener('scroll', () => {
                            this.isBottom = false;
                            this.scrollEvent();
                        });
                        dom.addEventListener('touchstart', this.handleTouchEvent);
                        dom.addEventListener('touchend', this.handleTouchEvent);
                        setTimeout(() => {
                            // 判断三级分类是否超出一屏
                            let screenHeight = dom.clientHeight,
                                scrollHeight = dom.scrollHeight;
                            if (screenHeight >= scrollHeight) {
                                this.isBottom = true;
                            }
                        }, 500)
                    })
                }
            });
        },
        // 切换左侧一级分类
        changeFirLevel(index) {
            this.curFirLevel = index;
            this.curClassifySec = this.treeData[index].subCategory;
            this.curSecLevel = 0;
            this.isBottom = false;
            document.querySelector('.classify-thd').scrollTop = 0;
            let firDomList = document.getElementsByClassName('anchor-fir');
            firDomList[this.curFirLevel].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start'
            })
            this.$nextTick(() => {
                // 判断三级分类是否超出一屏
                let dom = document.getElementsByClassName('classify-thd')[0],
                    screenHeight = dom.clientHeight,
                    scrollHeight = dom.scrollHeight;
                if (screenHeight >= scrollHeight) {
                    this.isBottom = true;
                }
            })
        },
        // 切换二级分类
        changeSecLevel(index) {
            this.curSecLevel = index;
            let thdDomList = document.getElementsByClassName('anchor-thd');
            thdDomList[index].scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        },
        goSearchClassify(index, item) {
            tg.changePage('./search/searchClassify.html',{id: item.id,code: item.code, type: 'classify'});
        },
        // 滚动结束计算高亮的二级标签
        scrollEventFun() {
            let groupIndex=[],
                dom = document.getElementsByClassName('classify-thd')[0],
                screenHeight = dom.clientHeight,
                scrollTop = dom.scrollTop,
                scrollHeight =dom.scrollHeight,
                h1 =  document.getElementsByClassName('classify-sec')[0].clientHeight,
                h2 =  85;
            $('.anchor-thd').each((index, el) => {
                let {top, height} = el.getBoundingClientRect();
                if (_.inRange(top, 0, screenHeight) || (top < 0 && top + height > (h1 + h2))) {
                    groupIndex.push(index)
                }
            });
            this.curSecLevel = groupIndex[0]||0;
            let secDomList = document.getElementsByClassName('anchor-sec');
            secDomList[this.curSecLevel].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start'
            })
            // 滚动到底再滑动
            this.isBottom = screenHeight + Math.ceil(scrollTop) >= scrollHeight;
        },
        handleTouchEvent(event) {
            switch (event.type) {
                case 'touchstart':
                    this.startX = event.touches[0].pageX;
                    this.startY = event.touches[0].pageY;
                    break;
                case 'touchend':
                    let spanY = event.changedTouches[0].pageY - this.startY;
                    // 向上
                    if (spanY < -30 && this.curFirLevel < this.treeData.length - 1 && this.isBottom) {
                        this.changeFirLevel(this.curFirLevel + 1);
                    }
                    break;
            }
        },
        getImageUrl(imageUrl) {
            return domain.getImageUrl(imageUrl);
        }
    },
    destroyed(){
        document.removeEventListener('scroll', this.scrollEvent);
    }
}
</script>

<style>
</style>
