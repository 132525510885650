var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentTopOrder
    ? _c(
        "div",
        {
          staticClass: "bottomNotice blink",
          staticStyle: { opacity: "0", "z-index": "10" },
        },
        [
          _c(
            "div",
            {
              staticClass: "img-container full round float-left",
              staticStyle: { width: "33px", height: "33px" },
            },
            [
              _c("img", {
                attrs: {
                  src: _vm._f("fixedDomain")(_vm.currentTopOrder.imageUrl),
                  alt: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-left": "35px" } }, [
            _c("a", {
              staticClass: "bottomNoticeText text-limit1 text-white pos-r",
              attrs: {
                href: _vm.currentTopOrder.clickUrl,
                "data-scp": "bottom.notice",
              },
              domProps: { textContent: _vm._s(_vm.currentTopOrder.title) },
            }),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }